import { IProfileForm } from '@app/states/profile/interfaces/profile-form.interface';

export function getCustomerRegistration(profile: IProfileForm, hasCustomFields = false) {
  const customer = {
    mobilePhone: '7' + profile?.phone,
    lastName: profile?.fio?.split(' ')[0],
    firstName: profile?.fio?.split(' ')[1],
    middleName: profile?.fio?.split(' ')[2],
    birthDate: profile?.bDay,
    sex: profile?.gender,
    email: profile?.email,
  };
  return hasCustomFields
    ? {
        ...customer,
        customFields: {
          jobScope: profile?.workScope?.label,
          typeOrganization: profile?.workType === 1 ? 'Государственная' : 'Частная',
          numberOfEmployees: profile?.workNumberOfEmployees?.label,
          workExperience: profile?.workPeriod?.label,
          incame: profile?.workSalary,
          children: profile?.hasChildren,
          numberOfChildren: profile?.hasChildren ? profile?.numberOfChildren : 0,
          education: profile?.educationType?.label,
          family: profile?.maritalStatus?.label,
          additionalContact: undefined,
          typeOfEmployment: profile?.typeOfEmployment?.label,
        },
      }
    : customer;
}
