import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICalculator } from '@app/interfaces/calculator.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IILSCalculator } from '@app/states/calculator/interfaces/ils-calculator.interface';
import { IILSScheduleDTO } from '@app/states/calculator/interfaces/ils-schedule-dto.interface';
import { IILSSchedule } from '@app/states/calculator/interfaces/ils-schedule.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Класс сервиса запросов к бэкэнду калькулятора займов
 */
export class CalculatorService {
  /**
   * Конструктор сервиса запросов к бэкэнду калькулятора займов
   *
   * @param http [Сервис] {@link HttpClient} для обработки HTTP запросов
   * @param environment Данные окружения приложения
   */
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
  }

  /**
   * Сервис по получению данных от бэкэнда по ПДЛ
   * @param promocode промокод
   */
  public calculatorRequest(promocode?: string): Observable<IDataResponse<ICalculator>> {
    // eslint-disable-next-line max-len
    return this.http.get<IDataResponse<ICalculator>>(`${this.environment.api}loan-calculator/v3${promocode ? '?promocode='+promocode : ''}`);
  }

  /**
   * Сервис по получению данных от бэкэнда по ИЛС
   */
  public ils(): Observable<IDataResponse<{ attributes: IILSCalculator }>> {
    return this.http.get<IDataResponse<{ attributes: IILSCalculator }>>(`${this.environment.api}ils/v1/schedule/WEBBANKIR/tariff`);
  }

  /**
   * Сервис отправки на бэкэнд данных расписания калькулятора ИЛС
   *
   * @param attributes данные расписания калькулятора ИЛС
   */
  public ilsSchedule(attributes: IILSSchedule): Observable<IDataResponse<{ attributes: IILSScheduleDTO }>> {
    return this.http.post<IDataResponse<{ attributes: IILSScheduleDTO }>>(
      `${this.environment.api}ils/v1/schedule/WEBBANKIR`,
      { data: { type: 'PaymentSchedule', attributes } },
    );
  }
}
