import { IStateMigration } from '@app/interfaces/state-migration.interface';

export class EventsStateMigrations {
  public static migrate(): IStateMigration[] {
    return [
      {
        version: 1,
        key: 'Events',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 2,
          sessionId: null,
          webbankirId: null,
        }),
      },
      {
        version: undefined,
        key: 'Events',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 1,
          sessionId: null,
        }),
      },
    ];
  }
}
