import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable, of, throwError } from 'rxjs';
import { IBalanceResponse } from '../../interfaces/balance-response.interface';
import { ITransactionHistoryResponse } from '../../interfaces/transaction-history-response.interface';
import { HttpClient } from '@angular/common/http';
import { IJsonRpcResponse } from '@app/interfaces/jsonrpc-response.interface';
import { IJsonRpcRequest } from '@app/interfaces/jsonrpc-request.interface';
import { IExpiredBalance } from '@app/states/bonus/interfaces/expired-balance.interface';

@Injectable({
  providedIn: 'root',
})
export class BonusAccountsService {
  private path = `${this.environment.api}bonus-accounts/v1/rpc`;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  public getBalance(clientId: string): Observable<IBalanceResponse> {
    return this.call(this.path, 'getBalance', { clientId });
  }

  public getUpcomingExpiration(clientId: string): Observable<IJsonRpcResponse<IExpiredBalance>> {
    return this.call(this.path, 'getUpcomingExpiration', { clientId });
  }

  public getTransactionHistory(
    clientId: string,
    limit: number = 20,
    offset: number = 0,
  ): Observable<ITransactionHistoryResponse> {
    return this.call(this.path, 'getTransactionHistory', {
      clientId,
      limit,
      offset,
    });
  }

  private call<T>(endpoint: string, method: string, params: unknown, id = '1'): Observable<IJsonRpcResponse<T>> {
    const request: IJsonRpcRequest = {
      id,
      jsonrpc: '2.0',
      method,
      params,
    };

    return this.http.post<IJsonRpcResponse<T>>(endpoint, request).pipe(
      mergeMap((res: IJsonRpcResponse<T>) => res.error ? throwError(() => res) : of(res)),
    );
  }
}
