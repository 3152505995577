import { ModalClose } from '@web-bankir/ui-kit/components/modal';
import { ModalEvent } from '../constants/modal-event.const';
import { IModalConfig } from '../interfaces/modal-config.interface';
import { IModalEventFn } from '../interfaces/modal-event-fn.interface';
import { ModalActionsList } from './modal-actions-list.const';

export namespace ModalActions {
  export class OpenModal {
    public static readonly type: ModalActionsList = ModalActionsList.OPEN_MODAL;

    constructor(public payload: IModalConfig) {
    }
  }

  export class BindEvent {
    public static readonly type: ModalActionsList = ModalActionsList.BIND_EVENT;

    constructor(public event: ModalEvent, public payload: IModalEventFn){
    }
  }

  export class CloseModal {
    public static readonly type: ModalActionsList = ModalActionsList.CLOSE_MODAL;

    constructor(public payload?: any, public closedBy?: ModalClose) {
    }
  }

  export class Reset {
    public static readonly type: ModalActionsList  = ModalActionsList.RESET;
  }
}
