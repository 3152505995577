import { GaEvent } from '@app/states/events/constants/ga-event.const';
import { IGAEvent } from '@app/states/events/interfaces/ga-event.interface';

export const GOOGLE_ANALYTICS_EVENTS_MAP: Map<GaEvent, IGAEvent> = new Map<GaEvent, IGAEvent>([
  [GaEvent.CLIENT_NEXT_CONTACT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Продолжить"',
    label: 'Личная информация',
  }],
  [GaEvent.CLIENT_NEXT_ADDITIONAL, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Продолжить"',
    label: 'Дополнительная информация',
  }],
  [GaEvent.CLIENT_ADD_CARD_STEPS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Привязать карту"',
    label: 'Добавление карты',
  }],
  [GaEvent.CLIENT_ADD_ANOTHER_CARD, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Привязать другую банковскую карту"',
    label: 'Добавление новой карты',
  }],
  [GaEvent.CLIENT_ADD_YANDEX_WALLET, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Привязать кошелек"',
    label: 'Добавление кошелька ЮMoney',
  }],
  [GaEvent.CLIENT_ADD_QIWI_WALLET, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Привязать кошелек"',
    label: 'Добавление кошелька QIWI.WALLET',
  }],
  [GaEvent.CLIENT_ADD_ANOTHER_YANDEX_WALLET, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Привязать другой кошелек"',
    label: 'Добавление нового кошелька ЮMoney',
  }],
  [GaEvent.CLIENT_CHOICE_METHOD_CONTACT, {
    category: 'Пользователи',
    action: 'Выбор способа "CONTACT"',
    label: 'Способ получения CONTACT',
  }],
  [GaEvent.CLIENT_TIE_SOCIAL_NETWORK_VK, {
    category: 'Пользователи',
    action: 'Нажатие логотипа ВК',
    label: 'Привязка социальной сети ВК',
  }],
  [GaEvent.CLIENT_TIE_SOCIAL_NETWORK_OK, {
    category: 'Пользователи',
    action: 'Нажатие логотипа ОК',
    label: 'Привязка социальной сети ОК',
  }],
  [GaEvent.CLIENT_TIE_SOCIAL_NETWORK_FB, {
    category: 'Пользователи',
    action: 'Нажатие логотипа FB',
    label: 'Привязка социальной сети FB',
  }],
  [GaEvent.CLIENT_APPLY_LOAN_PRIMARY, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подать заявку на займ"',
    label: 'Подача заявки первичная',
  }],
  [GaEvent.CLIENT_SIGN_CONTRACT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подписать договор"',
    label: 'Подписание договора',
  }],
  [GaEvent.CLIENT_SIGN_CONTRACT_RESEND_CODE, {
    category: 'Пользователи',
    action: 'Нажатие "Повторно получить код подтверждения"',
    label: 'Запрос кода. Подписание договора',
  }],
  [GaEvent.CLIENT_VIEWING_CONTRACT, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Договор"',
    label: 'Просмотр договора',
  }],
  [GaEvent.CLIENT_PRINT_CONTRACT, {
    category: 'Пользователи',
    action: 'Нажатие "Рапечатать договор"',
    label: 'Печать договора',
  }],
  [GaEvent.CLIENT_SIGN_CONTRACT_OPEN_BLOCK, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Подписать договор" в графе "Статус займа"',
    label: 'Переход к подписанию договора',
  }],
  [GaEvent.CLIENT_CONFIRM_EMAIL_SEND, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подтвердить"',
    label: 'Попытка подтверждения e-mail',
  }],
  [GaEvent.CLIENT_REPAY_LOAN_OPEN_BLOCK, {
    category: 'Пользователи',
    action: 'Нажатие "Погасить займ"',
    label: 'Просмотр способа погашения',
  }],
  [GaEvent.CLIENT_CLICK_PAY, {
    category: 'Пользователи',
    action: 'Нажатие "Оплатить" / "Квитанция" / "Погасить займ в 1 клик"',
    label: 'Попытка оплаты',
  }],
  [GaEvent.CLIENT_EXTEND_LOAN_OPEN_BLOCK, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Продлить займ"',
    label: 'Попытка продления займа',
  }],
  [GaEvent.CLIENT_BUTTON_FORMULATE_ADDITIONAL_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Сформировать допсоглашение"',
    label: 'Сформировано доп. соглашение',
  }],
  [GaEvent.CLIENT_SIGN_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подписать допсоглашение"',
    label: 'Подписание доп. соглашения',
  }],
  [GaEvent.CLIENT_CLICK_TERMS_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Условия допсоглашения"/"Допсоглашения"',
    label: 'Ознакомление с условиями доп. соглашения',
  }],
  [GaEvent.CLIENT_RESEND_CODE_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Повторно получить код подтверждения"',
    label: 'Повторное получение кода для подписи доп. соглашения',
  }],
  [GaEvent.CLIENT_PAY_BUTTON_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки кнопки/ссылки "Оплатить"',
    label: 'Попытка оплаты доп. соглашения',
  }],
  [GaEvent.CLIENT_REPAY_LOAN_OPEN_BLOCK_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Погасить займ"',
    label: 'Попытка погашения займа при сформированном доп. соглашении',
  }],
  [GaEvent.CLIENT_BUTTON_SIGN_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подписать"',
    label: 'Попытка подписания доп. соглашения после того, как доп. соглашение было сформировано, был покинут раздел подписания',
  }],
  [GaEvent.CLIENT_BUTTON_VIEW_AGREEMENT, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Просмотреть"',
    label: 'Просмотр доп. соглашения после того, как доп. соглашение было сформировано, был покинут раздел подписания',
  }],
  [GaEvent.CLIENT_CHANGE_PHONE_OPEN_MODAL, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Изменить"',
    label: 'Попытка изменить телефон в ЛК',
  }],
  [GaEvent.CLIENT_CHANGE_PHONE_CONFIRM, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подтвердить" в модальном окне "Изменение номера мобильного телефона"',
    label: 'Подтверждение изменения номера телефона в ЛК',
  }],
  [GaEvent.CLIENT_CHANGE_PHONE_CLICK_CHANGE, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Изменить номер телефона"',
    label: 'Изменение номера телефона в ЛК, когда ожидается подтверждение ранее указанного номера',
  }],
  [GaEvent.CLIENT_CHANGE_PHONE_RESEND_CODE, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Запросить код повторно"',
    label: 'Запрос кода. Подтверждение номера в ЛК',
  }],
  [GaEvent.CLIENT_CHANGE_PHONE_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подтвердить"',
    label: 'Успешное изменение номера в ЛК',
  }],
  [GaEvent.CLIENT_CHANGE_EMAIL_OPEN_MODAL, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Изменить"',
    label: 'Попытка изменить e-mail в ЛК',
  }],
  [GaEvent.CLIENT_CHANGE_EMAIL_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Изменить"',
    label: 'Изменение e-mail в ЛК',
  }],
  [GaEvent.CLIENT_SAVE_PROFILE_STEP, {
    category: 'Пользователи',
    action: 'Нажатие "Продолжить"/"Завершить заполнение"',
    label: 'Изменение анкетных данных в ЛК',
  }],
  [GaEvent.CLIENT_COMPLAIN_MODAL, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Пожаловаться на оператора"',
    label: 'Попытка пожаловаться на оператора',
  }],
  [GaEvent.CLIENT_COMPLAIN_OPERATOR, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Отправить сообщение"',
    label: 'Жалоба на оператора',
  }],
  [GaEvent.CLIENT_CHOOSE_CHANGE_PASSWORD, {
    category: 'Пользователи',
    action: 'Выбор "Изменить пароль" в меню',
    label: 'Попытка изменить пароль',
  }],
  [GaEvent.CLIENT_CHANGE_PASSWORD_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Сохранить"',
    label: 'Изменение пароля',
  }],
  [GaEvent.CLIENT_LOGOUT, {
    category: 'Пользователи',
    action: 'Выбор "Выход" в меню',
    label: 'Выход из ЛК',
  }],
  [GaEvent.CLIENT_AUTH_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Войти"',
    label: 'Авторизация',
  }],
  [GaEvent.CLIENT_TIE_VK_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие логотипа ВК',
    label: 'Привязка ВК в ЛК',
  }],
  [GaEvent.CLIENT_TIE_OK_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие логотипа ОК',
    label: 'Привязка ОК в ЛК',
  }],
  [GaEvent.CLIENT_TIE_FB_SUCCESS, {
    category: 'Пользователи',
    action: 'Нажатие логотипа FB',
    label: 'Привязка FB в ЛК',
  }],
  [GaEvent.CLIENT_ARCHIVE_PAGE, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "История займов"',
    label: 'Просмотр истории займов',
  }],
  [GaEvent.CLIENT_ADD_CARD, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Привязать карту"',
    label: 'Попытка привязки карты в ЛК',
  }],
  [GaEvent.CLIENT_GET_CARD, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Получить на карту"',
    label: 'Привязка карты в ЛК',
  }],
  [GaEvent.CLIENT_TIE_NEW_YANDEX_WALLET, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Привязать новый яндекс кошелек"',
    label: 'Попытка привязать яндекс кошелек в ЛК',
  }],
  [GaEvent.CLIENT_YANDEX_WALLET_SUCCESS_TIE, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Привязать"',
    label: 'Привязка яндекс кошелька в ЛК',
  }],
  [GaEvent.CLIENT_MAKE_MAIN_CONTACT, {
    category: 'Пользователи',
    action: 'Нажатие "Сделать основным"',
    label: 'Выбор CONTACT',
  }],
  [GaEvent.CLIENT_MAKE_MAIN_CARD, {
    category: 'Пользователи',
    action: 'Нажатие "Сделать основной"',
    label: 'Выбор Карты',
  }],
  [GaEvent.CLIENT_MAKE_MAIN_YANDEX_WALLET, {
    category: 'Пользователи',
    action: 'Нажатие "Сделать основным"',
    label: 'Выбор яндекс кошелька',
  }],
  [GaEvent.CLIENT_INDENTIFICATION_YANDEX_WALLET_PA, {
    category: 'Пользователи',
    action: 'Нажатие ссылки "Привязать"',
    label: 'Попытка идентификации яндекс кошелька в ЛК',
  }],
  [GaEvent.CLIENT_CLICK_PENCIL, {
    category: 'Пользователи',
    action: 'Нажатие пиктограммы "карандаш"',
    label: 'Редактирование имени способа получения',
  }],
  [GaEvent.CLIENT_YANDEX_WALLET_DELETED, {
    category: 'Пользователи',
    action: 'Нажатие удалить в модальном окне',
    label: 'Удаление яндекс кошелька',
  }],
  [GaEvent.CLIENT_CARD_DELETED, {
    category: 'Пользователи',
    action: 'Нажатие удалить в модальном окне',
    label: 'Удаление карты',
  }],
  [GaEvent.CLIENT_UNTIE_SOCIAL_NETWORK_PA, {
    category: 'Пользователи',
    action: 'Нажатие "Отвязать" в модальном окне',
    label: 'Отвязка соц. сети в ЛК',
  }],
  [GaEvent.CLIENT_CANCEL_SOCIAL_NETWORK_PA, {
    category: 'Пользователи',
    action: 'Нажатие "Отмена" в модальном окне',
    label: 'Попытка отвязать соц. сеть в ЛК',
  }],
  [GaEvent.CLIENT_UNTIE_SOCIAL_NETWORK_STEPS, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подать заявку"',
    label: 'Повторная подача заявки',
  }],
  [GaEvent.CLIENT_CANCEL_SOCIAL_NETWORK_STEPS, {
    category: 'Пользователи',
    action: 'Нажатие "Отмена" в модальном окне',
    label: 'Попытка отвязать соц. сеть на этапе подачи заявки',
  }],
  [GaEvent.CLIENT_APPLY_LOAN_AGAIN, {
    category: 'Пользователи',
    action: 'Нажатие кнопки "Подать заявку"',
    label: 'Повторная подача заявки',
  }],
  [GaEvent.CLIENT_ACTION_PAYMENT, {
    category: 'Пользователи',
    action: 'Ответ от 1С о выдаче займа',
    label: 'Выдача займа',
  }],
  [GaEvent.CLIENT_MAIL_CONFIRMATION_EMAIL, {
    category: 'Пользователи',
    action: 'Переход по ссылке из письма',
    label: 'Подтверждение e-mail',
  }],
  [GaEvent.CLIENT_ACTION_PAYMENT_CONTACT, {
    category: 'Пользователи',
    action: 'Фиксация выдачи на CONTACT',
    label: 'Выдача на CONTACT',
  }],
  [GaEvent.CLIENT_ACTION_PAYMENT_CARD, {
    category: 'Пользователи',
    action: 'Фиксация выдачи на карту',
    label: 'Выдача на карту',
  }],
  [GaEvent.CLIENT_ACTION_PAYMENT_YANDEX, {
    category: 'Пользователи',
    action: 'Фиксация выдачи на яндекс кошелек',
    label: 'Выдача на яндекс кошелек',
  }],
  [GaEvent.CLIENT_CLICK_OPEN_CHAT, {
    category: 'Пользователи',
    action: 'Нажатие "Чат"',
    label: 'Онлайн помощь в ЛК',
  }],
  [GaEvent.USER_REGISTER_ATTEMPT, {
    category: 'Посетители',
    action: 'Нажатие на кнопку "Продолжить"',
    label: 'Попытка регистрации',
  }],
  [GaEvent.USER_REGISTER_NEXT, {
    category: 'Посетители',
    action: 'Нажатие на кнопку "Продолжить"',
    label: 'Регистрация ЛК',
  }],
  [GaEvent.USER_CHANGE_PHONE, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Изменить номер телефона"',
    label: 'Изменение номера телефона',
  }],
  [GaEvent.USER_REQUEST_AGAIN_CODE, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Запросить код повторно"',
    label: 'Запрос кода. Подтверждение номера',
  }],
  [GaEvent.USER_CLICK_PERSONAL_AREA, {
    category: 'Посетители',
    action: 'Нажатие кнопки "Личный кабинет"',
    label: 'Попытка авторизоваться',
  }],
  [GaEvent.USER_CLICK_RECEIVE_MONEY, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Получить деньги"',
    label: 'Попытка подачи заявки через форму авторизации',
  }],
  [GaEvent.USER_CLICK_PASSRESTORE_PAGE_AUTH, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Восстановить"',
    label: 'Попытка восстановить пароль на форме авторизации',
  }],
  [GaEvent.USER_SUCCESS_CHANGE_PASSWORD, {
    category: 'Посетители',
    action: 'Нажатие кнопки "Восстановить пароль"',
    label: 'Восстановление пароля',
  }],
  [GaEvent.USER_CLICK_PASSRESTORE, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Напомнить пароль"',
    label: '',
  }],
  [GaEvent.USER_PASSRESTORE_EMAIL, {
    category: 'Посетители',
    action: 'При выборе "По e-mail"',
    label: 'Восстановление по e-mail',
  }],
  [GaEvent.USER_PASSRESTORE_LOGIN, {
    category: 'Посетители',
    action: 'При выборе "По логину"',
    label: 'Восстановление по логину',
  }],
  [GaEvent.USER_PASSRESTORE_PHONE, {
    category: 'Посетители',
    action: 'При выборе "По телефону"',
    label: 'Восстановление по моб. номеру',
  }],
  [GaEvent.USER_CLICK_GOOGLE_PLAY, {
    category: 'Посетители',
    action: 'Нажатие на кнопку моб. прил. в верхней плашке',
    label: 'Переход в GooglePlay',
  }],
  [GaEvent.USER_CLICK_APP_STORE, {
    category: 'Посетители',
    action: 'Нажатие на кнопку моб. прил. в верхней плашке',
    label: 'Переход в Appstore',
  }],
  [GaEvent.USER_CLICK_OPEN_CHAT, {
    category: 'Посетители',
    action: 'Нажатие "Чат"',
    label: 'Онлайн помощь',
  }],
  [GaEvent.USER_RECEIVE_MONEY_CALC, {
    category: 'Посетители',
    action: 'Нажатие на кнопку "Оформить займ"/"Получить деньги"',
    label: 'Переход с калькулятора',
  }],
  [GaEvent.USER_PERSONAL_AREA, {
    category: 'Посетители',
    action: 'Нажатие ссылки "Личный кабинет"',
    label: 'Переход в ЛК через калькулятор',
  }],
  [GaEvent.USER_INVESTOR_BUTTON, {
    category: 'Посетители',
    action: 'Нажатие кнопки "Отправить Ваше сообщение"',
    label: 'Инвестор. Отправка сообщения',
  }],
]);

