import { IAppealsState } from './appeals.state.interface';
import { RequestStatus } from '../../../constants/request-status.const';

/**
 * Дефолтное состояние хранилища "Обращения" при его инициализации
 */
export const APPEALS_STATE_DEFAULTS: IAppealsState = {
  appealsList: null,
  page: null,
  pageSize: null,
  status: RequestStatus.NotInvoked,
};
