export enum VirtualCardCreationStatus {
    /** карты/счета нет, открытие счета возможно */
    Available = 'account_creation_available', // 
    /** карты/счета нет, открытие счета невозможно */
    Unavailable = 'account_creation_unavailable',
    /** карта/счет находятся в процессе создания */
    InProgress = 'account_creation_in_progress',  
    /** процесс создания карты/счета требуется запустить заново */
    Retry = 'account_creation_retry',
    /** карта/счет уже созданы */
    Created = 'account_created',
}
  