import { RequestStatus } from '@app/constants/request-status.const';
import { IRegistrationState } from './registration-state.interface';

/**
 * Дефолтное состояние хранилища при его инициализации
 */
export const REGISTRATION_STATE_DEFAULTS: IRegistrationState = {
  version: 7,
  user: null,
  currentStep: '1',
  registrationStartTimestamp: null,
  esiaUsed: false,
  esiaKey: null,
  loanRequestStatus: RequestStatus.NotInvoked,
};
