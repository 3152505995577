import { LoansHistoryActionList } from './loans-history-action-list.const';
import { IDocumentItem } from '@app/states/loans-history/interfaces/document-item.interface';
import { ILoanHistoryItem } from '@app/states/loans-history/interfaces/new/loan-history-item.interface';
import { IError } from '@app/interfaces/error.interface';
import { IStatementItem } from '@app/states/loans-history/interfaces/statement-item.interface';

/**
 * Namespace для действий состояния "История займов"
 */
export namespace LoansHistoryActions {

  /**
   * Действие для загрузки истории займов пользователя
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD;
  }

  /**
   * Действие для успешной загрузки истории займов пользователя
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные истории займов пользователя
     */
    constructor(public payload: ILoanHistoryItem[]) {
    }
  }

  /**
   * Действие для неуспешной загрузки истории займов пользователя
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для загрузки документов истории займа пользователя
   */
  export class LoadDocuments {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_DOCUMENTS;
    /**
     * Тело действия
     *
     * @param payload Метод загрузки документов
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешной загрузки документов истории займа пользователя
   */
  export class LoadDocumentsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_DOCUMENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной загрузки документов истории займа пользователя
   */
  export class LoadDocumentsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_DOCUMENTS_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные документов истории займа пользователя
     */
    constructor(public payload: IDocumentItem[]) {
    }
  }

  /**
   * Действие для загрузки документа истории займа пользователя
   */
  export class OpenDocument {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.OPEN_DOCUMENT;
    /**
     * Тело действия
     *
     * @param payload Метод загрузки документа
     * @param type Тип документа
     */
    constructor(public payload: string, public type?: string) {
    }
  }

  /**
   * Действие для неуспешной загрузки документа истории займа пользователя
   */
  export class OpenDocumentFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.OPEN_DOCUMENT_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной загрузки документа истории займа пользователя
   */
  export class OpenDocumentSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.OPEN_DOCUMENT_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные документа
     */
    constructor(public payload: any) {
    }
  }

  /**
   * Действие для загрузки заявлений истории займа пользователя
   */
  export class LoadStatements {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_STATEMENTS;
    /**
     * Тело действия
     *
     * @param payload ID заявлений
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешной загрузки заявлений истории займа пользователя
   */
  export class LoadStatementsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_STATEMENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: { err; caught }) {
    }
  }

  /**
   * Действие для успешной загрузки заявлений истории займа пользователя
   */
  export class LoadStatementsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.LOAD_STATEMENTS_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные заявлений истории займа пользователя
     */
    constructor(public payload: IStatementItem[]) {
    }
  }

  /**
   * Действие для подачи заявления в истории займа пользователя
   */
  export class ApplyStatement {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.APPLY_STATEMENT;

    /**
     * Тело действия
     *
     * @param payload Метод загрузки
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешной подачи заявления в истории займа пользователя
   */
  export class ApplyStatementFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.APPLY_STATEMENT_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной подачи заявления в истории займа пользователя
   */
  export class ApplyStatementSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.APPLY_STATEMENT_SUCCESS;
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: LoansHistoryActionList = LoansHistoryActionList.RESET;
  }
}
