export enum PaymentMethod {
  CreditCard = 'credit_card',
  Sberbank = 'sberbank_online',
  YooMoney = 'yoo_money',
  QiwiWallet = 'qiwi_wallet',
  QiwiTerminal = 'qiwi_terminal',
  ElecsnetWallet = 'elecsnet_wallet',
  ElecsnetTerminal = 'elecsnet_terminal',
  MobilePhone = 'ruru',
  MKB = 'mkb',
  RussianPost = 'russian_post',
  BankTransfer = 'bank_transfer',
  GoldCrown = 'gold_crown',
  GoldCrownOnline = 'gold_crown_online',
  Contact = 'contact',
  SBP = 'SBP',
  VirtualCard = 'virtual_card',
}
