/**
 * Справочник действий состояния "Обращения" пользователя
 */
export enum AppealsActionList {
  LOAD = '[Appeals] Load appeals',
  LOAD_FAIL = '[Appeals] Load appeals failed',
  LOAD_SUCCESS = '[Appeals] Load appeals success',

  SEND_TO_EMAIL = '[Appeals] Send appeal to email',
  SEND_TO_EMAIL_FAIL = '[Appeals] Send appeal to email failed',
  SEND_TO_EMAIL_SUCCESS = '[Appeals] Send appeal to email success',

  LOAD_FILE = '[Appeals] Load appeal file',
  LOAD_FILE_FAIL = '[Appeals] Load appeal file failed',
  LOAD_FILE_SUCCESS = '[Appeals] Load appeal file success',

  RESET = '[Appeals] reset',
}
