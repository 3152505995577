import { IStateMigration } from '@app/interfaces/state-migration.interface';

/**
 * Класс миграций состояния "История займов"
 */
export class LoansHistoryStateMigrations {
  /**
   * Метод получения перечня миграций хранилища
   */
  public static migrate(): IStateMigration[] {
    return [
      {
        version: undefined,
        key: 'LoansHistory',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 1,
          items: [],
        }),
      },
    ];
  }
}
