import { Inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError } from 'rxjs';
import { LoggerService } from '../services/logger.service';
import { LoggerActions } from './logger.actions';

@State({
  name: 'Logger',
  defaults: null,
})
@Injectable()
export class LoggerState {
  constructor(private logger: LoggerService, @Inject('environment') private environment) {}

  @Action(LoggerActions.Log)
  public log(ctx: StateContext<null>, action: LoggerActions.Log) {
    return this.logger.log(action.payload).pipe(
      catchError(() => ctx.dispatch(new LoggerActions.LogFail(action.payload))),
    );
  }

  @Action(LoggerActions.LogFail)
  public logFail(ctx: StateContext<null>, action: LoggerActions.LogFail) {
    if (!this.environment.production) {
      // eslint-disable-next-line no-console
      console.error(action.payload);
    }
  }
}
