import { Injectable } from '@angular/core';
import { ContactPoint } from '@app/states/events/constants/contact-point.const';
import { EVENT_NAME_MAP } from '@app/states/events/constants/event-name-map.const';
import { EventsService } from '@app/states/events/services/events.service';
import { EVENTS_STATE_DEFAULTS } from '@app/states/events/states/events-state-defaults.const';
import { IEventsState } from '@app/states/events/states/events-state.interface';
import { EventsActions } from '@app/states/events/states/events.actions';
import { ProductState } from '@app/states/product/states/product.state';
import { ProfileState } from '@app/states/profile/states/profile.state';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as Sentry from '@sentry/angular-ivy';
import { v4 as uuid } from 'uuid';
import { CategoryName } from '../constants/category-name.const';
import { EventName } from '../constants/event-name.const';
import { GaEvent } from '../constants/ga-event.const';
import { MindboxOperation } from '../constants/mindbox-operation.const';
import { getCustomerRegistration } from '../helpers/get-customer-registration.helper';
import { getPartialRegistrationPayload } from '../helpers/get-partial-registration-payload.helper';
import { Metrika } from 'ng-yandex-metrika';


@State<IEventsState>({
  name: 'Events',
  defaults: EVENTS_STATE_DEFAULTS,
})
@Injectable()
export class EventsState implements NgxsOnInit {
  constructor(
    private service: EventsService,
    private store: Store,
    private yandexMetrikaService: Metrika,
  ) {}

  public ngxsOnInit(ctx: StateContext<IEventsState>): void {
    const { sessionId } = ctx.getState();

    if (!sessionId) {
      ctx.setState(
        patch({
          sessionId: uuid(),
        }),
      );
    }

    if (!this.store.selectSnapshot(EventsState.webbankirCrossId)) {
      ctx.dispatch(new EventsActions.SetWebbankirCrossId());
    }

    const webbankirId = this.store.selectSnapshot(EventsState.webbankirCrossId);

    Sentry.setUser({ webbankirId });
  }

  @Selector()
  public static webbankirCrossId(state: IEventsState): string {
    return state.webbankirId;
  }

  @Action(EventsActions.NewSession)
  public newSession(ctx: StateContext<IEventsState>, action: EventsActions.NewSession) {
    const { sessionId } = ctx.getState();

    if (!sessionId) {
      ctx.setState(
        patch({
          sessionId: uuid(),
        }),
      );
    }
  }

  @Action(EventsActions.SetWebbankirCrossId)
  public setWebbankirCrossId(ctx: StateContext<IEventsState>, action: EventsActions.SetWebbankirCrossId) {
    ctx.setState(
      patch({
        webbankirId: action.webbankirId || uuid(),
      }),
    );
  }

  @Action(EventsActions.NewUser)
  public newUser(ctx: StateContext<IEventsState>, action: EventsActions.NewUser) {}

  @Action(EventsActions.GaEvent)
  public gaEvent(ctx: StateContext<IEventsState>, action: EventsActions.GaEvent) {
    this.service.gaEvent(action.payload);
  }

  @Action(EventsActions.FieldEvent)
  public fieldEvent(ctx: StateContext<IEventsState>, action: EventsActions.FieldEvent) {
    return this.service.createEvent(action.payload);
  }

  @Action(EventsActions.ForwardLK)
  public forwardLK(ctx: StateContext<IEventsState>, action: EventsActions.ForwardLK) {
    return this.service.createEvent({
      jsonrpc: '2.0',
      method: 'add_metric',
      params: {
        '@type': 'counter',
        name: EventName.ForwardToLK,
        labels: { type: 'loaded' },
        is_business_metric: false,
        value: 1,
      },
    });
  }

  @Action(EventsActions.Event)
  public event(ctx: StateContext<IEventsState>, action: EventsActions.Event) {
    return this.service.createEvent({
      id: uuid(),
      jsonrpc: '2.0',
      method: 'create_event',
      params: {
        ...action.payload,
        '@type': EVENT_NAME_MAP.get(action.payload.name),
        client_uniq_id: this.store.selectSnapshot(EventsState.webbankirCrossId),
        system: 'lk',
        platform: 'web',
        project: 'webbankir',
      },
    });
  }

  @Action(EventsActions.Mindbox)
  public mindbox(ctx: StateContext<IEventsState>, action: EventsActions.Mindbox) {
    const payload = {
      ...action.payload,
      customer: {
        ...action.payload.customer,
        ids: {
          webbankirID: this.store.selectSnapshot(EventsState.webbankirCrossId),
          login: this.store.selectSnapshot(ProfileState.info)?.login,
        },
        subscriptions: [{ pointOfContact: ContactPoint.SMS }, { pointOfContact: ContactPoint.Email }],
      },
    };

    return this.service.mindboxOperation(action.type, payload);
  }

  @Action(EventsActions.AddMetric)
  public addMetric(ctx: StateContext<IEventsState>,
                   action: EventsActions.AddMetric) {
    const statusCode = action.labels.status_code;
    const labels = {
      ...action.labels,
      ...(statusCode != null && {
        status_code: statusCode.toString(),
        is_success: statusCode[0] === '2' ? 'true' : 'false',
        is_business_metric: false,
      }),
    };
    return this.service.createEvent({
      jsonrpc: '2.0',
      method: 'add_metric',
      params: {
        '@type': EVENT_NAME_MAP.get(EventName.FrontendLkApiRestRequests),
        name: action?.name || EventName.FrontendLkApiRestRequests,
        labels,
        value: 1,
      },
    },
      true,
    );
  }

  @Action(EventsActions.RegistrationStepOneComplete)
  public registrationStepOneComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.RegistrationStepOneComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    ctx.dispatch(new EventsActions.GaEvent(GaEvent.USER_REGISTER_ATTEMPT));
    ctx.dispatch(
      new EventsActions.Event({
        ...getPartialRegistrationPayload(profileForm?.phone),
        step_completed: 1,
      }),
    );
  }

  @Action(EventsActions.RegistrationStepConfirmationComplete)
  public registrationStepConfirmationComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.RegistrationStepConfirmationComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    ctx.dispatch(
      new EventsActions.Mindbox(MindboxOperation.Step4, {
        customer: getCustomerRegistration(profileForm),
      }),
    );
    ctx.dispatch(
      new EventsActions.Event({
        name: EventName.PhoneLinked,
        category: CategoryName.Registration,
        payload: {
          phone: profileForm?.phone ? '7' + profileForm.phone : null,
        },
      }),
    );
  }

  @Action(EventsActions.PhoneConfirmed)
  public singingLoanComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.PhoneConfirmed,
  ) {
    ctx.dispatch(
      new EventsActions.Mindbox(MindboxOperation.Step4, {
        customer: {
          mobilePhone: action.phone,
        },
      }),
    );
  }

  @Action(EventsActions.RegistrationStepTwoComplete)
  public registrationStepTwoComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.RegistrationStepTwoComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    ctx.dispatch(new EventsActions.GaEvent(GaEvent.CLIENT_NEXT_CONTACT));
    ctx.dispatch(
      new EventsActions.Mindbox(MindboxOperation.Step2, {
        customer: getCustomerRegistration(profileForm),
      }),
    );
    ctx.dispatch(
      new EventsActions.Event({
        ...getPartialRegistrationPayload(profileForm?.phone),
        step_completed: 2,
      }),
    );
  }

  @Action(EventsActions.RegistrationStepThreeComplete)
  public registrationStepThreeComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.RegistrationStepThreeComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    ctx.dispatch(new EventsActions.GaEvent(GaEvent.CLIENT_NEXT_ADDITIONAL));
    ctx.dispatch(
      new EventsActions.Mindbox(MindboxOperation.Step3, {
        customer: getCustomerRegistration(profileForm, true),
      }),
    );
    ctx.dispatch(
      new EventsActions.Event({
        ...getPartialRegistrationPayload(profileForm?.phone),
        step_completed: 3,
      }),
    );
  }

  @Action(EventsActions.RegistrationStepFourComplete)
  public registrationStepFourComplete(
    ctx: StateContext<IEventsState>,
    action: EventsActions.RegistrationStepFourComplete,
  ) {
    const profileForm = this.store.selectSnapshot(ProfileState.form);
    const productForm = this.store.selectSnapshot(ProductState.form);
    ctx.dispatch(new EventsActions.GaEvent(GaEvent.CLIENT_APPLY_LOAN_PRIMARY));
    ctx.dispatch(
      new EventsActions.Event({
        name: EventName.Registration,
        category: CategoryName.Registration,
        payload: {
          phone: profileForm?.phone ? '7' + profileForm.phone : null,
          method: productForm?.method?.label,
        },
        step_completed: 4,
      }),
    );
  }

  @Action(EventsActions.RegisterYandexUserId)
  public registerYandexUserId(ctx: StateContext<IEventsState>, action: EventsActions.RegisterYandexUserId) {
    this.yandexMetrikaService.setUserID(this.store.selectSnapshot(EventsState.webbankirCrossId));
  }

  @Action(EventsActions.EventV2)
  public eventV2(ctx: StateContext<IEventsState>, action: EventsActions.EventV2) {
    return this.service.createEvent({
      id: uuid(),
      jsonrpc: '2.0',
      method: 'create_event_v2',
      params: {
        ...action.payload,
        client_uniq_id: this.store.selectSnapshot(EventsState.webbankirCrossId),
        platform: 'web',
      },
    }, action.isNotCancelled);
  }
}
