import { ILogMessagePayload } from '../interfaces/log-message-payload.interface';
import { LoggerActionList } from './logger-action-list.const';

export namespace LoggerActions {
  export class Log {
    public static readonly type: LoggerActionList = LoggerActionList.LOG;
    constructor(public payload: ILogMessagePayload) {}
  }
  export class LogFail {
    public static readonly type: LoggerActionList = LoggerActionList.LOG_FAIL;
    constructor(public payload: ILogMessagePayload) {}
  }
}
