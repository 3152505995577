import { IDataResponse } from '@app/interfaces/data-response.interface';
import { ILoginResponse } from '@app/interfaces/login-response.interface';
import { IRegistrationApplicationDto } from '@app/states/registration/interfaces/registration-application-dto.interface';

export function isLoginResponse(payload: any): payload is ILoginResponse {
  return !!payload?.token;
}

// eslint-disable-next-line max-len
export function isLoginDataResponse(payload: IDataResponse<ILoginResponse> | IRegistrationApplicationDto): payload is IDataResponse<ILoginResponse> {
  return 'data' in payload ;
}
