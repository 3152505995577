import { EventName } from '@app/states/events/constants/event-name.const';

export const EVENT_NAME_MAP: Map<EventName, string> = new Map<EventName, string>([
  [EventName.Esia, 'common'],
  [EventName.InnFound, 'common'],
  [EventName.InnNotFound, 'common'],
  [EventName.PhoneChanged, 'common'],
  [EventName.PhoneLinked, 'common'],
  [EventName.CardType, 'common'],
  [EventName.CardLinked, 'common'],
  [EventName.Registration, 'registration_step_completed'],
  [EventName.MobileID, 'mobile_id_action'],
  [EventName.FrontendLkApiRestRequests, 'counter'],
  [EventName.CancelingBareBasket, 'canceling_bare_basket'],
  [EventName.ActiveLoanPageOpened, 'common'],
  [EventName.LoanDocumentsRequested, 'common'],
  [EventName.SmsCodeEntered, 'common'],
  [EventName.RefinancingInterestPaymentInitiated, 'common'],
  [EventName.RefinancingModalSuccess, 'common'],
]);
