/**
 *  WaitingSigning Требует подписания
 *  WaitingPayment Требует оплаты
 *  PreActive Не требует действий, но дата на начала пролонгации еще не наступила
 *  Active Активна
 *  Done Завершена
 */

export enum LoanCreditHolidayStatus {
  WaitingSigning = 1,
  WaitingPayment,
  PreActive,
  Active,
  Done,
}
