import { RiskAnalysisActionList } from '@app/states/risk-analysis/states/risk-analysis-action-list.const';
import { IRiskAnalysisData } from '@app/states/risk-analysis/interfaces/risk-analysis-data.interface';

export namespace RiskAnalysisActions {
  export class Set {
    public static readonly type = RiskAnalysisActionList.SET;
    constructor(public payload: Partial<IRiskAnalysisData>) {
    }
  }

  export class Reset {
    public static readonly type = RiskAnalysisActionList.RESET;
  }
}
