/**
 * Справочник действий состояния "Заём"
 */
export enum ProductActionList {
  LOAD = '[Product] load',
  LOAD_FAIL = '[Product] load fail',
  LOAD_SUCCESS = '[Product] load success',

  LOAD_COUNT = '[Product] load count loans',
  LOAD_COUNT_FAIL = '[Product] load count loans fail',
  LOAD_COUNT_SUCCESS = '[Product] load count loans success',

  PROLONGATION = '[Product] prolongation',
  PROLONGATION_FAIL = '[Product] prolongation fail',
  PROLONGATION_SUCCESS = '[Product] prolongation success',

  PROLONGATION_CREATE = '[Product] prolongation create',
  PROLONGATION_CREATE_FAIL = '[Product] prolongation create fail',
  PROLONGATION_CREATE_SUCCESS = '[Product] prolongation create success',

  PROLONGATION_SIGN = '[Product] prolongation sign',
  PROLONGATION_SIGN_FAIL = '[Product] prolongation sign fail',
  PROLONGATION_SIGN_SUCCESS = '[Product] prolongation sign success',

  PROLONGATION_DOCUMENT = '[Product] prolongation document',
  PROLONGATION_DOCUMENT_FAIL = '[Product] prolongation document fail',
  PROLONGATION_DOCUMENT_SUCCESS = '[Product] prolongation document success',

  PROLONGATION_SIGN_RESEND_CODE = '[Product] prolongation sign resend code',
  PROLONGATION_SIGN_RESEND_CODE_FAIL = '[Product] prolongation sign resend code fail',
  PROLONGATION_SIGN_RESEND_CODE_SUCCESS = '[Product] prolongation sign resend code success',

  SIGN = '[Product] sign',
  SIGN_FAIL = '[Product] sign fail',
  SIGN_SUCCESS = '[Product] sign success',

  RESEND_CODE = '[Product] resend code',
  RESEND_CODE_FAIL = '[Product] resend code fail',
  RESEND_CODE_SUCCESS = '[Product] resend code success',

  INSTALLMENT_PDF = '[Product] installment PDF',
  INSTALLMENT_PDF_FAIL = '[Product] installment PDF fail',
  INSTALLMENT_PDF_SUCCESS = '[Product] installment PDF success',

  SET_PAYMENT_SUM = '[Product] set payment sum',

  DISCLAIM = '[Product] disclaim',
  DISCLAIM_FAIL = '[Product] disclaim fail',
  DISCLAIM_SUCCESS = '[Product] disclaim success',

  CONSENTS = '[Product] consents',
  CONSENTS_FAIL = '[Product] consents fail',
  CONSENTS_SUCCESS = '[Product] consents success',

  CREATE = '[Product] loan create',
  CREATE_FAIL = '[Product] loan create fail',
  CREATE_SUCCESS = '[Product] loan create success',

  UPLOAD_PHOTO = '[Product] upload photo',
  UPLOAD_PHOTO_FAIL = '[Product] upload photo fail',
  UPLOAD_PHOTO_SUCCESS = '[Product] upload photo success',

  SUBMIT_WITHOUT_PHOTO = '[Product] submit without photo',
  SUBMIT_WITHOUT_PHOTO_FAIL = '[Product] submit without photo fail',
  SUBMIT_WITHOUT_PHOTO_SUCCESS = '[Product] submit without photo success',

  PDF = '[Product] PDF',
  PDF_FAIL = '[Product] PDF fail',
  PDF_SUCCESS = '[Product] PDF success',

  CONTRACT = '[Product] contract',
  CONTRACT_FAIL = '[Product] contract fail',
  CONTRACT_SUCCESS = '[Product] contract success',

  PAYMENT_METHODS = '[Product] payment methods',
  PAYMENT_METHODS_FAIL = '[Product] payment methods fail',
  PAYMENT_METHODS_SUCCESS = '[Product] payment methods success',

  PAYMENT_COMMISSION = '[Product] payment commission',
  PAYMENT_COMMISSION_FAIL = '[Product] payment commission fail',
  PAYMENT_COMMISSION_SUCCESS = '[Product] payment commission success',

  PAYMENT = '[Product] payment',
  PAYMENT_FAIL = '[Product] payment fail',
  PAYMENT_SUCCESS = '[Product] payment success',

  PAYMENT_V2 = '[Product] payment v2',
  PAYMENT_V2_SUCCESS = '[Product] payment v2 success',
  PAYMENT_V2_FAIL = '[Product] payment v2 fail',

  RESET = '[Product] reset',

  FACTS = '[Product] facts',
  FACTS_FAIL = '[Product] facts fail',
  FACTS_SUCCESS = '[Product] facts success',

  PROLONGATION_PDF = '[Product] prolongation PDF',
  PROLONGATION_PDF_FAIL = '[Product] prolongation PDF fail',
  PROLONGATION_PDF_SUCCESS = '[Product] prolongation PDF success',

  PROLONGATION_CONSENTS = '[Product] prolongation consents',
  PROLONGATION_CONSENTS_FAIL = '[Product] prolongation consents fail',
  PROLONGATION_CONSENTS_SUCCESS = '[Product] prolongation consents success',

  REFINANCING_CONSENTS = '[Product] refinancing consents',
  REFINANCING_CONSENTS_FAIL = '[Product] refinancing consents fail',
  REFINANCING_CONSENTS_SUCCESS = '[Product] refinancing consents success',

  REFINANCING_PAID = '[Product] refinancing paid',

  VIEW_CONSENT = '[Product] view consent',
  RESET_FORM = '[Product] reset form',
  SET_ADDITIONAL_LOAN_SUM = '[Product] set additional loan sum',
}
