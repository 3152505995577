import { RequestStatus } from '@app/constants/request-status.const';
import { IStateMigration } from '@app/interfaces/state-migration.interface';

/**
 * Класс миграций состояния "Профиль"
 */
export class ProfileStateMigrations {
  /** Метод получения перечня миграций хранилища */
  public static migrate(): IStateMigration[] {
    return [
      {
        version: undefined,
        key: 'profile',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 1,
          status: RequestStatus.NotInvoked,
          networks: null,
          phone: null,
          email: null,
          form: null,
          info: null,
        }),
      },
    ];
  }
}
