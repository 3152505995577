import {  Inject, Injectable } from '@angular/core';
import { YoomoneyWidgetCardDataErrorCode } from '@app/states/virtual-card/constants/yoomoney-widget/yoomoney-widget-card-data-error-code.const';
import { IYoomoneyWidgetAuthCode } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-auth-code.interface';
import { IYoomoneyWidgetChangePin } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-change-pin.interface';
import { IYoomoneyWidgetError } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-error.interface';
import { IYoomoneyWidgetTopUp } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-top-up.interface';
import { IYoomoneyWidgetTransfer } from '@app/states/virtual-card/interfaces/yoomoney-widget/yoomoney-widget-transfer.interface';
import { IEnvironment } from 'projects/web-bankir-app/src/environments/environment.interface';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YoomoneyWalletWidgetService {
  constructor(
    @Inject('environment') private environment: IEnvironment,
  ) {
  }

  private readonly yooMoneySrc = "https://yoomoney.ru/partner-wallet-widget/v1/widget.js";
  private yooMoneyWalletWidget: YooMoneyWalletWidget;

  public init(): void {
    if (typeof YooMoneyWalletWidget === 'undefined') {
      const yooMoney = document.createElement('script');
      yooMoney.type = 'text/javascript';
      yooMoney.async = true;
      yooMoney.src = this.yooMoneySrc;
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(yooMoney);
      
      yooMoney.addEventListener('load', () => {
        this.yooMoneyWalletWidget = new YooMoneyWalletWidget(this.environment.yoomoneyWidgetData);
      });
    }
  }

  public getAuthCode(url: string): Observable<IYoomoneyWidgetAuthCode> {
    return from<Observable<IYoomoneyWidgetAuthCode>>(this.yooMoneyWalletWidget.getAuthCode({authorizationEndpoint: url}))
  }

  /** В Promise объект возвращается только при неуспешном выполнении метода. */
  public displayCardData(widgetToken: string, cardId: string): Observable<IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>> {
    return from<Observable<IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>>>(this.yooMoneyWalletWidget.displayCardData({
      widgetToken,
      cardId
    }))
  }

  public changePin(widgetToken: string, cardId: string): Observable<IYoomoneyWidgetChangePin> {
    return from<Observable<IYoomoneyWidgetChangePin>>(this.yooMoneyWalletWidget.changePin({widgetToken,cardId}))
  }

  public topUp(widgetToken: string): Observable<IYoomoneyWidgetTopUp> {
    return from<Observable<IYoomoneyWidgetTopUp>>(this.yooMoneyWalletWidget.topUp({ widgetToken }))
  }

  public transfer(widgetToken: string): Observable<IYoomoneyWidgetTransfer> {
    return from<Observable<IYoomoneyWidgetTransfer>>(this.yooMoneyWalletWidget.transfer({widgetToken}))
  }
}
