import { IPayment3dsConfig } from '@app/interfaces/payment-3ds-config.interface';
import { InteractivePaymentType } from '@app/states/product/constants/interactive-payment-type.const';
import { IInteractivePaymentResponse } from '@app/states/product/interfaces/interactive-payment-response.interface';

export function getPayment3dsFormConfig(payload: IInteractivePaymentResponse): IPayment3dsConfig {
  let params = [];
  switch (payload.type) {
    case InteractivePaymentType.TdsMethod:
      params = [{ name: 'threeDSMethodData', value: payload.attributes?.tdsMethodData }];
      break;
    case InteractivePaymentType.TdsV2:
      params = [{ name: 'creq', value: payload.attributes?.creq }];
      break;
    case InteractivePaymentType.Processing:
      params = [];
      break;
    case InteractivePaymentType.TdsV1:
      params = [
        { name: 'MD', value: payload.attributes?.md },
        { name: 'PaReq', value: payload.attributes?.pareq },
        { name: 'TermUrl', value: payload.attributes?.termUrl },
      ];
      break;
  }
  return {
    url:
      payload.type === InteractivePaymentType.Processing
        ? payload.attributes?.processingUrl
        : payload.attributes.acsUrl,
    method: payload.type === InteractivePaymentType.Processing ? 'GET' : 'POST',
    params,
    waitForResponse: !(payload.type === InteractivePaymentType.TdsMethod),
  };
}
