/**
 *  Waiting ожидает рассмотрения
 *  Analyze рассматривается (идёт идентификация)
 *  Approved одобрена / договор ожидает подписания
 *  Signed договор подписан
 *  Payment договор в очереди на оплату (заём переведён в пункт выдачи)
 *  Issued выдан
 *  Closed договор закрыт
 *  AddInfoRequired требуется дополнительная информация
 *  IdRequired требуется идентификация клиента
 *  IdRefused клиент отказался от идентификации
 *  Rejected отказано,
 *  Revoked заявка отозвана клиентом
 *  Expired истек срок подписания
 *  ManualCheck ручная проверка
 */
export enum LoanStatus {
  Waiting = 1,
  Analyze,
  Approved,
  Signed,
  Payment,
  Issued,
  Closed,
  AddInfoRequired,
  IdRequired,
  IdRefused,
  Refinancing = 14,
  Rejected = 100,
  Revoked,
  Expired,
  FailedCardCheck,
  FailedRefinancing,
  ManualCheck = 200,
}
