import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAccountCreateDto } from '@app/interfaces/account-create-dto.interface';
import { IAccount } from '@app/interfaces/account.interface';
import { IAccountsResponse } from '@app/interfaces/accounts-response.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IJsonRpcResponse } from '@app/interfaces/jsonrpc-response.interface';
import { IAccountAddSMSVerify } from '@app/states/accounts/interfaces/account-add-sms-verify.interface';
import { ICardBank } from '@app/states/accounts/interfaces/card-bank.interface';
import { IInteractivePaymentResponse } from '@app/states/product/interfaces/interactive-payment-response.interface';
import { ISbpBank } from '@app/states/registration/interfaces/sbp-bank.interface';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { IAccountAddSMSResponse } from '../interfaces/account-add-sms-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private readonly accountsPath: string;
  private readonly paymentPath: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.accountsPath = `${this.environment.api}accounts`;
    this.paymentPath = `${this.environment.api}payment/v2`;
  }

  public accountsRequest(): Observable<IAccountsResponse> {
    return this.http.get(this.accountsPath) as Observable<IAccountsResponse>;
  }

  public banks(): Observable<IDataResponse<ISbpBank[]>> {
    return this.http.get<IDataResponse<ISbpBank[]>>(`${this.environment.api}payment/v2/sbp/banks`);
  }

  public bankCard(bin: string): Observable<IJsonRpcResponse<{ data: ICardBank }>> {
    return this.http.post<IJsonRpcResponse<{ data: ICardBank }>>(`${this.environment.gate}rpc`, {
      id: uuid(),
      jsonrpc: '2.0',
      method: 'find_card_bin',
      params: { bin },
    });
  }

  public checkCard(data): Observable<IDataResponse<IAccount[]>> {
    return this.http.post<IDataResponse<IAccount[]>>(`${this.accountsPath}/checkCard`, data);
  }

  public bindCard(data): Observable<HttpResponse<IDataResponse<IInteractivePaymentResponse>>> {
    return this.http.post<IDataResponse<IInteractivePaymentResponse>>(
      `${this.environment.api}payment/v3/payments/card/binding`,
      { data },
      { observe: 'response' }
    );
  }

  public sendCode(data: IAccountAddSMSVerify): Observable<IAccountAddSMSResponse> {
    return this.http.post<IAccountAddSMSResponse>(`${this.accountsPath}/v1/sendOtp`, data);
  }

  public createAccount(data: Partial<IAccountCreateDto>): Observable<IDataResponse<IAccount[]>> {
    return this.http.put<IDataResponse<IAccount[]>>(`${this.accountsPath}`, data);
  }

  public deleteRequest(payload) {
    return this.http.delete(`${this.accountsPath}/${payload}`, {});
  }

  public makeDefault(payload) {
    return this.http.patch(this.accountsPath, { id: payload, main: 1 });
  }

  public bindOptions() {
    return this.http.get(this.paymentPath);
  }
}
