/**
 * Справочник действий состояния "Профиль"
 */
export enum ProfileActionList {
  LOAD = '[Profile] Load user profile',
  LOAD_FAIL = '[Profile] Load user profile failed',
  LOAD_SUCCESS  = '[Profile] Load user profile success',

  LOAD_USER_SOCIAL_NETWORK = '[Profile] Load user social network',
  LOAD_USER_SOCIAL_NETWORK_FAIL = '[Profile]  Load user social network failed',
  LOAD_USER_SOCIAL_NETWORK_SUCCESS  = '[Profile]  Load user social network success',

  VERIFY = '[Profile] verify',
  VERIFY_FAIL = '[Profile] verify fail',
  VERIFY_SUCCESS = '[Profile] verify success',

  SUGGESTION = '[Profile] suggestion',
  SUGGESTION_FAIL = '[Profile] suggestion fail',
  SUGGESTION_SUCCESS = '[Profile] suggestion success',

  CHANGE_USER_PROFILE = '[Profile] Change user profile',
  CHANGE_USER_PROFILE_FAIL = '[Profile] Change user profile failed',
  CHANGE_USER_PROFILE_SUCCESS = '[Profile] Change user profile success',

  CHANGE_USER_PASSWORD = '[Profile] Change user password',
  CHANGE_USER_PASSWORD_FAIL = '[Profile] Change user password failed',
  CHANGE_USER_PASSWORD_SUCCESS = '[Profile] Change user password success',

  CHECK_USER_PHONE = '[Profile] Check user phone',
  CHECK_USER_PHONE_FAIL = '[Profile] Check user phone failed',
  CHECK_USER_PHONE_SUCCESS = '[Profile] Check user phone success',

  SEND_CODE_CONFIRMATION = '[Profile] Send code confirmation',
  SEND_CODE_CONFIRMATION_FAIL = '[Profile] Send code confirmation fail',
  SEND_CODE_CONFIRMATION_SUCCESS = '[Profile] Send code confirmation success',

  CHANGE_USER_PHONE = '[Profile] Change user phone',
  CHANGE_USER_PHONE_FAIL = '[Profile] Change user phone failed',
  CHANGE_USER_PHONE_SUCCESS = '[Profile] Change user phone success',

  CHANGE_USER_EMAIL = '[Profile] Change user email',
  CHANGE_USER_EMAIL_FAIL = '[Profile] Change user email failed',
  CHANGE_USER_EMAIL_SUCCESS = '[Profile] Change user email success',

  CONFIRM_USER_EMAIL = '[Profile] Confirm user email',
  CONFIRM_USER_EMAIL_FAIL = '[Profile] Confirm user email failed',
  CONFIRM_USER_EMAIL_SUCCESS = '[Profile] Confirm user email success',

  LOAD_EDITABLE_FIELDS = '[Profile] Load profile editable fields',
  LOAD_EDITABLE_FIELDS_FAIL = '[Profile] Load profile editable fields failed',
  LOAD_EDITABLE_FIELDS_SUCCESS = '[Profile] Load profile editable fields success',

  FILL_FORM = '[Profile] fill form',

  RESET = '[Profile] reset',

  RESET_MODAL_INFO = '[Profile] reset modal info',
  CHANGE_PROFILE = '[Profile] change profile',
  LOAD_REAAONS = '[Profile] load reasons',
  LOAD_REAAONS_FAIL = '[Profile] load reasons fail',
  LOAD_REAAONS_SUCCESS = '[Profile] load reasons success',

  RECOVER_PROFILE = '[Profile] recover profile',
  RECOVER_PROFILE_SUCCESS = '[Profile] recover profile success', 
  RECOVER_PROFILE_FAIL = '[Profile] recover profile fail',

  DELETE_PROFILE = '[Profile] delete profile',
  DELETE_PROFILE_SUCCESS = '[Profile] delete profile success',
  DELETE_PROFILE_FAIL = '[Profile] delete profile fail',
}
