import { Product } from '@app/states/calculator/constants/product.const';
import { ILSContractState } from '@app/states/product/constants/ils-contract-state.const';
import { PercentUnit } from '@app/states/product/constants/percent-unit.const';
import { TermUnit } from '@app/states/product/constants/term-unit.const';
import { IILSContractDTO } from '@app/states/product/interfaces/ils-contract-dto.interface';
import { IProduct } from '@app/states/product/interfaces/product.interface';
import { ilsContractStatusMap } from './product-ils-contract-status.helper';
import { holidayMap } from './product-supplementary.helper';

export function productIlsContractMap(id: string, product: IILSContractDTO): IProduct {
  return {
    id,
    type: Product.ILS,
    status: ilsContractStatusMap(product.state),
    documentUrl: `documents/v2/ils/list/${product.application}`,
    statementUrl: `documents/v2/ils/statement/${product.application}`,
    sum: {
      debt:
        product.state === ILSContractState.Pending
          ? product.fullCost.interestsTotal + product.loanAmount
          : product.supplementary?.holiday?.status
          ? product.payments?.currentPayment?.leftToPay
          : product.arrears.debtTotal,
      debtPaid: product.payments.currentPayment?.fundsDeposited,
      debtOld: product.arrears.debtTotal,
      debtPrincipal: product.arrears.debtPrincipal + product.arrears.overdueDebtPrincipal,
      debtPercent: product.arrears.debtInterests + product.arrears.overdueDebtInterests,
      debtPenalty: product.arrears.debtFines,
      initial: product.loanAmount,
      debtEnd: product.sumToRefund,
    },
    bonuses: {
      available: 0,
      received: 0,
    },
    date: {
      start: product.createdAt,
      end: product.expiredAt,
      oldEnd: '',
      request: '',
    },
    percent: {
      value: product.paymentSchedule.tariff.percentPerDay,
      unit: PercentUnit.Day,
    },
    currentPercent: null,
    term: {
      value: product.termInMonths,
      unit: TermUnit.Month,
    },
    overdue: {
      value: product.arrears.delay > 0,
      type: undefined,
      delay: product.arrears.delay > 0 ? product.arrears.delay : 0,
    },
    payments: {
      current: {
        date: product.payments.currentPayment?.date,
        sumPaid: 0,
        sumOverpayment: 0,
        sumLeft: product.payments.currentPayment?.leftToPay,
        sumTotal: product.payments.currentPayment?.amount,
        debtTotal: product.payments.currentPayment?.debtPrincipalSurplus,
        paid: product.payments.currentPayment?.leftToPay === 0,
        discount: 0,
      },
      all: [
        ...product.payments.pastPayments,
        product.arrears.delay > 0 ? null : product.payments.currentPayment,
        ...product.payments.futurePayments,
      ]
        .filter((i) => !!i)
        .map((p: any) => ({
          date: p.date || p.scheduledDate,
          sumPaid: 0,
          sumOverpayment: 0,
          sumLeft: p.leftToPay || p.amount,
          sumTotal: p.amount,
          debtTotal: p.debtPrincipalSurplus,
          paid: !!p.completedAt,
          discount: 0,
        })),
    },
    supplementary: {
      installment: null,
      amnesty: null,
      prolongation: null,
      holiday: holidayMap(product),
    },
    isSold: !!product.sold,
    canRefinancing: product.canRefinancing,
    refinanceTimeEnd: product.refinanceTimeLeft
      ? new Date(+new Date() + product.refinanceTimeLeft * 1000).toISOString()
      : null,
    notifications: product.notifications,
    refinancedFrom: null,
  };
}
