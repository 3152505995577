import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequestStatus } from '../../../constants/request-status.const';
import { ModalActions } from '../../modal/states/modal.actions';
import { IPasswordRecoveryConfirmResponse } from '../interfaces/password-recovery-confirm-response.interface';
import { IPasswordRecoveryMethod } from '../interfaces/password-recovery-method.interface';
import { IPasswordRecoverySuccessResponse } from '../interfaces/password-recovery-success-response.interface';
import { IResetPasswordResponse } from '../interfaces/reset-password-response.interface';
import { AuthService } from '../services/auth.service';
import { AUTH_STATE_DEFAULTS } from './auth-state-defaults.const';
import { AuthActions } from './auth.actions';
import { IAuthState, IAutologinData } from './auth.state.interface';
import { AccountsActions } from '@app/states/accounts/states/accounts.actions';
import { AppealsActions } from '@app/states/appeals/states/appeals.actions';
import { BonusActions } from '@app/states/bonus/states/bonus.actions';
import { CalculatorActions } from '@app/states/calculator/states/calculator.actions';
import { concat, finalize, of, zip } from 'rxjs';
import { LoansHistoryActions } from '@app/states/loans-history/states/loan-history/loans-history.actions';
import { ProfileActions } from '@app/states/profile/states/profile.actions';
import { isLoginResponse } from '@app/type-guards/login-response.type-guard';
import { RegistrationActions } from '@app/states/registration/states/registration.actions';
import { GlobalLoaderService } from '@app/components/global-loader/services/global-loader.service';
import { HttpHandlerService } from '@app/services/http-handler/http-handler.service';
import { ProductActions } from '@app/states/product/states/product.actions';
import { JivoChatService } from '@app/services/jivo-chat/jivo-chat.service';
import { CookieService } from '@app/services/cookie/cookie.service';
import { EventsActions } from '@app/states/events/states/events.actions';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { SettingsActions } from '@app/states/settings/states/settings.actions';
import { VirtualCardActions } from '@app/states/virtual-card/states/virtual-card.actions';
import { ExperimentsActions } from '@app/states/experiments/states/experiments.actions';

/**
 * Метод получения логина пользователя
 */
const getLoginValue = (login: string) => {
  const isPhone = /^(?<prefix>[7|8]?)(?<phone>9[0-9]{9})$/g.test(login.replace(/[^\d]/g, ''));
  if (isPhone) {
    login = login.replace(/[^\d]/g, '');
  }
  if (/^[9|0][\d]{9}$/.test(login)) {
    return '7' + login;
  } else if (login.startsWith('8') && isPhone) {
    return '7' + login.substr(1);
  } else {
    return login;
  }
};

/**
 * Класс NGXS состояния "Авторизация"
 */
@State<IAuthState>({
  name: 'Auth',
  defaults: AUTH_STATE_DEFAULTS,
})
@Injectable()
export class AuthState extends StateErrorHandler {
  /**
   * Конструктор класса состояния авторизации пользователя
   *
   * @param service [Сервис]{@link AuthService} авторизации пользователя
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   * @param loader [Сервис]{@link GlobalLoaderService} лоадера
   * @param httpHandler [Сервис]{@link HttpHandlerService} управления http запросами
   * @param jivoChat [Сервис]{@link JivoChatService} JIVO чат API
   * @param cookie [Сервис]{@link CookieService} работы с Cookie-файлами
   */
  constructor(
    private service: AuthService,
    protected toast: ToastService,
    private loader: GlobalLoaderService,
    private httpHandler: HttpHandlerService,
    private jivoChat: JivoChatService,
    private cookie: CookieService,
    protected store: Store,
  ) {
    super(toast, store);
  }

  /**
   * Селектор, возвращающий ID пользователя
   * @param state состояние авторизации пользователя
   */
  @Selector()
  public static userId(state: IAuthState) {
    return state.userId;
  }

  /**
   * Селектор, возвращающий автологин пользователя
   * @param state состояние авторизации пользователя
   */
  @Selector()
  public static autologin(state: IAuthState) {
    return state.autologin;
  }

  /**
   * Селектор, возвращающий код автологина пользователя
   * @param state состояние авторизации пользователя
   */
  @Selector()
  public static autologinCode(state: IAuthState) {
    return state.autologin?.code;
  }

  /**
   * Селектор, возвращающий информацию является ли пользователь авторизованным
   * @param state состояние авторизации пользователя
   */
  @Selector()
  public static isAuthorized(state: IAuthState) {
    return !!state.token && state.tokenExpired >= Math.floor(new Date().getTime() / 1000) && !state.registration;
  }

  /**
   * Действие для подтверждения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link AuthService#emailConfirm}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AuthActions#EmailConfirmSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#EmailConfirmFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.EmailConfirm)
  public emailConfirm(ctx: StateContext<IAuthState>,
                      action: AuthActions.EmailConfirm) {
    return this.service.emailConfirm(action.payload).pipe(
      tap((data) => ctx.dispatch(new AuthActions.EmailConfirmSuccess(data))),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.EmailConfirmFail', err);
        return ctx.dispatch(
          new AuthActions.EmailConfirmFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного подтверждения электронной почты пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.EmailConfirmFail)
  public emailConfirmFail(ctx: StateContext<IAuthState>,
                          action: AuthActions.EmailConfirmFail) {

  }

  /**
   * Действие для успешного подтверждения электронной почты пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.EmailConfirmSuccess)
  public emailConfirmSuccess(ctx: StateContext<IAuthState>,
                             action: AuthActions.EmailConfirmSuccess) {

  }

  /**
   * Действие для авторизации пользователя по токену
   *
   * Порядок выполнения:
   * - Если пользователь авторизован, обращение к [действию]{@link AuthActions#Logout},
   * иначе передаем [данные]{@link AuthActions#LoginSuccess} в состояние
   * и обращение к [действию]{@link Navigate} для навигации пользователя в кабинет
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.AuthByToken)
  public authByToken(ctx: StateContext<IAuthState>, action: AuthActions.AuthByToken) {
    const isAuthorized = ctx.getState().token;

    return (isAuthorized ? ctx.dispatch(new AuthActions.Logout()) : of(null)).pipe(
      tap(() => {
        ctx.dispatch(new AuthActions.LoginSuccess({
          userId: Number(action.payload.userId),
          token: action.payload.token,
          tokenExpired: Number(action.payload.expire),
          webbankirCrossId: action.payload.webbankirCrossId,
          webview: true,
        }));
        ctx.dispatch(new Navigate(['cabinet']));
      }));
  }

  /**
   * Действие для авторизации пользователя
   *
   * Порядок выполнения:
   * - Изменяем статус запроса
   * - Запрос к бэкенду {@link AuthService#loginRequest}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AuthActions#LoginSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#LoginFailed} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.Login)
  public login(ctx: StateContext<IAuthState>, action: AuthActions.Login) {
    ctx.setState(
      patch({
        loginRequestStatus: RequestStatus.Pending,
      }),
    );

    const login = getLoginValue(action.payload.login);

    const payload = { ...action.payload, login };

    return this.service.loginRequest(payload).pipe(
      map((response) => ctx.dispatch(new AuthActions.LoginSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.LoginFailed', err);
        return ctx.dispatch(
          new AuthActions.LoginFailed(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для сброса состояния автологина
   *
   * Порядок выполнения:
   * - Очистить данные автологина в хранилище
   *
   * @param ctx
   */
  @Action(AuthActions.ResetAutoLogin)
  public resetAutologin(ctx: StateContext<IAuthState>) {
    ctx.patchState({ autologin: null });
  }

  /**
   * Действие для автологина пользователя
   *
   * Порядок выполнения:
   * - Запуск лоадера {@link loader}
   * - Запрос к бэкенду {@link AuthService#autologinRequest}
   * - В случае успешного ответа
   *   - Если электронная почта подтверждена, вывод информационного сообщения об успешном подтверждении
   *   - Обращение к [действию]{@link AuthActions#LoginSuccess} и к [действию]{@link Navigate} для навигация пользователя в кабинет
   * - В случае неуспешного ответа
   *   - [обрабатываем]{@link AuthActions#LoginFailed} ошибку и обращение к [действию]{@link Navigate} для
   * навигация пользователя к авторизации
   * - Останавливаем лоадер {@link loader}
   * @param ctx
   * @param action
   */
  @Action(AuthActions.AutoLogin)
  public autologin(ctx: StateContext<IAuthState>, action: AuthActions.AutoLogin) {
    const isAuthorized = ctx.getState().token;
    return (isAuthorized ? ctx.dispatch(new AuthActions.Logout()) : of(null)).pipe(
      switchMap(() => {
        this.loader.loading(true, 'login');
        return this.service.autologinRequest(action.hash).pipe(
          switchMap((response) => {
            if (action.isEmailConfirmed) {
              this.toast.notification({
                title: 'Успешно',
                text: 'Email клиента подтвержден.',
                type: ToastType.Success,
              });
            }
            const autoLoginSiteReferer = new URL('https://webbankir.com/lk2/account');
            if (response.data?.additionalFields?.query) {
              for (const param in response.data?.additionalFields?.query) {
                autoLoginSiteReferer.searchParams.set(param, response.data?.additionalFields?.query[param]);
              }
            }
            this.cookie.setReferer(autoLoginSiteReferer.toString());

            return concat(
              ctx.dispatch(new AuthActions.LoginSuccess(response.data)),
              ctx.dispatch(new Navigate(['/cabinet'])),
            );
          }),
          catchError((err, caught) =>
            concat(
              ctx.dispatch(new Navigate(['/account'])),
              ctx.dispatch(new AuthActions.LoginFailed(err.error?.errors)),
            ),
          ),
          finalize(() => this.loader.loading(false, 'login')),
        );
    }));
  }

  /**
   * Действие для успешной авторизации пользователя
   *
   * Порядок выполнения:
   * - Обновляем данные в хранилище
   * - Изменяем ID пользователя для чата
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.LoginSuccess)
  public loginSuccess(ctx: StateContext<IAuthState>, action: AuthActions.LoginSuccess) {
    ctx.dispatch(new EventsActions.SetWebbankirCrossId(action.payload.webbankirCrossId));

    ctx.setState(
      patch({
        token: action.payload.token,
        tokenExpired: action.payload.tokenExpired,
        userId: action.payload.userId,
        webview: action.payload.webview,
        registration: action.payload.registration || false,
        loginRequestStatus: RequestStatus.Load,
        autologin:
          'additionalFields' in action.payload
            ? {
              code: action.payload.additionalFields.code,
              redirect: action.payload.additionalFields.query?.utm_content,
            } as IAutologinData
            : undefined,
      }),
    );
    ctx.dispatch(new ExperimentsActions.UpdateAttributes({ id: action.payload.userId }));
  }

  /**
   * Действие для неуспешной авторизации пользователя
   *
   * Порядок выполнения:
   * - Изменяем статус запроса
   * - Вывод информационного сообщения об ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.LoginFailed)
  public loginFailed(ctx: StateContext<IAuthState>, action: AuthActions.LoginFailed) {
    ctx.setState(
      patch({
        loginRequestStatus: RequestStatus.Error,
      }),
    );

    action.payload?.forEach((err) => {
      if (err.code === 'loginOrPasswordMismatching') {
        this.toast.notification({
          title: 'Ошибка',
          // eslint-disable-next-line max-len
          text: `Неверный логин или пароль.<br/>Данные для входа были направлены вам по СМС на номер телефона при регистрации.`,
          type: ToastType.Error,
        });
      } else {
        this.toast.notification({
          title: 'Ошибка',
          text: err.message,
          type: ToastType.Error,
        });
      }
    });
  }

  /**
   * Действие для авторизации пользователя через госуслуги
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link AuthService#esiaData}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AuthActions#EsiaSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#EsiaFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.Esia)
  public esia(ctx: StateContext<IAuthState>, action: AuthActions.Esia) {
    return this.service.esiaData(action.payload).pipe(
      tap((response: any) =>
        ctx.dispatch(new AuthActions.EsiaSuccess(
          isLoginResponse(response.data) ? response.data : response,
          action.payload,
        )),
      ),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.EsiaFail', err);
        return ctx.dispatch(
          new AuthActions.EsiaFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешной авторизации пользователя через госуслуги
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.EsiaFail)
  public esiaFail(ctx: StateContext<IAuthState>, action: AuthActions.EsiaFail) {
    action.payload.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
    ctx.dispatch(new AuthActions.LoginFailed([]));
  }

  /**
   * Действие для успешной авторизации пользователя через госуслуги
   *
   * Порядок выполнения:
   * - Если пользователь авторизовался{@link isLoginResponse},
   * передаем [модифицированные данные]{@link AuthActions#LoginSuccess} в состояние,
   * иначе
   *   - Обращение к [действию]{@link AuthActions#LoginFailed},
   *   - Обращение к [действию]{@link RegistrationActions#EsiaSuccess}
   *   - Обращение к [действию]{@link Navigate} для навигации пользователя к регистрации
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.EsiaSuccess)
  public esiaSuccess(ctx: StateContext<IAuthState>, action: AuthActions.EsiaSuccess) {
    if (isLoginResponse(action.payload)) {
      ctx.dispatch(new AuthActions.LoginSuccess(action.payload));
      return;
    }

    ctx.dispatch(new AuthActions.LoginFailed([]));
    ctx.dispatch(new RegistrationActions.EsiaSuccess(action.payload, action.key));
    ctx.dispatch(new Navigate(['/account', 'registration']));
  }

  /**
   * Действие для проверки возможности восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Изменяем статус запроса данных и идентификации пользователя
   * - Запрос к бэкенду {@link AuthService#passwordRecoveryCheck}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AuthActions#RecoveryCheckSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#RecoveryCheckFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.RecoveryCheck)
  public recoveryCheck(ctx: StateContext<IAuthState>, action: AuthActions.RecoveryCheck) {
    ctx.setState(
      patch({
        recoveryCheckStatus: RequestStatus.Pending,
        recoveryIdentity: action.payload.data.attributes.identity,
      }),
    );
    return this.service.passwordRecoveryCheck(action.payload).pipe(
      map((res: IDataResponse<IPasswordRecoveryMethod[]>) =>
        ctx.dispatch(new AuthActions.RecoveryCheckSuccess(res.data)),
      ),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.RecoveryCheckFail', err);
        return ctx.dispatch(
          new AuthActions.RecoveryCheckFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для успешной проверки возможности восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Изменяем статус запроса данных и добавляем методы восстановления пароля пользователя в хранилище
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.RecoveryCheckSuccess)
  public recoveryCheckSuccess(ctx: StateContext<IAuthState>, action: AuthActions.RecoveryCheckSuccess) {
    ctx.setState(
      patch({
        passwordRecoveryMethods: action.payload,
        recoveryCheckStatus: RequestStatus.Load,
      }),
    );
  }

  /**
   * Действие для неуспешной проверки возможности восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.RecoveryCheckFail)
  public recoveryCheckFailed(ctx: StateContext<IAuthState>, action: AuthActions.RecoveryCheckFail) {
    action.payload.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   * Действие для восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Изменям статус запроса
   * - Запрос к бэкенду {@link AuthService#passwordRecovery}
   * - В случае успешного ответа со статусом 202 - передаем
   * [модифицированные данные]{@link AuthActions#PasswordRecoveryViaSmsConfirmation} в состояние
   * - В случае успешного ответа со статусом 200 - передаем [модифицированные данные]{@link AuthActions#PasswordRecoverySuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#PasswordRecoveryFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.PasswordRecovery)
  public passwordRecovery(ctx: StateContext<IAuthState>, action: AuthActions.PasswordRecovery) {
    ctx.setState(
      patch({
        passwordRecoveryStatus: RequestStatus.Pending,
      }),
    );

    return this.service.passwordRecovery(action.payload).pipe(
      map((data: HttpResponse<IPasswordRecoverySuccessResponse | IPasswordRecoveryConfirmResponse>) => {
        if (data.status === 202) {
          ctx.dispatch(
            new AuthActions.PasswordRecoveryViaSmsConfirmation(data.body as IPasswordRecoveryConfirmResponse),
          );
        } else if (data.status === 200) {
          ctx.dispatch(new AuthActions.PasswordRecoverySuccess(data.body as IPasswordRecoverySuccessResponse));
        }
      }),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.PasswordRecoveryFail', err);
        return ctx.dispatch(
          new AuthActions.PasswordRecoveryFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для неуспешного восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.PasswordRecoveryFail)
  public passwordRecoveryFail(ctx: StateContext<IAuthState>, action: AuthActions.PasswordRecoveryFail) {
    action.payload.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   *  Действие для деавторизация пользователя
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link Navigate} для выхода из системы
   * - Обращение к действиям
   *    - {@link AuthActions#Reset}
   *    - {@link RegistrationActions#Reset}
   *    - {@link AccountsActions#Reset}
   *    - {@link AppealsActions#Reset}
   *    - {@link BonusActions#Reset}
   *    - {@link CalculatorActions#Reset}
   *    - {@link LoansHistoryActions#Reset}
   *    - {@link ProductActions#Reset}
   *    - {@link ProfileActions#Reset}
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.Logout)
  public logout(ctx: StateContext<IAuthState>) {
    this.jivoChat.signout();

    return ctx
      .dispatch(new Navigate(['/logout']))
      .pipe(
        tap(() => this.httpHandler.cancelPendingRequests()),
        switchMap(() =>
          zip(
            ctx.dispatch(new AuthActions.Reset()),
            ctx.dispatch(new RegistrationActions.Reset()),
            ctx.dispatch(new AccountsActions.Reset()),
            ctx.dispatch(new AppealsActions.Reset()),
            ctx.dispatch(new BonusActions.Reset()),
            ctx.dispatch(new CalculatorActions.Reset()),
            ctx.dispatch(new LoansHistoryActions.Reset()),
            ctx.dispatch(new ProductActions.Reset()),
            ctx.dispatch(new ProfileActions.Reset()),
            ctx.dispatch(new SettingsActions.Reset()),
            ctx.dispatch(new VirtualCardActions.Reset()),
            ctx.dispatch(new ExperimentsActions.Reset())
          ),
        ),
        switchMap(() => ctx.dispatch(new Navigate(['']))),
        tap(() => ctx.dispatch(new EventsActions.SetWebbankirCrossId())),
      );
  }

  /**
   * Действие для успешного восстановления пароля пользователя
   *
   * Порядок выполнения:
   * - Если метод восстановления email, обновляем данные токена в хранилище
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.PasswordRecoverySuccess)
  public passwordRecoverySuccess(ctx: StateContext<IAuthState>, action: AuthActions.PasswordRecoverySuccess) {
    if (action.payload.data.attributes.method !== 'email') {
      ctx.setState(
        patch({
          token: action.payload.data.attributes.oauth2.token,
          tokenExpired: Math.floor(new Date().getTime() / 1000) + action.payload.data.attributes.oauth2.expires_in,
        }),
      );
    }
  }

  /**
   * Действие для восстановления пароля пользователя через смс-код
   *
   * Порядок выполнения:
   * - Обновляем данные идентификации пользователя в хранилище
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.PasswordRecoveryViaSmsConfirmation)
  public passwordRecoveryViaSmsConfirmation(
    ctx: StateContext<IAuthState>,
    action: AuthActions.PasswordRecoveryViaSmsConfirmation,
  ) {
    ctx.setState(
      patch({
        recoveryConfirmIdentity: action.payload.data.attributes.identity,
      }),
    );
  }

  /**
   *  Действие для изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Изменям статус запроса
   * - Если передан hash, запрос к бэкенду {@link AuthService#resetPasswordHash}, иначе  запрос к бэкенду {@link AuthService#resetPassword}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link AuthActions#ResetPasswordSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AuthActions#ResetPasswordFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.ResetPassword)
  public resetPassword(ctx: StateContext<IAuthState>, action: AuthActions.ResetPassword) {
    ctx.setState(
      patch({
        resetPasswordStatus: RequestStatus.Pending,
      }),
    );

    return (action.payload.hash ?
        this.service.resetPasswordHash({ password: action.payload.new_password, emailHash: action.payload.hash }) :
        this.service.resetPassword(action.payload)
    ).pipe(
      map((data: IResetPasswordResponse) => ctx.dispatch(new AuthActions.ResetPasswordSuccess(data))),
      catchError((err, caught) => {
        this.catchSentryError('AuthActions.ResetPasswordFail', err);
        return ctx.dispatch(
          new AuthActions.ResetPasswordFail(err.error?.errors),
        );
      }),
    );
  }

  /**
   * Действие для успешного изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Обновляем статус запроса
   * - Обращение к [действию]{@link ModalActions#CloseModal} для закрытия модального окна
   * - Обращение к [действию]{@link Navigate} для навигации пользователя в кабинет
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.ResetPasswordSuccess)
  public resetPasswordSuccess(ctx: StateContext<IAuthState>) {
    ctx.setState(
      patch({
        resetPasswordStatus: RequestStatus.Load,
      }),
    );

    ctx.dispatch(new ModalActions.CloseModal());
    ctx.dispatch(new Navigate(['cabinet']));
  }

  /**
   * Действие для неуспешного изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.ResetPasswordFail)
  public resetPasswordFail(ctx: StateContext<IAuthState>, action: AuthActions.ResetPasswordFail) {
    action.payload.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(AuthActions.Reset)
  public reset(ctx: StateContext<IAuthState>, action: AuthActions.Reset) {
    ctx.setState(AUTH_STATE_DEFAULTS);
  }
}
