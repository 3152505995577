export enum ProductStatus {
  Waiting = 1,
  Analyze,
  Signing,
  Signed,
  Payment,
  Issued,
  Closed,
  AddInfoRequired,
  IdRequired,
  IdRefused,
  Refinancing = 14,
  Rejected = 100,
  Revoked,
  Expired,
  FailedCardCheck,
  FailedRefinancing,
  ManualCheck = 200,
}
