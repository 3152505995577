export enum ModalInfoType {
    /** Успешное рефинансирование */
    SuccessRefinancing = 'successRefinancing',
    /** Заём не рефинансирован */
    FailedRefinancing = 'failedRefinancing',
    /** Ошибка проверки карты */
    FailedCardCheck = 'failedCardCheck',
    /** Изменение суммы дохода */
    WorkSalaryChanged = 'workSalaryChanged',
    /** Для пользователей в просрочке, которым доступно рефинансирование */ 
    Refinancingorder = 'refinancingOrder',
}
  