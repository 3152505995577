import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector} from '@angular/core';
import { IEnvironment } from '../../../environments/environment.interface';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly REFERRER_COOKIE_EXPIRE: number = 30;
  private readonly REFERRER_COOKIE_NAME: string = 'site_referer';
  public static referrer: string;
  public environment: IEnvironment = this.injector.get('environment');

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected injector: Injector,
  ) {
    this.setReferer();
  }

  public get domain() {
    return this.environment.release !== 'development' ?
      `Domain=.${this.document.location.hostname}; ` :
      ``;
  }

  public set(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = dayjs();
      expires = '; expires=' + date.add(days, 'days').toDate();
    }
    this.document.cookie = name + '=' + (value || '') + expires + '; Secure; ' + this.domain + 'path=/;';
  }

  public get(name: string): string {
    const nameEQ = name + '=';
    const ca = this.document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  public remove(name: string): void {
    this.document.cookie = name + '=; Max-Age=-99999999;';
  }

  public setReferer(value: string = ''): void {
    let url = new URL(this.document.location.href);

    if (value) {
      this.set(this.REFERRER_COOKIE_NAME, btoa(value), this.REFERRER_COOKIE_EXPIRE);
    } else if (url.searchParams.has('utm_source') || url.searchParams.has('utmsource')) {
      if (!url.searchParams.has('wb_link_creation')) {
        url.searchParams.set('wb_link_creation', `${+new Date()}`);
      }

      this.set(this.REFERRER_COOKIE_NAME, btoa(url.toString()), this.REFERRER_COOKIE_EXPIRE);

    } else if (!this.get(this.REFERRER_COOKIE_NAME)) {
      url = this.document.referrer ? new URL(this.document.referrer) : url;

      if (!url.searchParams.has('wb_link_creation')) {
        url.searchParams.set('wb_link_creation', `${+new Date()}`);
      }

      this.set(this.REFERRER_COOKIE_NAME, btoa(url.toString()), this.REFERRER_COOKIE_EXPIRE);
    }

    CookieService.referrer = this.get(this.REFERRER_COOKIE_NAME);
  }
}
