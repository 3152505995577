import { ILoanCreditHoliday } from '@app/interfaces/loan/loan-credit-holiday.interface';
import { IILSApplicationDTO } from '@app/states/product/interfaces/ils-application-dto.interface';
import { IInstallment } from '@app/states/product/interfaces/supplementary/installment.interface';
import { IProlongation } from '@app/states/product/interfaces/supplementary/prolongation.interface';
import dayjs from 'dayjs';
import { IILSContractDTO } from '../interfaces/ils-contract-dto.interface';
import { IPDLDTO } from '../interfaces/pdl-dto.interface';

export function holidayMap(product: IILSApplicationDTO | IPDLDTO | IILSContractDTO): ILoanCreditHoliday {
  if (!product.supplementary?.holiday) {
    return undefined;
  }

  return {
    id: product.supplementary.holiday.id,
    dateEnd: product.supplementary.holiday.dateEnd,
    dateOfCreation: product.supplementary.holiday.dateOfCreation,
    dateStart: product.supplementary.holiday.dateStart,
    loanPercent: product.supplementary.holiday.loanPercent,
    status: product.supplementary.holiday.status,
    terminationDate: product.supplementary.holiday.terminationDate,
    info: product.supplementary.holiday.info,
  };
}

export function amnestyMap(product: IILSApplicationDTO | IPDLDTO): IInstallment {
  if (!product.supplementary?.amnesty) {
    return undefined;
  }

  return {
    id: product.supplementary.amnesty.id,
    initialAmount: product.supplementary.amnesty.depositAmount,
    payDate: product.supplementary.amnesty.payDateEnd,
    signDate: product.supplementary.amnesty.signDateEnd,
    status: product.supplementary.amnesty.status,
    discount: 0,
  };
}

export function prolongationMap(product: IILSApplicationDTO | IPDLDTO): IProlongation {
  if (!product.supplementary?.prolongation) {
    return undefined;
  }

  return {
    id: product.supplementary.prolongation.id,
    initialAmount: product.supplementary.prolongation.depositAmount,
    percentAmount: product.supplementary.prolongation.loanPercent,
    payDate: product.supplementary.prolongation.payDateEnd
      ? dayjs.utc(product.supplementary.prolongation.payDateEnd).utcOffset(3, true).format()
      : null,
    signDate: dayjs(product.supplementary.prolongation.signDateEnd)?.toISOString(),
    loanEndDate: dayjs(product.supplementary.prolongation.loanDateEnd)?.toISOString(),
    status: product.supplementary.prolongation.status,
    dateStart: product.supplementary.prolongation.dateStart,
  };
}

export function installmentMap(product: IILSApplicationDTO): IInstallment {
  if (product.supplementary?.installnesty) {
    return {
      id: product.supplementary.installnesty.id,
      initialAmount: product.supplementary.installnesty.depositAmount,
      payDate: product.supplementary.installnesty.payDateEnd,
      signDate: product.supplementary.installnesty.signDateEnd,
      status: product.supplementary.installnesty.status,
      discount: product.supplementary.installnesty.writeOff,
      payments: product.supplementary.installnesty.paymentsPlan.map((p) => ({
        date: p.payDateEnd,
        sumPaid: p.sumPaidPerPeriod - p.sumLeftPerPeriod,
        sumOverpayment: 0,
        sumLeft: p.sumLeftPerPeriod,
        sumTotal: p.sumPaidPerPeriod,
        debtTotal: p.totalSumLeftToPay,
        paid: p.paid,
        discount: p.writeOff,
      })),
    };
  }

  if (product.supplementary?.installment) {
    return {
      id: product.supplementary.installment.id,
      initialAmount: product.supplementary.installment.depositAmount,
      payDate: product.supplementary.installment.payDateEnd,
      signDate: product.supplementary.installment.signDateEnd,
      status: product.supplementary.installment.status,
      discount: 0,
      payments: product.supplementary.installment.paymentsPlan.map((p) => ({
        date: p.payDateEnd,
        sumOverpayment: 0,
        sumPaid: p.sumLeftToPayPerMonth,
        sumLeft: p.totalSumToPayPerMonth - p.sumLeftToPayPerMonth,
        sumTotal: p.totalSumToPayPerMonth,
        debtTotal: p.totalSumLeftToPay,
        paid: p.payed,
        discount: 0,
      })),
    };
  }

  return undefined;
}
