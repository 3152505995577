import { RequestStatus } from '../../../constants/request-status.const';
import { IAuthState } from './auth.state.interface';

/**
 * Дефолтное состояние хранилища "Авторизация" при его инициализации
 */
export const AUTH_STATE_DEFAULTS: IAuthState = {
  version: 2,
  token: null,
  tokenExpired: null,
  userId: null,
  webview: false,
  passwordRecoveryMethods: null,
  loginRequestStatus: RequestStatus.NotInvoked,
  recoveryCheckStatus: RequestStatus.NotInvoked,
  recoveryIdentity: null,
  passwordRecoveryStatus: RequestStatus.NotInvoked,
  recoveryConfirmIdentity: null,
  resetPasswordStatus: RequestStatus.NotInvoked,
  esiaDataResponse: null,
  autologin: null,
  registration: false,
};
