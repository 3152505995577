import { ConsentPackage } from '@app/constants/consent-package.const';
import { IConsentDto } from '@app/interfaces/consent-dto.interface';
import { IError } from '@app/interfaces/error.interface';
import { IFingerPrint } from '@app/interfaces/finger-print.interface';
import { IProlongation } from '@app/interfaces/loan/prolongation.interface';
import { Product } from '@app/states/calculator/constants/product.const';
import { ICommissionResponse } from '@app/states/product/interfaces/commission-response.interface';
import { IContract } from '@app/states/product/interfaces/contract.interface';
import { IDisclaim } from '@app/states/product/interfaces/disclaim.interface';
import { IFileUploadDto } from '@app/states/product/interfaces/file-upload-dto.interface';
import { IPaymentMethod } from '@app/states/product/interfaces/payment-method.interface';
import { IPaymentResponse } from '@app/states/product/interfaces/payment-response.interface';
import { IProductDTO } from '@app/states/product/interfaces/product-dto.interface';
import { ProductActionList } from '@app/states/product/states/product-action-list.const';
import { IClosedCount } from '@app/states/registration/interfaces/loan-closed.interface';
import { IProduct } from '../interfaces/product.interface';

/**
 * Namespace для действий состояния "Заём"
 */
export namespace ProductActions {
  /**
   * Действие для загрузки займа
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD;

    /**
     * Тело действия
     *
     * @param payload Информация обновлять ли статус запроса на {@link #RequestStatus#Pending} и очищать информацию о займе
     */
    constructor(public payload: boolean = false) {}
  }

  /**
   * Действие для неуспешной загрузки займа
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной загрузки займа
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные займа
     */
    constructor(public payload: IProductDTO[]) {}
  }

  /**
   * Действие получении информации о количестве займов
   */
  export class LoadCount {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD_COUNT;

    /**
     * Тело действия
     *
     * @param payload onlyClosed
     */
    constructor(public payload: boolean = false) {}
  }

  /**
   * Действие для неуспешной загрузки количества займов
   */
  export class LoadCountFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD_COUNT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной загрузки количества займов
   */
  export class LoadCountSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.LOAD_COUNT_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные кол-ва займов
     */
    constructor(public payload: IClosedCount) {}
  }

  /**
   * Действие для загрузки информации о пролонгации
   */
  export class Prolongation {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION;
  }

  /**
   * Действие для успешной загрузки информации о пролонгации
   */
  export class ProlongationSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные пролонгации
     */
    constructor(public payload: IProlongation) {}
  }

  /**
   * Действие для неуспешной загрузки информации о пролонгации
   */
  export class ProlongationFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для создания пролонгации
   */
  export class ProlongationCreate {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CREATE;
  }

  /**
   * Действие для успешного создания пролонгации
   */
  export class ProlongationCreateSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CREATE_SUCCESS;
  }

  /**
   * Действие для неуспешного создания пролонгации
   */
  export class ProlongationCreateFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CREATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для подписания договора
   */
  export class Sign {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SIGN;

    /**
     * Тело действия
     *
     * @param method Метод бэка для подписания документа
     * @param payload Пользовательские данные для подписания (код из СМС, ПЭП и пр)
     * @param loanType Тип займа
     */
    constructor(public method: string, public payload: any, public loanType: Product) {}
  }

  /**
   * Действие для неуспешного подписания договора
   */
  export class SignFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SIGN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешного подписания договора
   */
  export class SignSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SIGN_SUCCESS;
  }

  /**
   * Действие для запроса повторного кода
   */
  export class ResendCode {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.RESEND_CODE;

    /**
     * Тело действия
     *
     * @param loan Заем
     * @param payload Тип документа
     */
    constructor(public loan: IProduct, public payload?: string) {}
  }

  /**
   * Действие для неуспешного запроса повторного кода
   */
  export class ResendCodeFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.RESEND_CODE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешного запроса повторного кода
   */
  export class ResendCodeSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.RESEND_CODE_SUCCESS;
  }

  /**
   *  Действие для загрузки pdf документов рассрочки/амнистии
   */
  export class InstallmentPdf {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.INSTALLMENT_PDF;

    /**
     * Тело действия
     *
     * @param payload ID
     * @param loanId ID займа
     */
    constructor(public payload: string, public loanId: string) {}
  }

  /**
   *  Действие для неуспешной загрузки pdf документов рассрочки/амнистии
   */
  export class InstallmentPdfFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.INSTALLMENT_PDF_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешной загрузки pdf документов рассрочки/амнистии
   */
  export class InstallmentPdfSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.INSTALLMENT_PDF_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные документа рассрочки/амнистии
     */
    constructor(public payload: Blob) {}
  }

  /**
   *  Действие для установки суммы оплаты
   */
  export class SetPaymentSum {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SET_PAYMENT_SUM;

    /**
     * Тело действия
     *
     * @param payload Сумма
     */
    constructor(public payload: number) {}
  }

  /**
   *  Действие для получения информации о блокировке получения займа
   */
  export class Disclaim {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.DISCLAIM;
  }

  /**
   *  Действие для успешного получения информации о блокировке получения займа
   */
  export class DisclaimSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.DISCLAIM_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные о блокировке получения займа
     */
    constructor(public payload: IDisclaim) {}
  }

  /**
   *  Действие для неуспешного получения информации о блокировке получения займа
   */
  export class DisclaimFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.DISCLAIM_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для загрузки согласий
   */
  export class Consents {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONSENTS;

    /**
     * Тело действия
     *
     * @param payload Необходимые пакеты согласия
     * @param product Тип займа
     */
    constructor(public payload: ConsentPackage[], public product: Product = Product.PDL) {}
  }

  /**
   *  Действие для неуспешной загрузки согласий
   */
  export class ConsentsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONSENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешной загрузки согласий
   */
  export class ConsentsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONSENTS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные согласования
     * @param device Девайс
     */
    constructor(public payload: IConsentDto[], public device: IFingerPrint) {
      // TODO consent type enum
    }
  }

  /**
   *  Действие для создания займа
   */
  export class Create {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CREATE;

    /**
     * Тело действия
     *
     * @param payload параметр рефинансирования
     */
    constructor(public payload: string | null = null, public refinancedFromLoanType: Product = null) {}
  }

  /**
   *  Действие для неуспешного создания займа
   */
  export class CreateFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CREATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[], public errorCode: number) {}
  }

  /**
   *  Действие для успешного создания займа
   */
  export class CreateSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CREATE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные
     */
    constructor(public payload: IProductDTO) {}
  }

  /**
   *  Действие для загрузки фотографии
   */
  export class UploadPhoto {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.UPLOAD_PHOTO;

    /**
     * Тело действия
     *
     * @param payload Фотография
     * @param loan заём
     */
    constructor(public payload: Partial<IFileUploadDto>, public loan: IProduct) {}
  }

  /**
   *  Действие для неуспешной загрузки фотографии
   */
  export class UploadPhotoFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.UPLOAD_PHOTO_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешной загрузки фотографии
   */
  export class UploadPhotoSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.UPLOAD_PHOTO_SUCCESS;
  }

  /**
   *  Действие для подтверждения без фотографии
   */
  export class SubmitWithoutPhoto {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SUBMIT_WITHOUT_PHOTO;

    /**
     * Тело действия
     *
     * @param payload Данные займа
     */
    constructor(public payload: IProduct) {}
  }

  /**
   *  Действие для неуспешного подтверждения без фотографии
   */
  export class SubmitWithoutPhotoFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SUBMIT_WITHOUT_PHOTO_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешного подтверждения без фотографии
   */
  export class SubmitWithoutPhotoSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SUBMIT_WITHOUT_PHOTO_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Тип займа
     */
    constructor(public payload: Product) {}
  }

  /**
   *  Действие для получения Pdf
   */
  export class Pdf {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PDF;
    /**
     * Тело действия
     *
     * @param payload Ссылка на документ
     */
    constructor(public payload: string) {}
  }

  /**
   *  Действие для неуспешного получения Pdf
   */
  export class PdfFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PDF_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешного получения Pdf
   */
  export class PdfSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PDF_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные
     */
    constructor(public payload: Blob) {}
  }

  /**
   *  Действие для получения контракта
   */
  export class Contract {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONTRACT;
  }

  /**
   *  Действие для успешного получения контракта
   */
  export class ContractSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONTRACT_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные контракта
     */
    constructor(public payload: IContract) {}
  }

  /**
   *  Действие для неуспешного получения контракта
   */
  export class ContractFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.CONTRACT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для получения методов оплаты
   */
  export class PaymentMethods {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_METHODS;

    /**
     * Тело действия
     *
     * @param payload Тип займа
     */
    constructor(public payload: Product = Product.PDL) {}
  }

  /**
   *  Действие для неуспешного получения методов оплаты
   */
  export class PaymentMethodsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_METHODS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешного получения методов оплаты
   */
  export class PaymentMethodsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_METHODS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные способов оплаты
     */
    constructor(public payload: IPaymentMethod[]) {}
  }

  /**
   *  Действие для получения комиссии
   */
  export class PaymentCommission {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_COMMISSION;

    /**
     * Тело действия
     *
     * @param payload Сумма
     * @param service Сервис
     */
    constructor(public payload: number, public service: string) {}
  }

  /**
   *  Действие для неуспешного получения комиссии
   */
  export class PaymentCommissionFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_COMMISSION_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешного получения комиссии
   */
  export class PaymentCommissionSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_COMMISSION_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные комиссии
     */
    constructor(public payload: ICommissionResponse) {}
  }

  /**
   *  Действие второго запроса для оплаты
   */
  export class PaymentV2 {
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_V2;

    /**
     * Тело действия
     *
     * @param payload paymentId Идентификатор платежа
     * @param payload данные займа
     */
    constructor(public payload: string, public loan: IProduct, public timeout: number) {}
  }

  /**
   *  Действие успешного второго запроса для оплаты
   */
  export class PaymentV2Success {
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_V2_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные после оплаты
     */
    constructor(public payload: IPaymentResponse) {}
  }

  /**
   *  Действие неуспешного второго запроса для оплаты
   */

  export class PaymentV2Fail {
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_V2_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     * @param isHandled Флаг обработки ошибки в HttpErrorInterceptor
     */
    constructor(public payload: IError[], public isHandled: boolean = false) {}
  }

  /**
   *  Действие для оплаты
   */
  export class Payment {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT;

    /**
     * Тело действия
     *
     * @param payload Данные займа
     */
    constructor(public payload: IProduct) {}
  }

  /**
   *  Действие для неуспешной оплаты
   */
  export class PaymentFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     * @param isHandled Флаг обработки ошибки в HttpErrorInterceptor
     */
    constructor(public payload: IError[], public isHandled: boolean = false) {}
  }

  /**
   * Действие для успешной оплаты
   */
  export class PaymentSuccess<T = IPaymentResponse> {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PAYMENT_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные после оплаты
     */
    constructor(public payload: T) {}
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.RESET;
  }

  /**
   *  Действие для загрузки полезных фактов
   */
  export class Facts {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.FACTS;
  }

  /**
   *  Действие для неуспешной загрузки полезных фактов
   */
  export class FactsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.FACTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешной загрузки полезных фактов
   */
  export class FactsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.FACTS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Массив полезных фактов
     */
    constructor(public payload: string[]) {}
  }

  /**
   *  Действие для загрузки pdf дополнительного соглашения пролонгации
   */
  export class ProlongationPdf {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_PDF;

    /**
     * Тело действия
     *
     * @param payload ссылка
     */
    constructor(public payload: string) {}
  }

  /**
   *  Действие для неуспешной загрузки pdf дополнительного соглашения пролонгации
   */
  export class ProlongationPdfFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_PDF_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   *  Действие для успешной загрузки pdf дополнительного соглашения пролонгации
   */
  export class ProlongationPdfSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_PDF_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные документа рассрочки/амнистии
     */
    constructor(public payload: Blob) {}
  }

  /**
   * Действие для загрузки документа пролонгации
   */
  export class ProlongationDocument {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_DOCUMENT;
    /**
     * Тело действия
     *
     * @param payload ID займа
     */
    constructor(public payload: string) {}
  }

  /**
   * Действие для успешной загрузки документа пролонгации
   */
  export class ProlongationDocumentSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_DOCUMENT_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные документа пролонгации
     */
    constructor(public payload: Blob) {}
  }

  /**
   * Действие для неуспешной загрузки документа пролонгации
   */
  export class ProlongationDocumentFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_DOCUMENT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для подписания пролонгации
   */
  export class ProlongationSign {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN;
  }

  /**
   * Действие для успешного подписания пролонгации
   */
  export class ProlongationSignSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN_SUCCESS;
  }

  /**
   * Действие для неуспешного подписания пролонгации
   */
  export class ProlongationSignFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для отправки повторного кода подписания пролонгации
   */
  export class ProlongationSignResendCode {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN_RESEND_CODE;
  }

  /**
   * Действие для успешной отправки повторного кода подписания пролонгации
   */
  export class ProlongationSignResendCodeSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN_RESEND_CODE_SUCCESS;
  }

  /**
   * Действие для неуспешной отправки повторного кода подписания пролонгации
   */
  export class ProlongationSignResendCodeFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_SIGN_RESEND_CODE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  export class ProlongationConsents {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CONSENTS;
  }

  export class ProlongationConsentsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CONSENTS_SUCCESS;

    constructor(public payload: IConsentDto) {}
  }

  export class ProlongationConsentsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.PROLONGATION_CONSENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }
  export class RefinancingConsents {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.REFINANCING_CONSENTS;

    constructor(public payload: Product){}
  }

  export class RefinancingConsentsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.REFINANCING_CONSENTS_SUCCESS;

    constructor(
      public payload: { consents: IConsentDto[]; signingConsents: IConsentDto[] },
      public device: IFingerPrint
    ) {}
  }

  export class RefinancingConsentsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.REFINANCING_CONSENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие открыть согласие
   */
  export class ViewConsent {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.VIEW_CONSENT;

    /**
     * Тело действия
     *
     * @param payload согласиее
     * @param index индекс для массива ссылок
     */
    constructor(public payload: IConsentDto, public index: number) {}
  }

  export class RefinancingPaid {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.REFINANCING_PAID;
  }

  /** Действие для сброса состояния формы к значению по умолчанию */
  export class ResetForm {
    /** Уникальное имя действия */
    public static readonly type: ProductActionList = ProductActionList.RESET_FORM;
  }

  /** Сохранение значения доп. средств */
  export class SetAdditionalLoanSum {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProductActionList = ProductActionList.SET_ADDITIONAL_LOAN_SUM;

    /**
     * Тело действия
     *
     * @param payload сумма
     */
    constructor(public payload: number) {}
  }
}
