import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(protected toast: ToastService) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err?.status) {
          case 0:
            this.toast.notification({
              title: 'Ошибка',
              text: 'Обращение к сервису заблокировано. Попробуйте обновить страницу или зайти позже',
              type: ToastType.Error,
              code: err.status,
            });

            return throwError(() => err);
          case 429:
            this.toast.notification({
              title: 'Ошибка',
              text: 'Превышено количество запросов к серверу',
              type: ToastType.Error,
              code: err.status,
            });

            return throwError(() => err);
          case 500:
          case 502:
          case 503:
          case 504:
          case 522:
          case 524:
            this.toast.notification({
              title: err.error?.errors?.[0]?.title || 'Ошибка',
              text: err.error?.errors?.[0]?.message || 'Сервис временно недоступен. Попробуйте обновить страницу или зайти позже',
              type: ToastType.Error,
              code: err.status,
            });

            if(!!err.error?.errors) {
              err.error.isHandled = true;
            }

            return throwError(() => err);
          case 525:
          case 526:
            this.toast.notification({
              title: 'Ошибка',
              text: 'Ошибка сертификата. Попробуйте обновить страницу или зайти позже',
              type: ToastType.Error,
              code: err.status,
            });

            return throwError(() => err);
        }

        return throwError(() => err);
      }),
    );
  }
}
