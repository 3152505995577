/**
 * Справочник действий состояния "Бонусы"
 */
export enum BonusActionList {
  GET_BALANCE = '[Bonus] Get balance',
  GET_BALANCE_FAIL = '[Bonus] Get balance fail',
  GET_BALANCE_SUCCESS = '[Bonus] Get balance success',

  GET_UPCOMING_EXPIRATION = '[Bonus] Get upcoming expiration',
  GET_UPCOMING_EXPIRATION_FAIL = '[Bonus] Get upcoming expiration fail',
  GET_UPCOMING_EXPIRATION_SUCCESS = '[Bonus] Get upcoming expiration success',

  GET_TRANSACTION_HISTORY_FIRST_PAGE = '[Bonus] Get transaction history first page',
  GET_TRANSACTION_HISTORY_FIRST_PAGE_FAIL = '[Bonus] Get transaction history first page fail',
  GET_TRANSACTION_HISTORY_FIRST_PAGE_SUCCESS = '[Bonus] Get transaction history first page success',

  GET_TRANSACTION_HISTORY_NEXT_PAGE = '[Bonus] Get transaction history next page',
  GET_TRANSACTION_HISTORY_NEXT_PAGE_FAIL = '[Bonus] Get transaction history next page fail',
  GET_TRANSACTION_HISTORY_NEXT_PAGE_SUCCESS = '[Bonus] Get transaction history next page success',

  RESET = '[Bonus] reset'
}
