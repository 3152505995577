import { Inject, Injectable } from '@angular/core';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';

@Injectable({
  providedIn: 'root',
})
export class PWAService {
  public isInApplication: boolean =
    this.window.navigator['standalone'] || this.window.matchMedia('(display-mode: standalone)').matches;

  constructor(@Inject('Window') private window: Window, private toast: ToastService) {}

  public init(): void {
    if (!this.isInApplication) return;

    this.toast.notification({
      title: 'Внимание',
      text: 'Приложение работает в тестовом режиме',
      type: ToastType.Warning,
      timeout: 0,
    });
  }
}
