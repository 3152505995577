import { AppealsActionList } from './appeals-action-list.const';
import { IAppeals } from '../interfaces/appeals.interface';

/**
 * Namespace для действий состояния "Обращения" пользователя
 */
export namespace AppealsActions {
  /**
   * Действие для загрузки обращений
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD;
  }

  /**
   * Действие для неуспешной загрузки обращений
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: { err; caught }) {}
  }

  /**
   * Действие для успешной загрузки обращений
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные обращений
     */
    constructor(public payload: IAppeals) {}
  }

  /**
   * Действие для отправки обращения на почту
   */
  export class SendToEmail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.SEND_TO_EMAIL;

    /**
     * Тело действия
     *
     * @param payload ID обращения
     */
    constructor(public payload: { id: string }) {}
  }

  /**
   * Действие для неуспешной отправки обращения на почту
   */
  export class SendToEmailFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.SEND_TO_EMAIL_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: { err; caught }) {}
  }

  /**
   * Действие для успешной отправки обращения на почту
   */
  export class SendToEmailSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.SEND_TO_EMAIL_SUCCESS;
  }

  /**
   * Действие для загрузки обращения для скачивания
   */
  export class LoadFile {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD_FILE;

    /**
     * Тело действия
     *
     * @param payload ID обращения
     */
    constructor(public payload: { id: string }) {}
  }

  /**
   * Действие для неуспешной загрузки обращения для скачивания
   */
  export class LoadFileFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD_FILE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: { err; caught }) {}
  }

  /**
   * Действие для успешной загрузки обращения для скачивания
   */
  export class LoadFileSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.LOAD_FILE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные обращения
     */
    constructor(public payload: Blob) {}
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AppealsActionList = AppealsActionList.RESET;
  }
}
