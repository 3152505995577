import { Inject, Injectable } from '@angular/core';
import { Metrika } from 'ng-yandex-metrika';
import { IDataLayerEvent } from './interfaces/data-layer-event.interface';
import { IWindowWithDataLater } from './interfaces/window-with-data-layer.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticService {
  private _yaMetricaId: any = '';

  constructor(
    @Inject('Window') private window: IWindowWithDataLater,
    private yaMetrica: Metrika,
    @Inject('environment') private environment
  ) {}

  public get yaMetricaId(): any {
    return this._yaMetricaId;
  }

  public push(payload: IDataLayerEvent) {
    if (this.window.dataLayer) {
      if (this.environment.name !== 'production') {
        console.group('DataLayer');
        console.log('Content', this.window.dataLayer);
        console.log('New Event', payload);
        console.groupEnd();
      }
      this.window.dataLayer.push(payload);
    }
  }

  public async init() {
    try {
      this._yaMetricaId = await this.yaMetrica?.getClientID(this.environment.yandexMetrikaId);
    } catch (e) {
      this._yaMetricaId = '';
      console.error('catch yaMetricaId' + e);
    }
  }
}
