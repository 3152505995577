/**
 * Справочник действий состояния
 */
export enum CalculatorActionList {
  LOAD = '[Calculator] loaded',
  LOAD_FAIL = '[Calculator] load failed',
  LOAD_SUCCESS = '[Calculator] load success',

  SCHEDULE = '[Calculator] schedule',
  SCHEDULE_FAIL = '[Calculator] schedule fail',
  SCHEDULE_SUCCESS = '[Calculator] schedule success',

  LOAD_PDL = '[Calculator] load PDL',
  LOAD_PDL_FAIL = '[Calculator] load PDL failed',
  LOAD_PDL_SUCCESS = '[Calculator] load PDL success',

  RESET = '[Calculator] reset',
}
