import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GaEvent } from '@app/states/events/constants/ga-event.const';
import { GOOGLE_ANALYTICS_EVENTS_MAP } from '@app/states/events/constants/ga-event-map.const';
import { MindboxOperation } from '@app/states/events/constants/mindbox-operation.const';
import { Observable } from 'rxjs';
import { IMindboxResponse } from '@app/states/events/interfaces/mindbox/mindbox-response.interface';
import { IJsonRpcQuery } from '@app/interfaces/jsonrpc-query.interface';
import { CookieService } from '@app/services/cookie/cookie.service';
import { IEvent } from '@app/states/events/interfaces/event.interface';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private minboxPath = `${this.environment.gate}rpc`;
  private endpointId = this.environment.endpointId; // TODO: Implement production case

  constructor(
    private ga: GoogleAnalyticsService,
    private http: HttpClient,
    private cookie: CookieService,
    @Inject('environment') private environment,
  ) {
  }

  public gaEvent(event: GaEvent): void {
    const payload = GOOGLE_ANALYTICS_EVENTS_MAP.get(event);

    this.ga.event(payload.action, payload.category, payload.label);
  }


  public createEvent(data: IEvent, isNotCancelled = false) {
    let headers = new HttpHeaders();
    if (isNotCancelled) {
      headers = headers.append('isNotCancelled', 'isNotCancelled');
    }
    return this.http.post(`${this.environment.gate}rpc`, data, { headers });
  }

  public mindboxOperation(operation: MindboxOperation, body: unknown): Observable<IMindboxResponse> {
    const deviceUUID = this.cookie.get('mindboxDeviceUUID');

    const request: IJsonRpcQuery = {
      id: '1',
      jsonrpc: '2.0',
      method: 'mindbox_wb',
      params: {
        query_params: {
          endpointId: [this.endpointId],
          operation: [operation],
          ...(deviceUUID ? { deviceUUID: [deviceUUID] } : {}),
        },
        body,
        path: 'sync',
      },
    };

    return this.http.post<IMindboxResponse>(this.minboxPath, request);
  }
}
