export enum MetricType {
  CustomEvent = 'custom_event',
  Counter = 'metric_counter',
  Event = 'event',
  CreateLoan = 'create_loan',
  CreateUser = 'create_user',
  UserVerify = 'user_verify',
  CardBinding = 'card_binding',
  RequestLoanPhoto = 'request_loan_photo',
}
