import { ILSContractState } from "../constants/ils-contract-state.const";
import { ProductStatus } from "../constants/product-status.const";
import { IProduct } from "../interfaces/product.interface";

export function ilsContractStatusMap(state: ILSContractState): IProduct['status'] {
  switch (state) {
    case ILSContractState.Pending:
      return ProductStatus.Signing;
    case ILSContractState.Signed:
      return ProductStatus.Signed;
    case ILSContractState.Actual:
      return ProductStatus.Issued;
  }
}