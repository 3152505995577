import { RequestStatus } from '@app/constants/request-status.const';
import { IStateMigration } from '@app/interfaces/state-migration.interface';

/**
 * Класс миграций состояния "Регистрация"
 */
export class RegistrationStateMigrations {
  /**
   * Метод получения перечня миграций хранилища
   */
  public static migrate(): IStateMigration[] {
    return [
      {
        version: undefined,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          ...state,
          version: 1,
          riskAnalysis: {
            keyboardTypingSpeed: 0,
            leftPageCount: 0,
            numberOfCorrections: 0,
            numberSingleClicks: 0,
            pageFillingDuration: 0,

            startAt: 0,
            keydownCounter: 0,
          },
        }),
      },
      {
        version: 1,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          ...state,
          version: 2,
          userHash: '',
        }),
      },
      {
        version: 2,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 3,
          token: null,
          tokenExpired: null,
          userId: null,
          referrer: null,
          form: null,
          user: null,
          currentStep: 1,
          registrationStartTimestamp: null,
          esiaUsed: false,
          esiaKey: null,
        }),
      },
      {
        version: 3,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 4,
          token: null,
          tokenExpired: null,
          userId: null,
          referrer: null,
          form: null,
          user: null,
          currentStep: '1',
          registrationStartTimestamp: null,
          esiaUsed: false,
          esiaKey: null,
        }),
      },
      {
        version: 4,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 5,
          token: null,
          tokenExpired: null,
          userId: null,
          form: null,
          user: null,
          currentStep: '1',
          registrationStartTimestamp: null,
          esiaUsed: false,
          esiaKey: null,
          loanRequestStatus: RequestStatus.NotInvoked,
        }),
      },
      {
        version: 5,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 6,
          token: null,
          tokenExpired: null,
          userId: null,
          user: null,
          currentStep: '1',
          registrationStartTimestamp: null,
          esiaUsed: false,
          esiaKey: null,
          loanRequestStatus: RequestStatus.NotInvoked,
        }),
      },
      {
        version: 6,
        key: 'registration',
        versionKey: 'version',
        migrate: (state: any) => ({
          version: 7,
          user: null,
          currentStep: '1',
          registrationStartTimestamp: null,
          esiaUsed: false,
          esiaKey: null,
          loanRequestStatus: RequestStatus.NotInvoked,
        }),
      },
    ];
  }
}
