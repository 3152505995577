import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { APPEALS_STATE_DEFAULTS } from './appeals-state-defaults.const';
import { AppealsService } from '../services/appeals.service';
import { AppealsActions } from './appeals.actions';
import { IAppealsResponse } from '../interfaces/appeals-response.interface';
import { IAppealsState } from './appeals.state.interface';
import { RequestStatus } from '../../../constants/request-status.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';


/**
 * Класс NGXS состояния "Обращения" пользователя
 */
@State<IAppealsState>({
  name: 'appeals',
  defaults: APPEALS_STATE_DEFAULTS,
})
@Injectable()
export class AppealsState extends StateErrorHandler {
  /**
   * Конструктор класса состояния "Обращения"
   *
   * @param service [Сервис]{@link AppealsService} обращений пользователя
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   */
  constructor(
    private service: AppealsService,
    protected toast: ToastService,
    protected store: Store,
  ) {
    super(toast, store);
  }

  /**
   * Действие для загрузки обращений
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Запрос к бэкенду {@link AppealsService#getAppeals}
   * - В случае успешного ответа - передаем [данные]{@link AppealsActions#LoadSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AppealsActions#LoadFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.Load)
  public load(ctx: StateContext<IAppealsState>, action: AppealsActions.Load) {
    ctx.patchState({
      status: RequestStatus.Pending,
    });

    return this.service.getAppeals().pipe(
      tap((response: IAppealsResponse) => {
        if (response.data) {
          ctx.dispatch(new AppealsActions.LoadSuccess(response.data));
        }
      }),
      catchError((err, caught) => {
        this.catchSentryError('AppealsActions.LoadFail', err);
        return ctx.dispatch(
          new AppealsActions.LoadFail({ err, caught })
        )
      }),
    );
  }

  /**
   * Действие для успешной загрузки обращений
   *
   * Порядок выполнения:
   * - Обновить в состоянии данные об обращениях, изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<IAppealsState>, action: AppealsActions.LoadSuccess) {
    ctx.patchState({
      appealsList: action.payload.data,
      status: RequestStatus.Load,
    });
  }

  /**
   * Действие для неуспешной загрузки обращений
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.LoadFail)
  public loadFail(ctx: StateContext<IAppealsState>, action: AppealsActions.LoadFail) {
    ctx.patchState({
      status: RequestStatus.Error,
    });
  }

  /**
   * Действие для отправки обращения на почту
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Запрос к бэкенду {@link AppealsService#sendToEmail}
   * - В случае успешного ответа - [обрабатываем]{@link AppealsActions#SendToEmailSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link AppealsActions#SendToEmailFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.SendToEmail)
  public sendToEmail(ctx: StateContext<IAppealsState>, action: AppealsActions.SendToEmail) {
    ctx.patchState({
      status: RequestStatus.Pending,
    });
    return this.service.sendToEmail(action.payload.id).pipe(
      tap(() => ctx.dispatch(new AppealsActions.SendToEmailSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('AppealsActions.SendToEmailFail', err);
        return ctx.dispatch(
          new AppealsActions.SendToEmailFail({ err, caught })
        )
      }),
    );
  }

  /**
   * Действие для успешной отправки обращения на почту
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешной отправки обращения на почту
   * - Изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.SendToEmailSuccess)
  public sendToEmailSuccess(ctx: StateContext<IAppealsState>, action: AppealsActions.SendToEmailSuccess) {
    this.toast.notification({
      title: 'Заявление отправлено',
      text: 'Заявление отправлено на вашу электронную почту',
      type: ToastType.Success,
    });
    ctx.patchState({
      status: RequestStatus.Load,
    });
  }

  /**
   * Действие для неуспешной отправки обращения на почту
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Вывод информационного сообщения об ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.SendToEmailFail)
  public sendToEmailFail(ctx: StateContext<IAppealsState>, action: AppealsActions.SendToEmailFail) {
    ctx.patchState({
      status: RequestStatus.Error,
    });
    action.payload.err.error?.errors?.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   * Действие для загрузки обращения для скачивания
   *
   * Порядок выполнения:
   * - Изменить статус запроса данных
   * - Запрос к бэкенду {@link AppealsService#loadFile}
   * - В случае успешного ответа - передаем [данные]{@link AppealsActions#LoadFileSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link AppealsActions#LoadFileSuccess} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.LoadFile)
  public loadFile(ctx: StateContext<IAppealsState>, action: AppealsActions.LoadFile) {
    return this.service.getFile(action.payload.id).pipe(
      tap((file) => ctx.dispatch(new AppealsActions.LoadFileSuccess(file))),
      catchError((err, caught) => {
        this.catchSentryError('AppealsActions.LoadFileSuccess', err);
        return ctx.dispatch(
          new AppealsActions.LoadFileSuccess(err.error.text)
        )
      }),
    );
  }

  /**
   * Действие для успешной загрузки обращения для скачивания
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.LoadFileSuccess)
  public loadFileSuccess(ctx: StateContext<IAppealsState>, action: AppealsActions.LoadFileSuccess) {
  }

  /**
   * Действие для неуспешной загрузки обращения для скачивания
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(AppealsActions.LoadFileFail)
  public loadFileFail(ctx: StateContext<IAppealsState>, action: AppealsActions.LoadFileFail) {
    action.payload.err.error?.errors?.forEach((err) => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   */
  @Action(AppealsActions.Reset)
  public reset(ctx: StateContext<IAppealsState>) {
    ctx.setState(APPEALS_STATE_DEFAULTS);
  }
}
