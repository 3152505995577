export enum ConsentPackage {
  Identification = 'identification',
  Scoring = 'scoring',
  SubmitApplication = 'submitApplication',
  SigningContract = 'signingContract',
  ILSLoanApplication = 'loanApplication',
  SigningProlongation = 'signingProlongation',
  Refinancing = 'refinancing',
  SigningRefinancing = 'signingRefinancing',
  AdditionalLoan = 'additionalLoan',
  SigningAdditionalLoan = 'signingAdditionalLoan',
}
