import { IError } from '@app/interfaces/error.interface';
import { SettingsActionList } from './settings-action-list.const';
import { IDefaultProfileSettings } from '@app/states/settings/interfaces/default-settings.interface';


export namespace SettingsActions {
  /** Действие для получения дефолтных настроек профиля */
  export class ProfileSettings {
    /** Уникальное имя действия */
    public static readonly type = SettingsActionList.PROFILE_SETTINGS;
  }

  /** Действие для неуспешного получения дефолтных настроек профиля */
  export class ProfileSettingsFail {
    /** Уникальное имя действия */
    public static readonly type = SettingsActionList.PROFILE_SETTINGS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /** Действие для успешного получения дефолтных настроек профиля */
  export class ProfileSettingsSuccess {
    /** Уникальное имя действия */
    public static readonly type = SettingsActionList.PROFILE_SETTINGS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload данные дефолтных настроек
     */
    constructor(public payload: IDefaultProfileSettings) {
    }
  }

  /** Действие для сброса состояния к значению по умолчанию */
  export class Reset {
    /** Уникальное имя действия */
    public static readonly type: SettingsActionList = SettingsActionList.RESET;
  }
}
