import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs';
import { SETTINGS_STATE_DEFAULTS } from './settings-state-defaults.const';
import { ISettingsState } from './settings.state.interface';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { SettingsService } from '../services/settings.service';
import { SettingsActions } from './settings.actions';
import { IDefaultProfileSettings } from '../interfaces/default-settings.interface';


@State<ISettingsState>({
  name: 'Settings',
  defaults: SETTINGS_STATE_DEFAULTS,
})
@Injectable()
export class SettingsState extends StateErrorHandler implements NgxsOnInit {

  constructor(
    private service: SettingsService,
    protected toast: ToastService,
    protected store: Store,
  ) {
    super(toast, store);
  }

  public ngxsOnInit(ctx: StateContext<ISettingsState>): void {
    ctx.dispatch(new SettingsActions.ProfileSettings());
  }

  @Selector()
  public static defaultProfileSettings(state: ISettingsState): IDefaultProfileSettings {
    return state.defaultProfileSettings;
  }

  @Action(SettingsActions.ProfileSettings)
  public settings(ctx: StateContext<ISettingsState>,
                     action: SettingsActions.ProfileSettings) {
    return this.service.profileSettings().pipe(
      tap((response) => {
        ctx.dispatch(new SettingsActions.ProfileSettingsSuccess(response.data.fields));
      }),
      catchError((err, caught) => {
        this.catchSentryError('SettingsActions.ProfileSettingsFail', err);
        return ctx.dispatch(
          new SettingsActions.ProfileSettingsFail(err.error?.errors),
        );
      }),
    );
  }

  @Action(SettingsActions.ProfileSettingsSuccess)
  public settingsSuccess(ctx: StateContext<ISettingsState>,
                     action: SettingsActions.ProfileSettingsSuccess) {
    ctx.setState(
      patch({
        defaultProfileSettings: action.payload,
      }),
    );
  }

  @Action(SettingsActions.ProfileSettingsFail)
  public settingsFail(ctx: StateContext<ISettingsState>,
                     action: SettingsActions.ProfileSettingsFail) {
    ctx.setState(
      patch({
        defaultProfileSettings: null,
      }),
    );
  }

  @Action(SettingsActions.Reset)
  public reset(ctx: StateContext<ISettingsState>) {
    ctx.setState(SETTINGS_STATE_DEFAULTS);
  }
}
