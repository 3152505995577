import dayjs from 'dayjs';
import { IVirtualCardOperation } from '../interfaces/virtual-card-operation.interface';

export function operationsMap(operations: IVirtualCardOperation[]): {[key: string]: IVirtualCardOperation[]} {
  if (!operations || !operations?.length) return;

  const newOperations = {};

  operations.map((operation) => {
    const day = dayjs(operation.attributes.timestamp).format('YYYY-MM-DD');

    if (newOperations[day]) {
        newOperations[day].push(operation);
      } else {
        newOperations[day] = [operation];
      }
  });
  return newOperations;
}
