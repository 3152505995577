import { IProductDTO } from '@app/states/product/interfaces/product-dto.interface';
import { IPDLDTO } from '@app/states/product/interfaces/pdl-dto.interface';
import { IILSApplicationDTO } from '@app/states/product/interfaces/ils-application-dto.interface';
import { IILSContractDTO } from '@app/states/product/interfaces/ils-contract-dto.interface';
import { ProductType } from '@app/states/product/constants/product-type.const';

export function isPDLProduct(type: IProductDTO['type'], attributes: IProductDTO['attributes']): attributes is IPDLDTO {
  return type === ProductType.PDL;
}

// eslint-disable-next-line max-len
export function isILSApplicationProduct(type: IProductDTO['type'], attributes: IProductDTO['attributes']): attributes is IILSApplicationDTO {
  return type === ProductType.ILSApplication;
}

export function isILSContractProduct(type: IProductDTO['type'], attributes: IProductDTO['attributes']): attributes is IILSContractDTO {
  return type === ProductType.ILSContract;
}
