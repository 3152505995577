import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { catchError, map, tap } from 'rxjs/operators';
import { ICreateFeedbackResponse } from '../interfaces/create-feedback-response';
import { FeedbackService } from '../services/feedback.service';
import { FeedbackActions } from './feedback.actions';
import { IFeedbackState } from '@app/states/feedback/states/feedback.state.interface';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';

/**
 * Класс NGXS состояния "Обращения" пользователя
 */
@State<IFeedbackState>({
  name: 'Feedback',
})
@Injectable()
export class FeedbackState extends StateErrorHandler {
  /**
   * Конструктор класса состояния займа
   *
   * @param service [Сервис]{@link FeedbackService} обращений пользователя
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   */
  constructor(
    private service: FeedbackService,
    protected toast: ToastService,
    protected store: Store,
  ) {
    super(toast, store);
  }

  /**
   * Действие для загрузки типов обращения
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link FeedbackService#reasons}
   * - В случае успешного ответа - передаем [данные]{@link FeedbackActions#ReasonsSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link FeedbackActions#ReasonsFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.Reasons)
  public reasons(ctx: StateContext<IFeedbackState>) {
    return this.service.reasons().pipe(
      tap((response) =>
        ctx.dispatch(new FeedbackActions.ReasonsSuccess(response.data.map((item) => ({
          value: item.type,
          label: item.description,
        })))),
      ),
      catchError((err, caught) => {
        this.catchSentryError('FeedbackActions.ReasonsFail', err);
        return ctx.dispatch(
          new FeedbackActions.ReasonsFail({ err, caught }),
        );
      }),
    );
  }

  /**
   * Действие для неуспешной загрузки типов обращения
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.ReasonsFail)
  public reasonsFail(ctx: StateContext<IFeedbackState>,
                     action: FeedbackActions.ReasonsFail) {
    action.payload.err.error?.errors?.forEach(err => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }

  /**
   * Действие для успешной загрузки типов обращения
   *
   * Порядок выполнения:
   * - Обновить данные типов обращения в хранилище
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.ReasonsSuccess)
  public reasonsSuccess(ctx: StateContext<IFeedbackState>,
                        action: FeedbackActions.ReasonsSuccess) {
    ctx.patchState({
      reasons: action.payload,
    });
  }

  /**
   * Действие для создания нового обращения пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link FeedbackService#createRequest}
   * - В случае успешного ответа - передаем [данные]{@link FeedbackActions#CreateSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link FeedbackActions#CreateFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.Create)
  public create(ctx: StateContext<IFeedbackState>, action: FeedbackActions.Create) {
    const form = ctx.getState().form.model;

    return this.service.createRequest({
      ...form,
      dialogueDate: form.dialogueDate ? form.dialogueDate + ' 00:00:00' : null,
    }).pipe(
      map((data: ICreateFeedbackResponse) => {
          ctx.dispatch(new FeedbackActions.CreateSuccess(data));
        },
      ),
      catchError((err, caught) => {
        this.catchSentryError('FeedbackActions.CreateFail', err);
        return ctx.dispatch(
          new FeedbackActions.CreateFail({ err, caught }),
        );
      }),
    );
  }

  /**
   * Действие для успешного создания нового обращения пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешном создании обращения
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.CreateSuccess)
  public createSuccess() {
    this.toast.notification({
      title: 'Обращение отправлено',
      text: 'Скоро мы вернемся с решением',
      type: ToastType.Success,
    });
  }

  /**
   * Действие для неуспешного создания нового обращения пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения о каждой ошибке
   *
   * @param ctx
   * @param action
   */
  @Action(FeedbackActions.CreateFail)
  public createFail(ctx: StateContext<IFeedbackState>, action: FeedbackActions.CreateFail) {
    action.payload.err.error?.errors?.forEach(err => {
      this.toast.notification({
        title: 'Ошибка',
        text: err.message,
        type: ToastType.Error,
      });
    });
  }
}
