import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '../../../environments/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class JuicyscoreService {
  private scriptRef: HTMLElement;

  constructor(@Inject('environment') private environment: IEnvironment) {}

  public add(): void {
    if (typeof juicyScoreApi === 'undefined') {
      this.destroy();

      const juicyscore = document.createElement('script');
      juicyscore.type = 'text/javascript';
      juicyscore.async = true;
      juicyscore.src = this.environment.juicyScoreUrl + '/static/js.js';
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(juicyscore);

      this.scriptRef = juicyscore;
    }
  }

  public destroy(): void {
    if (!this.scriptRef) return;

    const head = document.getElementsByTagName('head')[0];

    try {
      head.removeChild(this.scriptRef);
    } catch (e) {
    }
  }

  public getSession(): string {
    try {
      return juicyScoreApi.getSession();
    } catch (e) {
      return '';
    }
  }

  public manuallyNextButton(): void {
    this.emulateClick('manuallyNextButton');
  }

  public manuallyStopPing(): void {
    this.emulateClick('manuallyStopPing');
  }

  public manuallyComplete(): void {
    this.emulateClick('manuallyComplete');
  }

  private emulateClick(button: 'manuallyNextButton' | 'manuallyStopPing' | 'manuallyComplete'): void {
    if (typeof juicyScoreApi !== 'undefined') {
      juicyScoreApi[button].dispatchEvent(new Event('click'));
    }
  }
}
