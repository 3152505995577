import { RequestStatus } from 'projects/web-bankir-app/src/app/constants/request-status.const';
import { ICalculatorState } from './calculator.state.interface';

/**
 * Дефолтное состояние хранилища при его инициализации
 */
export const CALCULATOR_STATE_DEFAULTS: ICalculatorState = {
  version: 2,
  pdl: {
    data: {
      status: { available: true, availableFrom: null },
      stairs: true,
      percent: 1,
      percent_min: 1,
      percent_max: 1,
      period_min: 7,
      period_max: 31,
      period_percents: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      sum_min: 3000,
      sum_max: 30000,
      sum_max_website: 30000,
      sum_step: 500,
      pdl_rate_minimal_year: 365,
      pdl_rate_max_year: 365,
      pdl_rate_minimal_day: 1,
      pdl_rate_personal: 365,
      pdl_till_minimal: 0,
      period_default: 10,
      period_default_website: 10,
      sum_default: 15000,
      sum_default_website: 15000,
      promo_id: 0,
      promo: null,
    },
  },
  ils: {
    data: null,
    schedule: null,
  },
  status: RequestStatus.NotInvoked,
};
