import { ICreateFeedbackResponse } from '../interfaces/create-feedback-response';
import { FeedbackActionList } from './feedback-action-list.const';
import { ISelectItem } from '@web-bankir/ui-kit/components/controls/select';

/**
 * Namespace для действий состояния "Обращения" пользователя
 */
export namespace FeedbackActions {
  /**
   * Действие для загрузки типов обращения
   */
  export class Reasons {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.REASONS;
  }

  /**
   * Действие для неуспешной загрузки типов обращения
   */
  export class ReasonsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.REASONS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Ошибка
     */
    constructor(public payload: { err; caught }) {
    }
  }

  /**
   * Действие для успешной загрузки типов обращения
   */
  export class ReasonsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.REASONS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload данные типов обращения
     */
    constructor(public payload: ISelectItem[]) {
    }
  }

  /**
   * Действие для создания нового обращения пользователя
   */
  export class Create {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.CREATE;
  }

  /**
   * Действие для неуспешного создания нового обращения пользователя
   */
  export class CreateFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.CREATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Ошибка
     */
    constructor(public payload: any) {
    }
  }

  /**
   * Действие для успешного создания нового обращения пользователя
   */
  export class CreateSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: FeedbackActionList = FeedbackActionList.CREATE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные ответа на создание нового обращения
     */
    constructor(public payload: ICreateFeedbackResponse) {
    }
  }

}
