import { ProfileFormsPossibleDisabledFields } from "./profile-forms-fields.conts";

// TODO: попросить бэк присылать корректные поля?
export const EditableFieldsExtention = {
  job: ProfileFormsPossibleDisabledFields.work,
  numberOfChildren: ProfileFormsPossibleDisabledFields.family,
  education: ProfileFormsPossibleDisabledFields.education,
  publicity: ProfileFormsPossibleDisabledFields.publicity,
  beneficialOwner: ProfileFormsPossibleDisabledFields.beneficial,
  beneficial: ProfileFormsPossibleDisabledFields.beneficial
}