import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/states/auth/states/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthByCodeGuard  {
  constructor(private store: Store) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authByTokenQuery = (queryParams: Params) => {
      const { token, exp, user_id, webbankir_cross_id } = queryParams;
      return token && exp && user_id ? { token, expire: exp, userId: user_id, webbankirCrossId: webbankir_cross_id } : null;
    };
    const authByToken = authByTokenQuery(route.queryParams);
    if (authByToken) {
      this.store.dispatch(new AuthActions.AuthByToken(authByToken));
      return false;
    } else {
      return true;
    }
  }
}
