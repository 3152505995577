export enum LoanInstallmentStatus {
  /**
   * Подписание
   */
  Signing = 1,
  /**
   * Оплата первоначального взноса
   */
  Payment,
  /**
   * Активна
   */
  Active,
  /**
   * ??
   */
  Prolongation = 4,
  /**
   * ??
   */
  Overdue,
}
