import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { IEnvironment } from '../../environments/environment.interface';
import { AuthActions } from '@app/states/auth/states/auth.actions';
import { takeUntil } from 'rxjs/operators';
import { HttpHandlerService } from '@app/services/http-handler/http-handler.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    @Inject('environment') private environment: IEnvironment,
    private httpHandler: HttpHandlerService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.has('isNotCancelled')) {
      return next.handle(request);
    }

    if (request.url.includes(this.environment.gate)) {
      return next.handle(request).pipe(
        takeUntil(this.httpHandler.onCancelPendingRequests()),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        }),
      );
    }

    const { token, userId } = this.store.selectSnapshot((state) => {
      if (new Date(state.Auth?.tokenExpired * 1000) > new Date()) {
        return { token: state.Auth.token, userId: state.Auth.userId };
      }

      return {};
    });

    if (!!token && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    if (userId) {
      request = request.clone({
        setHeaders: {
          UserId: userId.toString(),
        },
      });
    }


    return next.handle(request).pipe(
      takeUntil(this.httpHandler.onCancelPendingRequests()),
      catchError((error: HttpErrorResponse) => {

        if (error.status === 401) {
          this.store.dispatch(new AuthActions.Logout());
        }
        return throwError(() => error);
      }),
    );
  }
}
