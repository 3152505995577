import { ILoginPayload } from '../interfaces/login-payload.interface';
import { IAutoLoginResponse, ILoginResponse } from '../../../interfaces/login-response.interface';
import { IPasswordRecoveryCheckPayload } from '../interfaces/password-recovery-check-payload.interface';
import { IPasswordRecoveryPayload } from '../interfaces/password-recovery-payload.interface';
import { IPasswordRecoverySuccessResponse } from '../interfaces/password-recovery-success-response.interface';
import { IPasswordRecoveryConfirmResponse } from '../interfaces/password-recovery-confirm-response.interface';
import { AuthActionList } from './auth-action-list.const';
import { IResetPasswordPayload } from '../interfaces/reset-password-payload.interface';
import { IResetPasswordResponse } from '../interfaces/reset-password-response.interface';
import { IPasswordRecoveryMethod } from '../interfaces/password-recovery-method.interface';
import { IError } from '@app/interfaces/error.interface';
import {
  IRegistrationApplicationDto,
} from '@app/states/registration/interfaces/registration-application-dto.interface';

/**
 * Namespace для действий состояния "Авторизация"
 */
export namespace AuthActions {
  /**
   * Действие для авторизации пользователя
   */
  export class Login {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.LOGIN;

    /**
     * Тело действия
     *
     * @param payload Данные {@link ILoginPayload} для авторизации пользователя
     */
    constructor(public payload: ILoginPayload) {
    }
  }

  /**
   * Действие для успешной авторизации пользователя
   */
  export class LoginSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.LOGIN_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные авторизации пользователя
     */
    constructor(public payload: ILoginResponse | IAutoLoginResponse) {
    }
  }

  /**
   * Действие для неуспешной авторизации пользователя
   */
  export class LoginFailed {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.LOGIN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для подтверждения электронной почты пользователя
   */
  export class EmailConfirm {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.EMAIL_CONFIRM;

    /**
     * Тело действия
     *
     * @param payload Данные электронной почты
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешного подтверждения электронной почты пользователя
   */
  export class EmailConfirmFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.EMAIL_CONFIRM_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного подтверждения электронной почты пользователя
   */
  export class EmailConfirmSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.EMAIL_CONFIRM_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные подтверждения электронной почты пользователя
     */
    constructor(public payload: any) {
    }
  }

  /**
   * Действие для авторизации пользователя по токену
   */
  export class AuthByToken {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.AUTH_BY_TOKEN;

    /**
     * Тело действия
     *
     * @param payload Данные токена, даты истечения срока действия токена, ID пользователя
     */
    constructor(public payload: { token: string; expire: string; userId: string; webbankirCrossId: string }) {
    }
  }

  /**
   * Действие для авторизации пользователя через госуслуги
   */
  export class Esia {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.ESIA;

    /**
     * Тело действия
     *
     * @param payload Ключ авторизации
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешной авторизации пользователя через госуслуги
   */
  export class EsiaFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.ESIA_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной авторизации пользователя через госуслуги
   */
  export class EsiaSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.ESIA_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные авторизации пользователя
     * @param key Ключ авторизации
     */
    constructor(public payload: ILoginResponse | IRegistrationApplicationDto, public key: string) { // TODO interface
    }
  }

  /**
   * Действие для деавторизация пользователя
   */
  export class Logout {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.LOGOUT;
  }

  /**
   * Действие для проверки возможности восстановления пароля пользователя
   */
  export class RecoveryCheck {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_CHECK;

    /**
     * Тело действия
     *
     * @param payload Данные для проверки возможности восстановления пароля пользователя
     */
    constructor(public payload: IPasswordRecoveryCheckPayload) {
    }
  }

  /**
   * Действие для успешной проверки возможности восстановления пароля пользователя
   */
  export class RecoveryCheckSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_CHECK_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные способов восстановления пароля пользователя
     */
    constructor(public payload: IPasswordRecoveryMethod[]) {
    }
  }

  /**
   * Действие для неуспешной проверки возможности восстановления пароля пользователя
   */
  export class RecoveryCheckFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_CHECK_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для восстановления пароля пользователя
   */
  export class PasswordRecovery {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY;

    /**
     * Тело действия
     *
     * @param payload Данные для восстановление пароля пользователя
     */
    constructor(public payload: IPasswordRecoveryPayload) {
    }
  }

  /**
   * Действие для успешного восстановления пароля пользователя
   */
  export class PasswordRecoverySuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные восстановление пароля пользователя
     */
    constructor(public payload: IPasswordRecoverySuccessResponse) {
    }
  }

  /**
   * Действие для неуспешного восстановления пароля пользователя
   */
  export class PasswordRecoveryFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для восстановления пароля пользователя через смс-код
   */
  export class PasswordRecoveryViaSmsConfirmation {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RECOVERY_VIA_SMS_CONFIRMATION;

    /**
     * Тело действия
     *
     * @param payload Данные восстановление пароля пользователя
     */
    constructor(public payload: IPasswordRecoveryConfirmResponse) {
    }
  }

  /**
   * Действие для изменения пароля пользователя
   */
  export class ResetPassword {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RESET_PASSWORD;

    /**
     * Тело действия
     *
     * @param payload Данные нового пароля пользователя
     */
    constructor(public payload: IResetPasswordPayload) {
    }
  }

  /**
   * Действие для неуспешного изменения пароля пользователя
   */
  export class ResetPasswordFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RESET_PASSWORD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного изменения пароля пользователя
   */
  export class ResetPasswordSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.RESET_PASSWORD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные изменения пароля пользователя
     */
    constructor(public payload: IResetPasswordResponse) {
    }
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    public static readonly type: AuthActionList = AuthActionList.RESET;
  }

  /**
   * Действие для автологина пользователя
   */
  export class AutoLogin {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AuthActionList = AuthActionList.AUTOLOGIN;

    /**
     * Тело действия
     *
     * @param hash Данные автологина
     * @param isEmailConfirmed Данные подтверждена ли электронная почта пользователя
     */
    constructor(public readonly hash: string, public readonly isEmailConfirmed = false) {
    }
  }

  /**
   * Действие для сброса состояния автологина
   */
  export class ResetAutoLogin {
    public static readonly type: AuthActionList = AuthActionList.RESET_AUTOLOGIN;

    constructor() {
    }
  }
}
