import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, iif, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterScrollService {
  private topPosition: [number, number] = [0, 0];
  private routeScrollPositions = new Map<string,[number, number]>;
  private excludingUrls = ['modal'];

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {}

  public initScroll(): void {
    this.router.events.pipe(
      filter((e) => e instanceof Scroll || e instanceof  NavigationStart),
      switchMap( (e: Scroll | NavigationStart) => iif(() => e instanceof Scroll,
          this.scrollEventHandle(e),
          this.navigationHandle(e),
        ),
      )).subscribe();
  }

  private scrollEventHandle(e): Observable<Scroll> {
    return of(e).pipe(
      tap((event: Scroll) => {
        this.setScrollPosition((event.routerEvent as NavigationEnd)?.urlAfterRedirects);
      }),
    );
  }

  private navigationHandle(e): Observable<NavigationStart> {
    return of(e).pipe(
      tap((event: NavigationStart) => {
        this.updateScrollPositions(this.router.url, event.url);
      }),
    );
  }

  private updateScrollPositions(currentUrl: string, newUrl: string): void {
    if(this.isExcludingUrl(newUrl) && !this.isExcludingUrl(currentUrl)) {
      this.routeScrollPositions.set(currentUrl, this.viewportScroller.getScrollPosition())
    }
  }

  private setScrollPosition(url): void {
    if (this.isExcludingUrl(url)) {
      return;
    }

    if (this.routeScrollPositions.has(url)) {
      this.viewportScroller.scrollToPosition(this.routeScrollPositions.get(url));
      this.routeScrollPositions.delete(url)
      return;
    }
    this.viewportScroller.scrollToPosition(this.topPosition);
  }

  private isExcludingUrl(url: string): boolean {
    return !!this.excludingUrls.filter(execulingUrl => url.includes(execulingUrl)).length
  }
}
