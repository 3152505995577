import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProfile } from '../interfaces/profile.interface';
import { IChangePassword } from '../interfaces/change-password.interface';
import { ISocialNetworks } from '../interfaces/social-networks.interface';
import { IEmail } from '../interfaces/email.interface';
import { IChangeResponse } from '../interfaces/change-response.interface';
import { ICheckNewPhone } from '../interfaces/check-new-phone.interface';
import { IChangePhone } from '../interfaces/change-phone.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IClientDto } from '@app/states/profile/interfaces/client-dto.interface';
import { IFileUploadDto } from '@app/states/product/interfaces/file-upload-dto.interface';
import { IProfileEditableFields } from '../interfaces/profile-editable-fields.interface';
import { IAddressSuggestionRequestDto, IFioSuggestionRequestDto } from '@app/states/registration/interfaces/suggestion-request-dto.interface';
import { IJsonRpcResponse } from '@app/interfaces/jsonrpc-response.interface';
import { IAddressSuggestionResponseDto, IFioSuggestionResponseDto } from '@app/states/registration/interfaces/suggestion-response-dto.interface';
import { IJsonRpcRequest } from '@app/interfaces/jsonrpc-request.interface';
import { v4 as uuid } from 'uuid';
import { IReasons } from '../interfaces/reasons.interface';
import { IAttributeResponse } from '@app/interfaces/attribute-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly path: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.path = `${this.environment.api}`;
  }

  public getProfile(withModals: boolean): Observable<IDataResponse<IProfile>> {
    return this.http.get<IDataResponse<IProfile>>(`${this.path}user/get${withModals ? '?withModals=true' : ''}`);
  }

  public getClientInfo(id: string): Observable<IDataResponse<IClientDto>> {
    return this.http.get<IDataResponse<IClientDto>>(`${this.path}user/v2/clients/${id}`);
  }

  public verify(id: string, data: Partial<IFileUploadDto>): Observable<HttpResponse<void>> {
    const form = new FormData();

    for (const key in data) {
      form.append(key, data[key]);
    }

    return this.http.post<void>(`${this.path}user/v2/clients/${id}/verify`, form, { observe: 'response' });
  }

  public getSocialNetworks(): Observable<IDataResponse<ISocialNetworks>> {
    return this.http.get<IDataResponse<ISocialNetworks>>(`${this.path}social-network/user`);
  }


  public suggestion(type: string, val: string): Observable<IJsonRpcResponse<
    IAddressSuggestionResponseDto[] | IFioSuggestionResponseDto[]
    >> {
    switch (type) {
      case 'address':
        return this.address(val);
      case 'fio':
        return this.fio(val);
    }
  }

  private address(term: string): Observable<IJsonRpcResponse<IAddressSuggestionResponseDto[]>> {
    const requestParams: IAddressSuggestionRequestDto = {
      count: 5,
      address: term,
    };

    const request = this.createRpcRequest('suggest_address', requestParams);

    return this.http.post<IJsonRpcResponse<IAddressSuggestionResponseDto[]>>(this.environment.rpcApi, request);
  }

  private fio(term: string): Observable<IJsonRpcResponse<IFioSuggestionResponseDto[]>> {
    const requestParams: IFioSuggestionRequestDto = {
      count: 5,
      fio: term,
    };

    const request = this.createRpcRequest('suggest_fio', requestParams);

    return this.http.post<IJsonRpcResponse<IFioSuggestionResponseDto[]>>(this.environment.rpcApi, request);
  }

  private createRpcRequest<T>(method: string, params: T): IJsonRpcRequest<T> {
    return {
      id: uuid(),
      jsonrpc: '2.0',
      method,
      params,
    };
  }

  public editUserProfile(data: Partial<IProfile>): Observable<IChangeResponse> {
    return this.http.patch<IChangeResponse>(`${this.path}user/v2/edit`, data);
  }

  public changeUserPassword(data: IChangePassword): Observable<IChangeResponse> {
    return this.http.post<IChangeResponse>(`${this.path}user/password/change/inside`, data);
  }

  public checkNewPhone(data: ICheckNewPhone): Observable<IChangeResponse> {
    return this.http.post<IChangeResponse>(`${this.path}user/v2/create`, data);
  }

  public changePhone(data: IChangePhone): Observable<IChangeResponse> {
    return this.http.patch<IChangeResponse>(`${this.path}user/edit/phone`, data);
  }

  public getAccounts(): Observable<any> {
    return this.http.get(`${this.path}accounts`);
  }

  public editUserEmail(data: IEmail): Observable<IChangeResponse> {
    return this.http.patch<IChangeResponse>(`${this.path}user/edit/email`, data);
  }

  public getEditableFields(id: string): Observable<IDataResponse<IProfileEditableFields>> {
    return this.http.get<IDataResponse<IProfileEditableFields>>(`${this.path}user/v2/clients/${id}/editable-fields`);
  }

  public LoadReasons(): Observable<IDataResponse<IReasons[]>> {
    return this.http.get<IDataResponse<IReasons[]>>(`${this.path}user/v2/dictionaries/delete-reasons`);
  }

  /**
   * Восстановление профиля пользователя
   * @param id ID пользователя
   * @returns Observable с ответом об успешном восстановлении
   */
  public recoverProfile(id: string): Observable<void> {
    return this.http.patch<void>(`${this.path}user/v2/clients/${id}/restore`, {});
  }

  /**
   * Удаление профиля пользователя
   * @param id ID пользователя
   * @param deleteReasonId ID причины удаления
   * @returns Observable с ответом об успешном удалении
   */
  public deleteProfile(id: string, deleteReasonId: number): Observable<IAttributeResponse<{ deletedAt: string }>> {
    const body = {
      data: {
        id: `${id}`,
        type: 'Client',
        attributes: {
          deleteReasonId
        }
      }
    };
  
    return this.http.delete<IAttributeResponse<{ deletedAt: string }>>(`${this.path}user/v2/clients/${id}`, { body });
  }
}
