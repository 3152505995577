import { IProductDTO } from '@app/states/product/interfaces/product-dto.interface';
import { IProduct } from '@app/states/product/interfaces/product.interface';
import {
  isILSApplicationProduct,
  isILSContractProduct,
  isPDLProduct,
} from '@app/states/product/type-guards/product.type-guard';
import { productPdlMap } from '@app/states/product/helpers/product-pdl-map.helper';
import { productILSApplicationMap } from '@app/states/product/helpers/product-ils-application-map.helper';
import { productIlsContractMap } from '@app/states/product/helpers/product-ils-contract-map.helper';

export function productMap(product: IProductDTO): IProduct {
  if (!product) {return null;}

  if (isPDLProduct(product.type, product.attributes)) {
    return productPdlMap(product.id, product.attributes);
  }

  if (isILSApplicationProduct(product.type, product.attributes)) {
    return productILSApplicationMap(product.id, product.attributes);
  }

  if (isILSContractProduct(product.type, product.attributes)) {
    return productIlsContractMap(product.id, product.attributes);
  }
}

