export enum GaEvent {
  CLIENT_NEXT_CONTACT = 'CLIENT_NEXT_CONTACT',
  CLIENT_NEXT_ADDITIONAL = 'CLIENT_NEXT_ADDITIONAL',
  CLIENT_ADD_CARD_STEPS = 'CLIENT_ADD_CARD_STEPS',
  CLIENT_ADD_ANOTHER_CARD = 'CLIENT_ADD_ANOTHER_CARD',
  CLIENT_ADD_YANDEX_WALLET = 'CLIENT_ADD_YANDEX_WALLET',
  CLIENT_ADD_QIWI_WALLET = 'CLIENT_ADD_QIWI_WALLET',
  CLIENT_ADD_ANOTHER_YANDEX_WALLET = 'CLIENT_ADD_ANOTHER_YANDEX_WALLET',
  CLIENT_CHOICE_METHOD_CONTACT = 'CLIENT_CHOICE_METHOD_CONTACT',
  CLIENT_TIE_SOCIAL_NETWORK_VK = 'CLIENT_TIE_SOCIAL_NETWORK_VK',
  CLIENT_TIE_SOCIAL_NETWORK_OK = 'CLIENT_TIE_SOCIAL_NETWORK_OK',
  CLIENT_TIE_SOCIAL_NETWORK_FB = 'CLIENT_TIE_SOCIAL_NETWORK_FB',
  CLIENT_APPLY_LOAN_PRIMARY = 'CLIENT_APPLY_LOAN_PRIMARY',
  CLIENT_SIGN_CONTRACT = 'CLIENT_SIGN_CONTRACT',
  CLIENT_SIGN_CONTRACT_RESEND_CODE = 'CLIENT_SIGN_CONTRACT_RESEND_CODE',
  CLIENT_VIEWING_CONTRACT = 'CLIENT_VIEWING_CONTRACT',
  CLIENT_PRINT_CONTRACT = 'CLIENT_PRINT_CONTRACT',
  CLIENT_SIGN_CONTRACT_OPEN_BLOCK = 'CLIENT_SIGN_CONTRACT_OPEN_BLOCK',
  CLIENT_CONFIRM_EMAIL_SEND = 'CLIENT_CONFIRM_EMAIL_SEND',
  CLIENT_REPAY_LOAN_OPEN_BLOCK = 'CLIENT_REPAY_LOAN_OPEN_BLOCK',
  CLIENT_CLICK_PAY = 'CLIENT_CLICK_PAY',
  CLIENT_EXTEND_LOAN_OPEN_BLOCK = 'CLIENT_EXTEND_LOAN_OPEN_BLOCK',
  CLIENT_BUTTON_FORMULATE_ADDITIONAL_AGREEMENT = 'CLIENT_BUTTON_FORMULATE_ADDITIONAL_AGREEMENT',
  CLIENT_SIGN_AGREEMENT = 'CLIENT_SIGN_AGREEMENT',
  CLIENT_CLICK_TERMS_AGREEMENT = 'CLIENT_CLICK_TERMS_AGREEMENT',
  CLIENT_RESEND_CODE_AGREEMENT = 'CLIENT_RESEND_CODE_AGREEMENT',
  CLIENT_PAY_BUTTON_AGREEMENT = 'CLIENT_PAY_BUTTON_AGREEMENT',
  CLIENT_REPAY_LOAN_OPEN_BLOCK_AGREEMENT = 'CLIENT_REPAY_LOAN_OPEN_BLOCK_AGREEMENT',
  CLIENT_BUTTON_SIGN_AGREEMENT = 'CLIENT_BUTTON_SIGN_AGREEMENT',
  CLIENT_BUTTON_VIEW_AGREEMENT = 'CLIENT_BUTTON_VIEW_AGREEMENT',
  CLIENT_CHANGE_PHONE_OPEN_MODAL = 'CLIENT_CHANGE_PHONE_OPEN_MODAL',
  CLIENT_CHANGE_PHONE_CONFIRM = 'CLIENT_CHANGE_PHONE_CONFIRM',
  CLIENT_CHANGE_PHONE_CLICK_CHANGE = 'CLIENT_CHANGE_PHONE_CLICK_CHANGE',
  CLIENT_CHANGE_PHONE_RESEND_CODE = 'CLIENT_CHANGE_PHONE_RESEND_CODE',
  CLIENT_CHANGE_PHONE_SUCCESS = 'CLIENT_CHANGE_PHONE_SUCCESS',
  CLIENT_CHANGE_EMAIL_OPEN_MODAL = 'CLIENT_CHANGE_EMAIL_OPEN_MODAL',
  CLIENT_CHANGE_EMAIL_SUCCESS = 'CLIENT_CHANGE_EMAIL_SUCCESS',
  CLIENT_SAVE_PROFILE_STEP = 'CLIENT_SAVE_PROFILE_STEP',
  CLIENT_COMPLAIN_MODAL = 'CLIENT_COMPLAIN_MODAL',
  CLIENT_COMPLAIN_OPERATOR = 'CLIENT_COMPLAIN_OPERATOR',
  CLIENT_CHOOSE_CHANGE_PASSWORD = 'CLIENT_CHOOSE_CHANGE_PASSWORD',
  CLIENT_CHANGE_PASSWORD_SUCCESS = 'CLIENT_CHANGE_PASSWORD_SUCCESS',
  CLIENT_LOGOUT = 'CLIENT_LOGOUT',
  CLIENT_AUTH_SUCCESS = 'CLIENT_AUTH_SUCCESS',
  CLIENT_TIE_VK_SUCCESS = 'CLIENT_TIE_VK_SUCCESS',
  CLIENT_TIE_OK_SUCCESS = 'CLIENT_TIE_OK_SUCCESS',
  CLIENT_TIE_FB_SUCCESS = 'CLIENT_TIE_FB_SUCCESS',
  CLIENT_ARCHIVE_PAGE = 'CLIENT_ARCHIVE_PAGE',
  CLIENT_ADD_CARD = 'CLIENT_ADD_CARD',
  CLIENT_GET_CARD = 'CLIENT_GET_CARD',
  CLIENT_TIE_NEW_YANDEX_WALLET = 'CLIENT_TIE_NEW_YANDEX_WALLET',
  CLIENT_YANDEX_WALLET_SUCCESS_TIE = 'CLIENT_YANDEX_WALLET_SUCCESS_TIE',
  CLIENT_MAKE_MAIN_CONTACT = 'CLIENT_MAKE_MAIN_CONTACT',
  CLIENT_MAKE_MAIN_CARD = 'CLIENT_MAKE_MAIN_CARD',
  CLIENT_MAKE_MAIN_YANDEX_WALLET = 'CLIENT_MAKE_MAIN_YANDEX_WALLET',
  CLIENT_INDENTIFICATION_YANDEX_WALLET_PA = 'CLIENT_INDENTIFICATION_YANDEX_WALLET_PA',
  CLIENT_CLICK_PENCIL = 'CLIENT_CLICK_PENCIL',
  CLIENT_YANDEX_WALLET_DELETED = 'CLIENT_YANDEX_WALLET_DELETED',
  CLIENT_CARD_DELETED = 'CLIENT_CARD_DELETED',
  CLIENT_UNTIE_SOCIAL_NETWORK_PA = 'CLIENT_UNTIE_SOCIAL_NETWORK_PA',
  CLIENT_CANCEL_SOCIAL_NETWORK_PA = 'CLIENT_CANCEL_SOCIAL_NETWORK_PA',
  CLIENT_UNTIE_SOCIAL_NETWORK_STEPS = 'CLIENT_UNTIE_SOCIAL_NETWORK_STEPS',
  CLIENT_CANCEL_SOCIAL_NETWORK_STEPS = 'CLIENT_CANCEL_SOCIAL_NETWORK_STEPS',
  CLIENT_APPLY_LOAN_AGAIN = 'CLIENT_APPLY_LOAN_AGAIN',
  CLIENT_ACTION_PAYMENT = 'CLIENT_ACTION_PAYMENT',
  CLIENT_MAIL_CONFIRMATION_EMAIL = 'CLIENT_MAIL_CONFIRMATION_EMAIL',
  CLIENT_ACTION_PAYMENT_CONTACT = 'CLIENT_ACTION_PAYMENT_CONTACT',
  CLIENT_ACTION_PAYMENT_CARD = 'CLIENT_ACTION_PAYMENT_CARD',
  CLIENT_ACTION_PAYMENT_YANDEX = 'CLIENT_ACTION_PAYMENT_YANDEX',
  CLIENT_CLICK_OPEN_CHAT = 'CLIENT_CLICK_OPEN_CHAT',
  USER_REGISTER_ATTEMPT = 'USER_REGISTER_ATTEMPT',
  USER_REGISTER_NEXT = 'USER_REGISTER_NEXT',
  USER_CHANGE_PHONE = 'USER_CHANGE_PHONE',
  USER_REQUEST_AGAIN_CODE = 'USER_REQUEST_AGAIN_CODE',
  USER_CLICK_PERSONAL_AREA = 'USER_CLICK_PERSONAL_AREA',
  USER_CLICK_RECEIVE_MONEY = 'USER_CLICK_RECEIVE_MONEY',
  USER_CLICK_PASSRESTORE_PAGE_AUTH = 'USER_CLICK_PASSRESTORE_PAGE_AUTH',
  USER_SUCCESS_CHANGE_PASSWORD = 'USER_SUCCESS_CHANGE_PASSWORD',
  USER_CLICK_PASSRESTORE = 'USER_CLICK_PASSRESTORE',
  USER_PASSRESTORE_EMAIL = 'USER_PASSRESTORE_EMAIL',
  USER_PASSRESTORE_LOGIN = 'USER_PASSRESTORE_LOGIN',
  USER_PASSRESTORE_PHONE = 'USER_PASSRESTORE_PHONE',
  USER_CLICK_GOOGLE_PLAY = 'USER_CLICK_GOOGLE_PLAY',
  USER_CLICK_APP_STORE = 'USER_CLICK_APP_STORE',
  USER_CLICK_OPEN_CHAT = 'USER_CLICK_OPEN_CHAT',
  USER_RECEIVE_MONEY_CALC = 'USER_RECEIVE_MONEY_CALC',
  USER_PERSONAL_AREA = 'USER_PERSONAL_AREA',
  USER_INVESTOR_BUTTON = 'USER_INVESTOR_BUTTON'
}
