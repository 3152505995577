import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { Observable } from 'rxjs';
import { IVirtualCardStateDto } from '../interfaces/virtual-card-state-dto.interface';
import { IVirtualCardContextDto } from '../interfaces/virtual-card-context-dto.interface';
import { IVirtualCardAccountDto } from '../interfaces/virtual-card-account-dto.interface';
import { IVirtualCardWidgetTokenDto } from '../interfaces/virtual-card-widget-token-dto.interface';
import { IVirtualCardReissueStatusDto } from '../interfaces/virtual-card-reissue-status.interface';
import { IVirtualCardOperationsDto } from '../interfaces/virtual-card-operations-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class VirtualCardService {
  private readonly virtualCardPath: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.virtualCardPath = `${this.environment.api}virtual-cards/v1`;
  }

  public check(): Observable<IDataResponse<IVirtualCardStateDto>> {
    return this.http.post<IDataResponse<IVirtualCardStateDto>>(`${this.virtualCardPath}/yoomoney/check`, {});
  }

  public getContext(): Observable<IDataResponse<IVirtualCardContextDto>> {
    return this.http.get<IDataResponse<IVirtualCardContextDto>>(`${this.virtualCardPath}/yoomoney/context`)
  }

  public create(code: string): Observable<IDataResponse<IVirtualCardAccountDto>> {
    return this.http.post<IDataResponse<IVirtualCardAccountDto>>(`${this.virtualCardPath}/yoomoney/accounts`, {
      data: {
        type: "account",
        attributes: {
          code
        }
      }
    });
  }

  public token(): Observable<IDataResponse<IVirtualCardWidgetTokenDto>> {
    return this.http.get<IDataResponse<IVirtualCardWidgetTokenDto>>(`${this.virtualCardPath}/yoomoney/widget/token`)
  }

  public block(cardId: string): Observable<void> {
    return this.http.post<void>(`${this.virtualCardPath}/yoomoney/accounts/cards/${cardId}/block`, {})
  }

  public reissue(data: any): Observable<IDataResponse<IVirtualCardReissueStatusDto>> {
    return this.http.post<IDataResponse<IVirtualCardReissueStatusDto>>(`${this.virtualCardPath}/yoomoney/cards`, {data})
  }

  public reissueStatus(id: string): Observable<IDataResponse<IVirtualCardReissueStatusDto>> {
    return this.http.get<IDataResponse<IVirtualCardReissueStatusDto>>(`${this.virtualCardPath}/yoomoney/cards/reissue/status/${id}`)
  }

  public operations(accountNumber: string, from: string, to: string, cursor?: string):Observable<IVirtualCardOperationsDto> {
    return this.http.get<IVirtualCardOperationsDto>(`${this.virtualCardPath}/yoomoney/accounts/${accountNumber}/operations/detail?from=${from}&to=${to}${cursor ? '&cursor=' + cursor : ''}`)
  }
}
