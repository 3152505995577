import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IJsonRpcQuery } from '@app/interfaces/jsonrpc-query.interface';
import { IJsonRpcResponse } from '@app/interfaces/jsonrpc-response.interface';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ILogMessagePayload } from '../interfaces/log-message-payload.interface';
import { ILogResponse } from '../interfaces/log-response.interface';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private path = `${this.environment.gate}rpc`;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  public log(body: ILogMessagePayload, id = '1'): Observable<ILogResponse> {
    const request: IJsonRpcQuery<ILogMessagePayload> = {
      id,
      jsonrpc: '2.0',
      method: 'live_logging',
      params: {
        body,
        tag: 'frontend-wb-lk',
      },
    };

    return this.http.post<IJsonRpcResponse<{}>>(this.path, request).pipe(
      mergeMap((res) => res.error ? throwError(() => res) : of(res)),
    );
  }
}
