import { IILSApplicationDTO } from '@app/states/product/interfaces/ils-application-dto.interface';
import { IProduct } from '@app/states/product/interfaces/product.interface';
import { ProductStatus } from '@app/states/product/constants/product-status.const';
import { ILSApplicationState } from '@app/states/product/constants/ils-application-state.const';
import { Product } from '@app/states/calculator/constants/product.const';
import { TermUnit } from '@app/states/product/constants/term-unit.const';
import { PercentUnit } from '@app/states/product/constants/percent-unit.const';
import { IInstallment } from '@app/states/product/interfaces/supplementary/installment.interface';
import { amnestyMap, holidayMap, prolongationMap } from './product-supplementary.helper';


export function productILSApplicationMap(id: string, product: IILSApplicationDTO): IProduct {
  const installment = installmentMap(product);
  return {
    id,
    type: Product.ILS,
    documentUrl: `documents/v2/ils/list/${id}`,
    statementUrl: `documents/v2/ils/statement/${id}`,
    status: statusMap(product),
    sum: {
      debt: 0,
      debtPaid: 0,
      debtOld: 0,
      debtPrincipal: 0,
      debtPercent: 0,
      debtPenalty: 0,
      initial: 0,
      debtEnd: 0,
    },
    bonuses: {
      available: 0,
      received: 0,
    },
    date: {
      start: '',
      end: '',
      oldEnd: '',
      request: '',
    },
    percent: {
      value: 0,
      unit: PercentUnit.Year,
    },
    currentPercent: null,
    term: {
      value: 0,
      unit: TermUnit.Month,
    },
    overdue: {
      value: false,
      type: null,
      delay: 0,
    },
    payments: {
      current: null,
      all: null,
    },
    supplementary: {
      installment,
      amnesty: amnestyMap(product),
      prolongation: prolongationMap(product),
      holiday: holidayMap(product),
    },
    isSold: false,
  };
}

export function statusMap(product: IILSApplicationDTO): IProduct['status'] {
  switch (product.state) {
    case ILSApplicationState.Scoring:
    case ILSApplicationState.Underwriting:
      return ProductStatus.Waiting;
    case ILSApplicationState.Info:
      return ProductStatus.IdRequired;
  }
}

export function installmentMap(product: IILSApplicationDTO): IInstallment {
  if (product.supplementary?.installnesty) {
    return {
      id: product.supplementary.installnesty.id,
      initialAmount: product.supplementary.installnesty.depositAmount,
      payDate: product.supplementary.installnesty.payDateEnd,
      signDate: product.supplementary.installnesty.signDateEnd,
      status: product.supplementary.installnesty.status,
      discount: product.supplementary.installnesty.writeOff,
      payments: product.supplementary.installnesty.paymentsPlan.map(p => ({
        date: p.payDateEnd,
        sumPaid: p.sumPaidPerPeriod - p.sumLeftPerPeriod,
        sumOverpayment: 0,
        sumLeft: p.sumLeftPerPeriod,
        sumTotal: p.sumPaidPerPeriod,
        debtTotal: p.totalSumLeftToPay,
        paid: p.paid,
        discount: p.writeOff,
      })),
    };
  }

  if (product.supplementary?.installment) {
    return {
      id: product.supplementary.installment.id,
      initialAmount: product.supplementary.installment.depositAmount,
      payDate: product.supplementary.installment.payDateEnd,
      signDate: product.supplementary.installment.signDateEnd,
      status: product.supplementary.installment.status,
      discount: 0,
      payments: product.supplementary.installment.paymentsPlan.map(p => ({
        date: p.payDateEnd,
        sumOverpayment: 0,
        sumPaid: p.sumLeftToPayPerMonth,
        sumLeft: p.totalSumToPayPerMonth - p.sumLeftToPayPerMonth,
        sumTotal: p.totalSumToPayPerMonth,
        debtTotal: p.totalSumLeftToPay,
        paid: p.payed,
        discount: 0,
      })),
    };
  }

  return undefined;
}
