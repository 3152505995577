import { CalculatorActionList } from './calculator-action-list.const';
import { ICalculator } from '@app/interfaces/calculator.interface';
import { IILSCalculator } from '@app/states/calculator/interfaces/ils-calculator.interface';
import { IILSSchedule } from '@app/states/calculator/interfaces/ils-schedule.interface';
import { IError } from '@app/interfaces/error.interface';
import { IILSScheduleDTO } from '@app/states/calculator/interfaces/ils-schedule-dto.interface';

/**
 * Namespace для действий состояния "Калькулятор займа"
 */
export namespace CalculatorActions {
  /**
   * Действие для загрузки данных калькулятора
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD;

    /**
     * Тело действия
     *
     * @param payload Информация запрашивать ли данные калькулятора по ИЛС
     */
    constructor(public payload: boolean = true) {
    }
  }

  /**
   * Действие для успешной загрузки данных калькулятора
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные калькулятора по ПДЛ и ИЛС
     */
    constructor(public payload: { pdl: ICalculator; ils: IILSCalculator }) {
    }
  }

  /**
   * Действие для неуспешной загрузки данных калькулятора
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Ошибка загрузки данных калькулятора
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для заполнения расписания калькулятора ИЛС
   */
  export class Schedule {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.SCHEDULE;

    /**
     * Тело действия
     *
     * @param payload Данные калькулятора ИЛС
     */
    constructor(public payload: IILSSchedule) {
    }
  }

  /**
   * Действие для неуспешного заполнения расписания калькулятора ИЛС
   */
  export class ScheduleFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.SCHEDULE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного заполнения расписания калькулятора ИЛС
   */
  export class ScheduleSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.SCHEDULE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные калькулятора ИЛС
     */
    constructor(public payload: IILSScheduleDTO) {
    }
  }

  /**
   * Действие для загрузки данных калькулятора PDL
   */
  export class LoadPdl {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD_PDL;

    /**
     * Тело действия
     *
     * @param payload промокод
     */
    constructor(public payload: string = null) {
    }
  }

  /**
   * Действие для успешной загрузки данных калькулятора PDL
   */
  export class LoadPdlSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD_PDL_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные калькулятора PDL
     */
    constructor(public payload: ICalculator) {
    }
  }

  /**
   * Действие для неуспешной загрузки данных калькулятора PDL
   */
  export class LoadPdlFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.LOAD_PDL_FAIL;

    /**
     * Тело действия
     *
     * @param payload Ошибка загрузки данных калькулятора
     */
    constructor(public payload: IError[]) {
    }
  }


  /**
   * Действие для сброса данных калькулятора при логауте пользователя
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: CalculatorActionList = CalculatorActionList.RESET;
  }
}
