import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loaders: Set<string> = new Set<string>();
  private isLoading = false;

  public loading(isLoading: boolean = true, trigger: string = ''): void {
    if (isLoading) {
      this.loaders.add(trigger);
    } else {
      this.loaders.delete(trigger);
    }

    this.visibilityCheck();
  }

  public clear(): void {
    this.loaders.clear();

    this.visibilityCheck();
  }

  private visibilityCheck(): void {
    if (this.isLoading !== !!this.loaders.size) {
      this.isLoading = !!this.loaders.size;
      this.isLoading$.next(this.isLoading);
    }
  }
}
