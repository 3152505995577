import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICreateFeedbackResponse } from '../interfaces/create-feedback-response';
import { IReasonDto } from '@app/states/feedback/interfaces/reason-dto.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IFeedbackForm } from '@app/states/feedback/interfaces/feedback-form.interface';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  private readonly path: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.path = `${this.environment.api}complaints`;
  }

  public reasons(): Observable<IDataResponse<IReasonDto[]>> {
    return this.http.get<IDataResponse<IReasonDto[]>>(`${this.path}/get`);
  }

  public createRequest(payload: IFeedbackForm): Observable<ICreateFeedbackResponse> {
    return this.http.post<ICreateFeedbackResponse>(`${this.path}/create`, payload);
  }
}
