import { IStateMigration } from '@app/interfaces/state-migration.interface';
import { RequestStatus } from '@app/constants/request-status.const';

/**
 * Класс миграций состояния "Заём"
 */
export class ProductStateMigrations {
  /**
   * Метод получения перечня миграций хранилища
   */
  public static migrate(): IStateMigration[] {
    return [
      {
        version: 3,
        key: 'Product',
        versionKey: 'version',
        migrate: (state) => ({
          ...state,
          version: 4,
          newLoan: {
            ...state.newLoan,
            model: {
              ...state.newLoan.model,
              defaultProduct: null,
            },
          },
        }),
      },
      {
        version: 2,
        key: 'Product',
        versionKey: 'version',
        migrate: (state) => ({
          ...state,
          version: 3,
          newLoan: {
            model: {
              sum: null,
              term: null,
              product: null,
              account: null,
              terms: null,
              consents: null,
              method: null,
              card: null,
              expire: null,
              bank: null,
              promocode: {
                code: null,
                confirmed: null,
              }
            },
            status: null,
            errors: null,
            dirty: null,
          },
        }),
      },
      {
        version: 1,
        key: 'Product',
        versionKey: 'version',
        migrate: (state) => ({
          ...state,
          version: 2,
          isILSEnabled: true,
        }),
      },
      {
        version: undefined,
        key: 'Product',
        versionKey: 'version',
        migrate: (state) => ({
          version: 1,
          data: null,
          status: RequestStatus.NotInvoked,
          newLoan: null,
          payment: {
            model: null,
            status: null,
            errors: null,
            dirty: null,
          },
          prolongation: null,
          disclaim: null,
          contract: null,
          closedCount: {
            count_loans_pdl: 0,
            count_loans: 0,
            count_loans_pos: 0,
          },
          paymentMethods: null,
          isILSEnabled: false,
        }),
      },
      
      {
        version: 1,
        key: 'Product',
        versionKey: 'version',
        migrate: (state) => ({
          version: 2,
          data: null,
          status: RequestStatus.NotInvoked,
          newLoan: null,
          payment: {
            model: null,
            status: null,
            errors: null,
            dirty: null,
          },
          prolongation: null,
          disclaim: null,
          contract: null,
          closedCount: {
            count_loans_pdl: 0,
            count_loans: 0,
            count_loans_pos: 0,
          },
          paymentMethods: null,
          isILSEnabled: false,
          refinancingPaid: false,
        }),
      },
    ];
  }
}
