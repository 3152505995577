import { ProfileActionList } from './profile-action-list.const';
import { IProfile } from '../interfaces/profile.interface';
import { ISocialNetworks } from '../interfaces/social-networks.interface';
import { IChangePassword } from '../interfaces/change-password.interface';
import { IEmail } from '../interfaces/email.interface';
import { IChangeResponse } from '../interfaces/change-response.interface';
import { ICheckNewPhone } from '../interfaces/check-new-phone.interface';
import { IError } from '@app/interfaces/error.interface';
import { ISelectItem } from '@web-bankir/ui-kit/components/controls/select';
import { IFileUploadDto } from '@app/states/product/interfaces/file-upload-dto.interface';
import { IProfileEditableFields } from '../interfaces/profile-editable-fields.interface';
import { IReasons } from '../interfaces/reasons.interface';


/**
 * Namespace для действий состояния "Профиль"
 */
export namespace ProfileActions {
  /**
   * Действие для загрузки профиля пользователя
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD;

    /**
     * Тело действия
     * @param withModals Флаг для загрузки данных модального окна для редактирования данных профиля
     */
    constructor(public withModals = false) {}
  }

  /**
   * Действие для неуспешной загрузки профиля пользователя
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_FAIL;


    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной загрузки профиля пользователя
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные профиля пользователя
     */
    constructor(public payload: IProfile, public workAdditionalIncome: number) {
    }
  }
  /**
   * Действие для загрузки информации о наличии социальных сетей пользователя
   */
  export class LoadSocialNetwork {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_USER_SOCIAL_NETWORK;
  }
  /**
   * Действие для неуспешной загрузки информации о наличии социальных сетей пользователя
   */
  export class LoadSocialNetworkFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_USER_SOCIAL_NETWORK_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешной загрузки информации о наличии социальных сетей пользователя
   */
  export class LoadSocialNetworkSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_USER_SOCIAL_NETWORK_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Данные о наличии социальных сетей пользователя
     */
    constructor(public payload: ISocialNetworks) {
    }
  }
  /**
   * Действие для загрузки фотографий паспорта и пользователя
   */
  export class Verify {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.VERIFY;
    /**
     * Тело действия
     *
     * @param payload загружаемые файлы фото паспорта и пользователя
     */
    constructor(public payload: Partial<IFileUploadDto>) {
    }
  }
  /**
   * Действие для неуспешной загрузки фотографий паспорта и пользователя
   */
  export class VerifyFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.VERIFY_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешной загрузки фотографий паспорта и пользователя
   */
  export class VerifySuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.VERIFY_SUCCESS;
  }

  /**
   * Действие для предлагаемых вариантов заполнения данных в разделе профиля
   */
  export class Suggestion {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SUGGESTION;
    /**
     * Тело действия
     *
     * @param payload Предлагаемые варианты заполнения данных: type - имя поля, value - предлагаемое значение
     */
    constructor(public payload: {type: string; value: string}) {
    }
  }


  /**
   * Действие при успехе предложения вариантов заполнения раздела профиля
   */
  export class SuggestionSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SUGGESTION_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные
     * @param type Имя поля для предложения
     */
    constructor(public payload: ISelectItem[], public type: string) {
    }
  }

  /**
   * Действие при неуспехе предложения вариантов для раздела профиля
   */
  export class SuggestionFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SUGGESTION_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для изменения данных профиля пользователя
   */
  export class ChangeProfile {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PROFILE;
   /**
    * Тело действия
    *
    * @param payload Частичные данные пользователя
    * @param successNotification нотификация при успехе
    */
    constructor(
      public payload: Partial<IProfile>, 
      public successNotification = {
        title: 'Успех',
        text: 'Анкетные данные изменены',
      }
    ) {
    }
  }
  /**
   * Действие для неуспешного изменения данных профиля пользователя
   */
  export class ChangeProfileFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PROFILE_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного изменения данных профиля пользователя
   */
  export class ChangeProfileSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PROFILE_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Нотификация при успехе
     */
    constructor(public payload: any) {
    }
  }
  /**
   * Действие для изменения пароля пользователя
   */
  export class ChangePassword {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PASSWORD;
    /**
     * Тело действия
     *
     * @param payload Данные о старом и новом пароле пользователя
     */
    constructor(public payload: IChangePassword) {
    }
  }
  /**
   * Действие для неуспешного изменения пароля пользователя
   */
  export class ChangePasswordFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PASSWORD_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного изменения пароля пользователя
   */
  export class ChangePasswordSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PASSWORD_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных
     */
    constructor(public payload: IChangeResponse) {
    }
  }
  /**
   * Действие для проверки нового номера телефона пользователя
   */
  export class CheckPhone {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHECK_USER_PHONE;
    /**
     * Тело действия
     *
     * @param payload Данные с новым номером телефона
     */
    constructor(public payload: ICheckNewPhone) {
    }
  }
  /**
   * Действие для неуспешной проверки нового номера телефона пользователя
   */
  export class CheckPhoneFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHECK_USER_PHONE_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешной проверки нового номера телефона пользователя
   */
  export class CheckPhoneSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHECK_USER_PHONE_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных, номер телефона
     */
    constructor(public payload: { response: IChangeResponse; phone: number }) {
    }
  }
  /**
   *  Действие для запроса кода подтверждения
   */
  export class SendCodeConfirmation {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SEND_CODE_CONFIRMATION;
  }
  /**
   * Действие для неуспешного запроса кода подтверждения
   */
  export class SendCodeConfirmationFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SEND_CODE_CONFIRMATION_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного запроса кода подтверждения
   */
  export class SendCodeConfirmationSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.SEND_CODE_CONFIRMATION_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных
     */
    constructor(public payload: IChangeResponse) {
    }
  }
  /**
   * Действие для изменения номера телефона пользователя
   */
  export class ChangePhone {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PHONE;
    /**
     * Тело действия
     *
     * @param payload код подтверждения
     */
    constructor(public payload: { smsCode: string }) {
    }
  }
  /**
   * Действие для неуспешного изменения номера телефон
   */
  export class ChangePhoneFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PHONE_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного изменения номера телефона пользователя
   */
  export class ChangePhoneSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_PHONE_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных
     */
    constructor(public payload: IChangeResponse) {
    }
  }
  /**
   * Действие для изменения электронной почты пользователя
   */
  export class ChangeEmail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_EMAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об электронной почте
     */
    constructor(public payload: IEmail) {
    }
  }
  /**
   * Действие для неуспешного изменения электронной почты пользователя
   */
  export class ChangeEmailFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_EMAIL_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного изменения электронной почты пользователя
   */
  export class ChangeEmailSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_USER_EMAIL_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных
     */
    constructor(public payload: IChangeResponse) {
    }
  }
  /**
   * Действие для подвтверждения электронной почты пользователя
   */
  export class ConfirmEmail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CONFIRM_USER_EMAIL;
    /**
     * Тело действия
     *
     * @param payload Электронная почта пользователя
     */
    constructor(public payload: IEmail) {
    }
  }
  /**
   * Действие для неуспешного подтверждения электронной почты пользователя
   */
  export class ConfirmEmailFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CONFIRM_USER_EMAIL_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }
  /**
   * Действие для успешного подтверждения электронной почты пользователя
   */
  export class ConfirmEmailSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CONFIRM_USER_EMAIL_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload Код и сообщение об изменении данных
     */
    constructor(public payload: IChangeResponse) {
    }
  }

  /**
   * Действие для загрузки возможных для редактирования полей в профиле пользователя
   */
  export class LoadEditableFields {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_EDITABLE_FIELDS;
  }

  /**
   * Действие для неуспешной загрузки возможных для редактирования полей в профиле пользователя
   */
  export class LoadEditableFieldsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_EDITABLE_FIELDS_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной загрузки возможных для редактирования полей в профиле пользователя
   */
  export class LoadEditableFieldsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_EDITABLE_FIELDS_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload данные полей для редактирования
     */
    constructor(public payload: IProfileEditableFields) {}
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.RESET;
  }

  /**
   * Действие для сброса данных модального окна редактирования профиля к значению по умолчанию
   */
  export class ResetModalInfo {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.RESET_MODAL_INFO;
  }

  /**
   * Действие показа модалки для смены значений профиля
   */
  export class ShowChangeModal {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.CHANGE_PROFILE;
    /**
     * Тело действия
     *
     * @param payload ID займа
     */
    constructor(public payload?: string) {}
  }

  /**
   * Действие получение причин удаления профиля
   */
  export class LoadReasons {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_REAAONS;
  }

  export class LoadReasonsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_REAAONS_SUCCESS;
    /**
     * Тело действия
     *
     * @param payload причины удаления профиля
     */
    constructor(public payload?: IReasons[]) {}
    
  }

  export class LoadReasonsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.LOAD_REAAONS_FAIL;
    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для восстановления профиля пользователя
   */
  export class RecoverProfile {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.RECOVER_PROFILE;
  }

  /**
   * Действие для успешного восстановления профиля пользователя
   */
  export class RecoverProfileSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.RECOVER_PROFILE_SUCCESS;
  }

  /**
   * Действие для неуспешного восстановления профиля пользователя
   */
  export class RecoverProfileFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.RECOVER_PROFILE_FAIL;

    /**
     * Тело действия
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для удаления профиля пользователя
   */
  export class DeleteProfile {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.DELETE_PROFILE;

    /**
     * Тело действия
     * @param payload ID причины удаления
     */
    constructor(public payload: number) {}
  }

  /**
   * Действие для успешного удаления профиля пользователя
   */
  export class DeleteProfileSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.DELETE_PROFILE_SUCCESS;

    /**
     * Тело действия
     * @param payload время удаления
     */
    constructor(public payload: string) {}
  }

  /**
   * Действие для неуспешного удаления профиля пользователя
   */
  export class DeleteProfileFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ProfileActionList = ProfileActionList.DELETE_PROFILE_FAIL;

    /**
     * Тело действия
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }
}
