import { RequestStatus } from 'projects/web-bankir-app/src/app/constants/request-status.const';
import { IAccountsState } from './accounts.state.interface';

/**
 * Дефолтное состояние хранилища "Счета" при его инициализации
 */
export const ACCOUNTS_STATE_DEFAULTS: IAccountsState = {
  data: null,
  status: RequestStatus.NotInvoked,
  form: null,
  confirmation: null,
};
