import { CommonModule } from '@angular/common';
import { LoadingLogoComponent } from '@web-bankir/ui-kit/components/graph';
import { Component, HostBinding, Inject, OnInit, Renderer2 } from '@angular/core';
import { GlobalLoaderService } from '@app/components/global-loader/services/global-loader.service';
import { Destroy } from '@web-bankir/ui-kit/helpers/abstractions';
import { debounceTime } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  imports: [
    CommonModule,
    LoadingLogoComponent,
  ]
})
export class GlobalLoaderComponent extends Destroy implements OnInit {
  @HostBinding('hidden')
  public isHidden = true;

  constructor(private service: GlobalLoaderService, private renderer: Renderer2, @Inject('Document') private document) {
    super();
  }

  public ngOnInit(): void {
    this.service.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        tap((value) => {
          this.isHidden = !value;

          if (this.isHidden) {
            this.removeClass(this.document?.documentElement, 'loading');
          } else {
            this.addClass(this.document?.documentElement, 'loading');
          }
        })
      )
      .subscribe();
  }

  private addClass(element: HTMLElement, className: string): void {
    if (element) {
      if (!element.classList.contains(className)) {
        this.renderer.addClass(element, className);
      }
    }
  }

  private removeClass(element: HTMLElement, className: string): void {
    if (element) {
      if (element.classList.contains(className)) {
        this.renderer.removeClass(element, className);
      }
    }
  }
}
