import { IConsentDto } from '../../../interfaces/consent-dto.interface';
import { RegistrationActionList } from './registration-action-list.const';
import { ISelectItem } from '@web-bankir/ui-kit/components/controls/select';
import { ConsentPackage } from '@app/constants/consent-package.const';
import { IFingerPrint } from '@app/interfaces/finger-print.interface';
import { IPhoneCodeDto } from '@app/states/registration/interfaces/phone-code-dto.interface';
import { IError } from '@app/interfaces/error.interface';
import { IVerifyDto } from '@app/states/registration/interfaces/verify-dto.interface';
import {
  IRegistrationApplicationDto,
} from '@app/states/registration/interfaces/registration-application-dto.interface';
import { IMobileIDDataDto } from '@app/states/registration/interfaces/mobile-id-data-dto.interface';
import { EventsActionList } from '@app/states/events/states/events-action-list.const';
import { ILoginResponse } from '@app/interfaces/login-response.interface';

/**
 * Namespace для действий состояния "Регистрация"
 */
export namespace RegistrationActions {

  /**
   * Действие для окончания заполнения формы регистрации
   */
  export class Complete {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.COMPLETE;
  }

  export class InitSession {
    public static readonly type: RegistrationActionList = RegistrationActionList.INIT_SESSION;
  }

  export class InitSessionFail {
    public static readonly type: RegistrationActionList = RegistrationActionList.INIT_SESSION_FAIL;

    constructor(public payload: IError[]) {
    }
  }

  export class InitSessionSuccess {
    public static readonly type: RegistrationActionList = RegistrationActionList.INIT_SESSION_SUCCESS;
  }

  /**
   * Действие для предзаполнения формы регистрации
   */
  export class Fill {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.FILL;

    /**
     * Тело действия
     *
     * @param payload Предзаполненное состояние формы регистрации
     * @param skipFirstStep Признак пропуска 1го шага (в случае true - первый шаг не предустанавливается)
     */
    constructor(public payload: IRegistrationApplicationDto, public skipFirstStep = false) {
    }
  }

  /**
   * Действие для получения списка согласий
   */
  export class Consents {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONSENTS;

    /**
     * Тело действия
     *
     * @param payload Получаемые согласия
     */
    constructor(public payload: ConsentPackage[]) {
    }
  }

  /**
   * Действие для неуспешного получения списка согласий
   */
  export class ConsentsFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONSENTS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного получения списка согласий
   */
  export class ConsentsSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONSENTS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные о согласиях
     * @param device Данные о девайсе пользователя
     */
    constructor(public payload: IConsentDto[], public device: IFingerPrint) { // TODO consent type enum
    }
  }

  /**
   * Действие для отправки формы регистрации 1-го шага
   */
  export class SubmitStepOne {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_ONE;
  }


  /**
   * Действие для неуспешной отправки формы регистрации 1-го шага
   */
  export class SubmitStepOneFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_ONE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные о ошибке при отправке формы регистрации 1-го шага
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной отправки формы регистрации 1-го шага
   */
  export class SubmitStepOneSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_ONE_SUCCESS;
  }

  /**
   * Действие для верификации данных формы регистрации
   */
  export class Verify {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.VERIFY;

    /**
     * Тело действия
     *
     * @param field Имя проверяемого поля
     * @param payload Проверяемые данные
     */
    constructor(public field: string, public payload: Partial<IVerifyDto>) {
    }
  }

  /**
   * Действие для неуспешной верификации данных формы регистрации
   */
  export class VerifyFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.VERIFY_FAIL;

    /**
     *  Тело действия
     *
     * @param field Имя проверяемого поля
     * @param payload Данные об ошибке
     */
    constructor(public field: string, public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной верификации данных формы регистрации
   */
  export class VerifySuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.VERIFY_SUCCESS;

    /**
     *  Тело действия
     *
     * @param field Имя проверяемого поля
     */
    constructor(public field: string) {
    }
  }

  /**
   * Действие для предлагаемых вариантов заполнения данных формы регистрации
   */
  export class Suggestion {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUGGESTION;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые варианты заполнения данных: type - имя поля, value - предлагаемое значение
     */
    constructor(public payload: {type: string; value: string}) {
    }
  }

  /**
   * Действие при успехе предложения вариантов заполнения данных формы регистрации
   */
  export class SuggestionSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUGGESTION_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные
     * @param type Имя поля для предложения
     */
    constructor(public payload: ISelectItem[], public type: string) {
    }
  }

  /**
   * Действие при неуспехе предложения вариантов заполнения данных формы регистрации
   */
  export class SuggestionFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUGGESTION_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[], public type: string) {
    }
  }

  /**
   * Действие при форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   */
  export class BuildFio {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.BUILD_FIO;

    /**
     * Тело действия
     *
     * @param payload Данные для форматирования: type - имя поля, value - данные
     */
    constructor(public payload: {type: string; value: string}) {
    }
  }

  /**
   * Действие при успешном форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   */
  export class BuildFioSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.BUILD_FIO_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные после форматирования
     * @param type Имя поля
     */

    constructor(public payload: { value: string }, public type: string) {
    }
  }

  /**
   * Действие при неуспешном форматировании ФИО в правильный порядок: Фамилия, Имя, Отчество формы регистрации
   */
  export class BuildFioFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.BUILD_FIO_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для отправки формы регистрации 2-го шага
   */
  export class SubmitStepTwo {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_TWO;
  }


  /**
   * Действие для неуспешной отправки формы регистрации 2-го шага
   */
  export class SubmitStepTwoFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_TWO_FAIL;

    /**
     *  Тело действия
     *
     * @param payload Данные о ошибке при отправке формы регистрации 2-го шага
     */

    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной отправки формы регистрации 2-го шага
   */
  export class SubmitStepTwoSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_TWO_SUCCESS;
  }

  /**
   * Действие для получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   */
  export class Inn {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.INN;
  }

  /**
   * Действие для неуспешного получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   */
  export class InnFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.INN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного получения ИНН пользователя для предзаполнения формы регистрации по установочным данным
   */
  export class InnSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.INN_SUCCESS;

    /**
     * Тело действия
     * @param payload ИНН
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для отправки формы регистрации 3-го шага
   */
  export class SubmitStepThree {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_THREE;
  }


  /**
   * Действие для неуспешной отправки формы регистрации 3-го шага
   */
  export class SubmitStepThreeFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_THREE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные о ошибке при отправке формы регистрации 3-го шага
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной отправки формы регистрации 3-го шага
   */
  export class SubmitStepThreeSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SUBMIT_STEP_THREE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Первичная уникальная идентификационная информация о зарегестрированном пользователе
     */

    constructor(public payload: ILoginResponse) {
    }
  }

  /**
   * Действие для поля смс-кода формы регистрации
   */
  export class SmsCode {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SMS_CODE;
  }

  /**
   * Действие для неуспешного ввода смс-кода формы регистрации
   */
  export class SmsCodeFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SMS_CODE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного ввода смс-кода формы регистрации
   */
  export class SmsCodeSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SMS_CODE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные смс-кода
     */
    constructor(public payload: IPhoneCodeDto) {
    }
  }

  /**
   * Действие для изменения телефона пользователя
   */
  export class ChangePhone {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CHANGE_PHONE;
  }

  /**
   * Действие для подтверждения введенного телефона при изменения телефона пользователя
   */
  export class ConfirmPhone {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONFIRM_PHONE;

    /**
     * Тело действия
     *
     * @param payload Проверочный код
     */
    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешного подтверждения введенного телефона при изменения телефона пользователя
   */
  export class ConfirmPhoneFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONFIRM_PHONE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного подтверждения введенного телефона при изменения телефона пользователя
   */
  export class ConfirmPhoneSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.CONFIRM_PHONE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные об измененном телефоне пользователя
     */
    constructor(public payload: IPhoneCodeDto) {
    }
  }

  /**
   * Действие для предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   */
  export class MobileID {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.MOBILE_ID;
  }

  /**
   * Действие для неуспешного предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   */
  export class MobileIDFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.MOBILE_ID_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного предзаполнения данных регистрации на основании MobileID
   * (получение данных клиента по установочным данным от оператора сотовой связи)
   */
  export class MobileIDSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.MOBILE_ID_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные о пользователе полученные от оператора сотовой связи
     */
    constructor(public payload: IMobileIDDataDto) {
    }
  }


  /**
   * Действие для сброса факта подтверждения номера телефона (код из СМС)
   */
  export class ResetPhoneConfirmation {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.RESET_PHONE_CONFIRMATION;
  }

  /**
   * Действие для отправки дополнительных полей при регистрации
   */
  export class FillAdditional {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.FILL_ADDITIONAL;
  }

  /**
   * Действие для неуспешной отправки дополнительных полей при регистрации
   */
  export class FillAdditionalFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.FILL_ADDITIONAL_FAIL;

    /**
     * Тело действия
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешной отправки дополнительных полей при регистрации
   */
  export class FillAdditionalSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.FILL_ADDITIONAL_SUCCESS;
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.RESET;
  }

  /**
   * Действие для получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   */
  export class Esia {
    /**
     * Уникальное имя действия
     */
    public static readonly type = RegistrationActionList.ESIA;

    constructor(public payload: string) {
    }
  }

  /**
   * Действие для неуспешного получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   */
  export class EsiaFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type = RegistrationActionList.ESIA_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {
    }
  }

  /**
   * Действие для успешного получения данных от IDP ESIA с целью осуществления авторизации или предзаполнения формы регистрации
   * (в случае, если пользователь был ранее не зарегистрирован в системе)
   */
  export class EsiaSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type = RegistrationActionList.ESIA_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Полученые данные о пользователе от IDP ESIA
     * @param key Ключ IDP ESIA
     */
    constructor(public payload: IRegistrationApplicationDto, public key: string) { // TODO interface
    }
  }


  /**
   * Действие при указании на втором шаге регистрации гражданства "Другое"
   */
  export class RegistrationSngNationalityComplete {
    /**
     * Уникальное имя действия
     */
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_SNG_NATIONALITY_COMPLETE;
  }

  /**
   * Действие для отправки телефона для помощи
   */
  export class SendPhone {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SEND_PHONE;
  }

  /**
   * Действие для успешной отправки телефона для помощи
   */
  export class SendPhoneSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SEND_PHONE_SUCCESS;
  }

  /**
   * Действие неуспешной отправки телефона для помощи
   */
  export class SendPhoneFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: RegistrationActionList = RegistrationActionList.SEND_PHONE_FAIL;/**
    * Тело действия
    *
    * @param payload Данные об ошибке
    */
     constructor(public payload: IError[]) {
   }
 }

  /** Действие для отправки данных для прохождения УПРИД */
  export class Identify {
    public static readonly type:RegistrationActionList = RegistrationActionList.IDENTIFY;
  }

  /** Действие для успешной отправки данных для прохождения УПРИД */
  export class IdentifySuccess {
    public static readonly type:RegistrationActionList = RegistrationActionList.IDENTIFY_SUCCESS;
  }

  /** Действие для неуспешной отправки данных для прохождения УПРИД */
  export class IdentifyFail {
    public static readonly type:RegistrationActionList = RegistrationActionList.IDENTIFY_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
      constructor(public payload: IError[]) {
    }
  }
}
