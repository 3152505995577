import { IGetBalancePayload } from '../interfaces/get-balance-payload.interface';
import { IGetTransactionHistoryPayload } from '../interfaces/get-transaction-history-payload.interface';
import { BonusActionList } from './bonus-action-list.const';
import { IBalance } from '@app/states/bonus/interfaces/balance.interface';
import { ITransactionHistory } from '@app/states/bonus/interfaces/transaction-history.interface';
import { IExpiredBalance } from '@app/states/bonus/interfaces/expired-balance.interface';

/**
 * Namespace для действий состояния "Бонусы"
 */
export namespace BonusActions {
  /**
   * Действие для загрузки бонусного баланса пользователя
   */
  export class GetBalance {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_BALANCE;

    /**
     * Тело действия
     *
     * @param payload [Модель]{@link IGetBalancePayload} данных для загрузки
     */
    constructor(public payload: IGetBalancePayload) {
    }
  }

  /**
   * Действие для неуспешной загрузки бонусного баланса пользователя
   */
  export class GetBalanceFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_BALANCE_FAIL;
  }

  /**
   * Действие для успешной загрузки бонусного баланса пользователя
   */
  export class GetBalanceSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_BALANCE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные бонусного баланса пользователя
     */
    constructor(public payload: IBalance) {
    }
  }

  /**
   * Действие для загрузки информации о сгорании бонусов пользователя
   */
  export class GetUpcomingExpiration {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_UPCOMING_EXPIRATION;

    /**
     * Тело действия
     *
     * @param payload clientId - ID пользователя
     */
    constructor(public payload: { clientId: string }) {
    }
  }

  /**
   * Действие для неуспешной загрузки информации о сгорании бонусов пользователя
   */
  export class GetUpcomingExpirationFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_UPCOMING_EXPIRATION_FAIL;
  }

  /**
   * Действие для успешной загрузки информации о сгорании бонусов пользователя
   */
  export class GetUpcomingExpirationSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_UPCOMING_EXPIRATION_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные о сгорании бонусов пользователя
     */
    constructor(public payload: IExpiredBalance) {
    }
  }

  /**
   * Действие для загрузки первой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryFirstPage {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_FIRST_PAGE;

    /**
     * Тело действия
     *
     * @param payload [Модель]{@link IGetTransactionHistoryPayload} данных для загрузки
     */
    constructor(public payload: IGetTransactionHistoryPayload) {
    }
  }

  /**
   * Действие для неуспешной загрузки первой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryFirstPageFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_FIRST_PAGE_FAIL;
  }

  /**
   * Действие для успешной загрузки первой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryFirstPageSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_FIRST_PAGE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload история изменений бонусного баланса пользователя
     */
    constructor(public payload: ITransactionHistory) {
    }
  }

  /**
   * Действие для загрузки n-ой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryNextPage {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_NEXT_PAGE;

    /**
     * Тело действия
     *
     * @param payload [Модель]{@link IGetTransactionHistoryPayload} данных для загрузки
     */
    constructor(public payload: IGetTransactionHistoryPayload) {
    }
  }

  /**
   * Действие для неуспешной загрузки n-ой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryNextPageFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_NEXT_PAGE_FAIL;
  }

  /**
   * Действие для успешной загрузки n-ой страницы истории изменений бонусного баланса пользователя
   */
  export class GetTransactionHistoryNextPageSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.GET_TRANSACTION_HISTORY_NEXT_PAGE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload история изменений бонусного баланса пользователя
     */
    constructor(public payload: ITransactionHistory) {
    }
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: BonusActionList = BonusActionList.RESET;
  }
}
