import { CategoryName } from '../constants/category-name.const';
import { EventName } from '../constants/event-name.const';

export function getPartialRegistrationPayload(phoneNumber: string) {
  return {
    name: EventName.Registration,
    category: CategoryName.Registration,
    payload: { phone: phoneNumber ? '7' + phoneNumber : null },
  };
}
