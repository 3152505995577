import { StorageEngine } from '@ngxs/storage-plugin';
import { CacheStorage } from './cache-storage.class';

export class CustomStorage implements StorageEngine {
  private storage: StorageEngine;

  constructor() {
    try {
      localStorage.setItem('storage_test', '');
      localStorage.removeItem('storage_test');
      this.storage = localStorage;
    } catch (err) {
      try {
        sessionStorage.setItem('storage_test', '');
        sessionStorage.removeItem('storage_test');
        this.storage = sessionStorage;
      } catch {
        this.storage = new CacheStorage();
      }
    }
  }

  public getItem(key: string): any {
    return this.storage.getItem(key);
  }

  public setItem(key: string, val: any): void {
    this.storage.setItem(key, val);
  }
}
