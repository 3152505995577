import { EventsActionList } from '@app/states/events/states/events-action-list.const';
import { GaEvent as GaEventType } from '@app/states/events/constants/ga-event.const';
import { MindboxOperation } from '@app/states/events/constants/mindbox-operation.const';
import { ICustomerPayload } from '@app/states/events/interfaces/mindbox/customer-payload.interface';
import { IEventPayload } from '@app/states/events/interfaces/event-payload.interface';
import { MetricType } from '../constants/metric-type.const';
import { EventName } from '../constants/event-name.const';

export namespace EventsActions {
  export class NewSession {
    public static readonly type: EventsActionList = EventsActionList.NEW_SESSION;
  }

  export class NewUser {
    public static readonly type: EventsActionList = EventsActionList.NEW_USER;
  }

  export class FieldEvent {
    public static readonly type: EventsActionList = EventsActionList.FIELD_EVENT;

    constructor(public payload: any) {
    }
  }

  export class ForwardLK {
    public static readonly type: EventsActionList = EventsActionList.FORWARD_LK;
  }

  export class Event {
    public static readonly type: EventsActionList = EventsActionList.EVENT;

    constructor(public payload: Partial<IEventPayload>) {
    }
  }

  export class GaEvent {
    public static readonly type: EventsActionList = EventsActionList.GA_EVENT;

    constructor(public payload: GaEventType) {
    }
  }

  export class Mindbox {
    public static readonly type: EventsActionList = EventsActionList.MINDBOX;

    constructor(public type: MindboxOperation, public payload: ICustomerPayload) {
    }
  }

  export class AddMetric {
    public static readonly type: EventsActionList = EventsActionList.ADD_METRIC;

    constructor(public labels: Partial<{ status_code: number; action: MetricType; type: string }>, public name?: EventName) {
    }
  }

  export class RegistrationStepOneComplete {
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_STEP_ONE_COMPLETE;
  }

  export class RegistrationStepConfirmationComplete {
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_STEP_CONFIRMATION_COMPLETE;
  }

  export class PhoneConfirmed {
    public static readonly type: EventsActionList = EventsActionList.PHONE_CONFIRMED;

    constructor(public phone: string) {
    }
  }

  export class RegistrationStepTwoComplete {
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_STEP_TWO_COMPLETE;
  }

  export class RegistrationStepThreeComplete {
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_STEP_THREE_COMPLETE;
  }

  export class RegistrationStepFourComplete {
    public static readonly type: EventsActionList = EventsActionList.REGISTRATION_STEP_FOUR_COMPLETE;

    constructor(public loanId: string) {
    }
  }

  export class SetWebbankirCrossId {
    public static readonly type: EventsActionList = EventsActionList.SET_WEBBANKIR_CROSS_ID;

    constructor(public webbankirId: string = '') {
    }
  }

  export class RegisterYandexUserId {
    public static readonly type: EventsActionList = EventsActionList.REGISTER_YANDEX_USER_ID;
  }

  export class EventV2 {
    public static readonly type: EventsActionList = EventsActionList.EVENT_V2;

    constructor(public payload: Partial<IEventPayload>, public isNotCancelled: boolean = false) {
    }
  }
}
