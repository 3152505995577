import { LoanStatus } from "@app/constants/loan/loan-status.const";
import { IProduct } from "../interfaces/product.interface";
import { ProductStatus } from "../constants/product-status.const";

export function pdlStatusMap(status: LoanStatus): IProduct['status'] {
    switch (status) {
      case LoanStatus.Waiting:
        return ProductStatus.Waiting;
      case LoanStatus.Analyze:
        return ProductStatus.Analyze;
      case LoanStatus.Approved:
        return ProductStatus.Signing;
      case LoanStatus.Signed:
        return ProductStatus.Signed;
      case LoanStatus.Payment:
        return ProductStatus.Payment;
      case LoanStatus.Issued:
        return ProductStatus.Issued;
      case LoanStatus.Closed:
        return ProductStatus.Closed;
      case LoanStatus.AddInfoRequired:
        return ProductStatus.AddInfoRequired;
      case LoanStatus.IdRequired:
        return ProductStatus.IdRequired;
      case LoanStatus.IdRefused:
        return ProductStatus.IdRefused;
      case LoanStatus.Rejected:
        return ProductStatus.Rejected;
      case LoanStatus.Revoked:
        return ProductStatus.Revoked;
      case LoanStatus.Expired:
        return ProductStatus.Expired;
      case LoanStatus.Refinancing:
        return ProductStatus.Refinancing;
      case LoanStatus.FailedCardCheck:
        return ProductStatus.FailedCardCheck;
      case LoanStatus.FailedRefinancing:
        return ProductStatus.FailedRefinancing;
    }
  }
  