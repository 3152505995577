import { IAccount } from '@app/interfaces/account.interface';
import { IError } from '@app/interfaces/error.interface';
import { ICardBank } from '@app/states/accounts/interfaces/card-bank.interface';
import { IInteractivePaymentResponse } from '@app/states/product/interfaces/interactive-payment-response.interface';
import { ISelectItem } from '@web-bankir/ui-kit/components/controls/select';
import { IAccountAddSMSResponse } from '../interfaces/account-add-sms-response.interface';
import { AccountsActionList } from './accounts-action-list.const';

/**
 * Namespace для действий состояния "Счета"
 */
export namespace AccountsActions {
  /**
   * Действие для загрузки счетов пользователя
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.LOAD;

    /**
     * Тело действия
     *
     * @param needRepeatRequest Флаг повторной загрузки аккаунтов
     */
    constructor(public needRepeatRequest: boolean = false) {}
  }

  /**
   * Действие для успешной загрузки счетов пользователя
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.LOAD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные счетов пользователя
     */
    constructor(public payload: IAccount[]) {}
  }

  /**
   * Действие для неуспешной загрузки счетов пользователя
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.LOAD_FAILED;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для загрузки списка банков
   */
  export class Banks {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BANKS;
  }

  /**
   * Действие для неуспешной загрузки списка банков
   */
  export class BanksFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BANKS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной загрузки списка банков
   */
  export class BanksSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BANKS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные банков
     */
    constructor(public payload: ISelectItem[]) {}
  }

  /**
   * Действие для загрузки данных банка
   */
  export class CardBank {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CARD_BANK;

    /**
     * Тело действия
     *
     * @param payload Данные первых 6 цифр номера карты
     */
    constructor(public payload: string) {}
  }

  /**
   * Действие для неуспешной загрузки данных банка
   */
  export class CardBankFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CARD_BANK_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной загрузки данных банка
   */
  export class CardBankSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CARD_BANK_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные банка
     */
    constructor(public payload: ICardBank) {}
  }

  /**
   * Действие для проверки карты пользователя
   */
  export class CheckCard {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CHECK_CARD;
  }

  /**
   * Действие для неуспешной проверки карты пользователя
   */
  export class CheckCardFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CHECK_CARD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешной проверки карты пользователя
   */
  export class CheckCardSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CHECK_CARD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные карты пользователя
     */
    constructor(public payload: IAccount[]) {}
  }

  /**
   * Действие для добавления карты пользователя
   */
  export class BindCard {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BIND_CARD;

    /**
     * Тело действия
     *
     * @param payload смс код
     */
    constructor(public payload: string = null) {}
  }

  /**
   * Действие для неуспешного добавления карты пользователя
   */
  export class BindCardFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BIND_CARD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     * @param isHandled Флаг обработки ошибки в HttpErrorInterceptor
     */
    constructor(public payload: IError[], public isHandled: boolean = false) {}
  }

  /**
   * Действие для успешного добавления карты пользователя
   */
  export class BindCardSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.BIND_CARD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные 3ds карты
     */
    constructor(public payload: IInteractivePaymentResponse) {}
  }

  export class SendCode {
    public static readonly type: AccountsActionList = AccountsActionList.SEND_CODE;

    constructor(public payload: string) {}
  }

  export class SendCodeFail {
    public static readonly type: AccountsActionList = AccountsActionList.SEND_CODE_FAIL;

    constructor(public payload: IError[], public code: number, public timeLeft: number) {}
  }

  export class SendCodeSuccess {
    public static readonly type: AccountsActionList = AccountsActionList.SEND_CODE_SUCCESS;

    constructor(public payload: IAccountAddSMSResponse) {}
  }

  /**
   * Действие для добавления способа оплаты пользователя
   */
  export class Create {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CREATE;

    constructor(public payload?: string) {}
  }

  /**
   * Действие для неуспешного добавления способа оплаты пользователя
   */
  export class CreateFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CREATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для успешного добавления способа оплаты пользователя
   */
  export class CreateSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.CREATE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные способа оплаты пользователя
     */
    constructor(public payload: IAccount) {}
  }

  /**
   * Действие для удаления способа оплаты пользователя
   */
  export class Delete {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.DELETE;

    /**
     * Тело действия
     *
     * @param payload ID способа оплаты
     * @param notify Выводить ли информационное сообщение после удаления способа оплаты
     * @param isResetForm Очищать ли форму после удаления способа оплаты
     */
    constructor(public payload: number, public notify: boolean = true, public isResetForm: boolean = true) {}
  }

  /**
   * Действие для успешного удаления способа оплаты пользователя
   */
  export class DeleteSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.DELETE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload ID способа оплаты
     * @param notify Выводить ли информационное сообщение после удаления способа оплаты
     * @param isResetForm Очищать ли форму после удаления способа оплаты
     */
    constructor(public payload: number, public notify: boolean, public isResetForm: boolean) {}
  }

  /**
   * Действие для неуспешного удаления способа оплаты пользователя
   */
  export class DeleteFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.DELETE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие для установления способа оплаты пользователя основным
   */
  export class MakeDefault {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.MAKE_DEFAULT;

    /**
     * Тело действия
     *
     * @param payload ID способа оплаты
     */
    constructor(public payload: number) {}
  }

  /**
   * Действие для успешного установления способа оплаты пользователя основным
   */
  export class MakeDefaultSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.MAKE_DEFAULT_SUCCESS;
  }

  /**
   * Действие для неуспешного установления способа оплаты пользователя основным
   */
  export class MakeDefaultFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.MAKE_DEFAULT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  export class BindOptions {
    public static readonly type: AccountsActionList = AccountsActionList.BIND_OPTIONS;
  }

  export class BindOptionsSuccess {
    public static readonly type: AccountsActionList = AccountsActionList.BIND_OPTIONS_SUCCESS;
  }

  export class BindOptionsFail {
    public static readonly type: AccountsActionList = AccountsActionList.BIND_OPTIONS_FAIL;
    constructor(public payload: IError[]) {}
  }

  export class Payment3dsHandler {
    public static readonly type: AccountsActionList = AccountsActionList.PAYMENT_3DS_HANDLER;
    constructor(public payload: IInteractivePaymentResponse, public callback: any) {}
  }

  /**
   * Действие для сброса состояния формы к значению по умолчанию
   */
  export class ResetForm {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.RESET_FORM;
  }

  export class ResetConfirmation {
    public static readonly type: AccountsActionList = AccountsActionList.RESET_CONFIRMATION;
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.RESET;
  }

  /**
   * Действие
   */
  export class WatchSmsCode {
    /**
     * Уникальное имя действия
     */
    public static readonly type: AccountsActionList = AccountsActionList.WATCH_SMS_CODE;
    constructor(public payload: SendCodeFail | SendCodeSuccess) {}
  }
}
