/**
 * Справочник действий состояния "Обращения" пользователя
 */
export enum FeedbackActionList {
  REASONS = '[Feedback] reasons',
  REASONS_FAIL = '[Feedback] reasons fail',
  REASONS_SUCCESS = '[Feedback] reasons success',

  CREATE = '[Feedback] create request',
  CREATE_FAIL = '[Feedback] create request fail',
  CREATE_SUCCESS = '[Feedback] create request success',
}
