import { StorageEngine } from '@ngxs/storage-plugin';

export class CacheStorage implements StorageEngine {
  private storage = new Map();

  public getItem(key: string): any {
    return this.storage.get(key);
  }

  public setItem(key: string, val: any): void {
    this.storage.set(key, val);
  }
}
