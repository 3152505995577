import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BRAND_WEBBANKIR } from '@app/constants/brand-webbankir.const';
import { ConsentPackage } from '@app/constants/consent-package.const';
import { IConsentDto } from '@app/interfaces/consent-dto.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IFingerPrint } from '@app/interfaces/finger-print.interface';
import { IProlongation } from '@app/interfaces/loan/prolongation.interface';
import { Product } from '@app/states/calculator/constants/product.const';
import { ICommissionResponse } from '@app/states/product/interfaces/commission-response.interface';
import { IContractDto } from '@app/states/product/interfaces/contract-dto.interface';
import { IDisclaim } from '@app/states/product/interfaces/disclaim.interface';
import { IFileUploadDto } from '@app/states/product/interfaces/file-upload-dto.interface';
import { IILSDto } from '@app/states/product/interfaces/ils-dto.interface';
import { IInteractivePaymentDto } from '@app/states/product/interfaces/interactive-payment-dto.interface';
import { IInteractivePaymentResponse } from '@app/states/product/interfaces/interactive-payment-response.interface';
import { IPaymentMethod } from '@app/states/product/interfaces/payment-method.interface';
import { IProductDTO } from '@app/states/product/interfaces/product-dto.interface';
import { IProductPatchDTO } from '@app/states/product/interfaces/product-patch-dto.interface';
import { ISberbankPaymentDto } from '@app/states/product/interfaces/sberbank-payment-dto.interface';
import { ISberbankPaymentResponse } from '@app/states/product/interfaces/sberbank-payment-response.interface';
import { ISbpLinkPaymentDto } from '@app/states/product/interfaces/sbp-link-payment-dto.interface';
import { ISbpLinkPaymentResponse } from '@app/states/product/interfaces/sbp-link-payment-response.interface';
import { IYoomoneyPaymentDto } from '@app/states/product/interfaces/yoomoney-payment-dto.interface';
import { IYoomoneyPaymentResponse } from '@app/states/product/interfaces/yoomoney-payment-response.interface';
import { IClosedCount } from '@app/states/registration/interfaces/loan-closed.interface';
import { ILoanDto } from '@app/states/registration/interfaces/loan-dto.interface';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IVirtualCardPaymentDto } from '../interfaces/virtual-card-payment-dto.interface';
import { IVirtualCardPaymentResponse } from '../interfaces/virtual-card-payment-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  public load(): Observable<IDataResponse<IProductDTO[]>> {
    return this.http.get<IDataResponse<IProductDTO[]>>(
      `${this.environment.api}middleware/v1/loans?filter[state]=active&page[limit]=2`
    );
  }

  public count(onlyClosed): Observable<IDataResponse<IClosedCount>> {
    return this.http.get<IDataResponse<IClosedCount>>(`${this.environment.api}loan/get/count`, {
      params: { only_closed: onlyClosed ? 1 : undefined },
    });
  }

  public prolongation(): Observable<IDataResponse<IProlongation>> {
    return this.http.get<IDataResponse<IProlongation>>(`${this.environment.api}supplementary/prolongation/calc`);
  }

  public prolongationCreate(daysDuration: number, attributes: IFingerPrint, code: string): Observable<void> {
    return this.http.post<void>(`${this.environment.api}supplementary/prolongation/create`, {
      daysDuration,
      code,
      validFor: 'prolongationContract',
      ...attributes,
    });
  }

  public prolongationSignResendCode(): Observable<void> {
    return this.http.get<void>(`${this.environment.api}supplementary/prolongation/sign/resend`);
  }

  public sign(method: string, data: any, isIls: boolean): Observable<void> {
    // TODO: Убрать, когда на бэке добавят проверку типа данных
    const headers = new HttpHeaders().set('Content-Type', 'application/vnd.api+json');
    return this.http.post<void>(`${this.environment.api}${method}`, data, isIls ? { headers } : {});
  }

  public resendCodePDL(method: string): Observable<void> {
    return this.http.get<void>(`${this.environment.api}${method}/sign/resend`);
  }

  public resendCodeILS(id: string): Observable<void> {
    return this.http.post<void>(`${this.environment.api}ils/v1/contracts/${id}:send-signature`, {});
  }

  public installmentPdf(id: string, installmentId: string): Observable<Blob> {
    return this.http.get(`${this.environment.api}documents/supplementary/pdf/${id}/${installmentId}`, {
      responseType: 'blob',
    });
  }

  public disclaim(): Observable<IDataResponse<IDisclaim>> {
    return this.http.get<IDataResponse<IDisclaim>>(`${this.environment.api}loan/disclaim_link`);
  }

  public consents(
    type: ConsentPackage,
    attributes: IFingerPrint,
    product: Product
  ): Observable<IDataResponse<IConsentDto[]>> {
    const url = `${this.environment.api}${
      product === Product.ILS ? 'ils/v1' : type === ConsentPackage.SigningContract ? 'loan/v2' : 'user/v2'
    }/consents/packages/${type}`;
    return this.http.post<IDataResponse<IConsentDto[]>>(url, {
      data: {
        type: 'ConsentsRequest',
        attributes: {
          brand: BRAND_WEBBANKIR,
          ...attributes,
        },
      },
    });
  }

  public prolongationConsents(
    type: ConsentPackage,
    attributes: IFingerPrint,
    loanSum: number = null,
    loanType: Product = Product.PDL,
  ): Observable<IDataResponse<IConsentDto[]>> {
    const url = type === ConsentPackage.SigningRefinancing && loanType === Product.ILS ? 'ils/v1' : 'loan/v2';
    return this.http.post<IDataResponse<IConsentDto[]>>(`${this.environment.api}${url}/consents/packages/${type}`, {
      data: {
        type: 'ConsentsRequest',
        attributes: {
          brand: BRAND_WEBBANKIR,
          ...attributes,
          ...(loanSum && {
            additionalParams: [
              {
                type: 'loanSum',
                value: `${loanSum}`,
              },
            ],
          }),
          ...(loanSum && { loanSum }),
        },
      },
    });
  }

  public ilsCreate(attributes: IILSDto): Observable<IDataResponse<IProductDTO>> {
    return this.http.post<IDataResponse<IProductDTO>>(`${this.environment.api}ils/v1/applications`, {
      data: {
        type: 'Application',
        attributes,
      },
    });
  }

  public pdlCreate(data: ILoanDto): Observable<HttpResponse<IDataResponse<IProductDTO>>> {
    return this.http.post<IDataResponse<IProductDTO>>(`${this.environment.api}loan/v2/create`, data, {
      observe: 'response',
    });
  }

  public uploadPhoto(id: string, data: Partial<IFileUploadDto>): Observable<HttpResponse<void>> {
    const form = new FormData();

    for (const key in data) {
      form.append(key, data[key]);
    }
    return this.http.post<void>(`${this.environment.api}loan/requests/${id}/files`, form, { observe: 'response' });
  }

  public uploadPhotoILS(id: string, data: Partial<IFileUploadDto>): Observable<void> {
    const form = new FormData();

    form.append('client', data.photo);
    form.append('passportMain', data.passport);

    return this.http.post<void>(`${this.environment.api}ils/v1/applications/${id}/verification/photos`, form);
  }

  public submitWithoutPhoto(id: string, data: IProductPatchDTO): Observable<void> {
    return this.http.patch<void>(`${this.environment.api}loan/requests/${id}`, {
      data,
    });
  }

  public revokeIls(id: string): Observable<void> {
    return this.http.post<void>(`${this.environment.api}ils/v1/applications/${id}:revoke`, {});
  }

  public pdf(method: string): Observable<Blob> {
    return this.http.get(`${method}`, {
      responseType: 'blob',
    });
  }

  public getContract(): Observable<IContractDto> {
    return this.http.get<IContractDto>(
      `${this.environment.api}contracts/v1/contracts?filter[state]=sold&extra=stateInfo`
    );
  }

  public paymentMethods(binding = false, group: string = undefined): Observable<IDataResponse<IPaymentMethod[]>> {
    return this.http.get<IDataResponse<IPaymentMethod[]>>(`${this.environment.api}payment/v2/methods`, {
      params: {
        ...(binding ? { actions: 'binding' } : {}),
        ...(group ? { group } : {}),
      },
    });
  }

  public paymentCommission(amount: number, service: string): Observable<ICommissionResponse> {
    return this.http.put<ICommissionResponse>(`${this.environment.api}payment/v2/payments/${service}/commission`, {
      amount,
    });
  }

  public paymentInteractive(
    data: Partial<IInteractivePaymentDto>
  ): Observable<IDataResponse<IInteractivePaymentResponse>> {
    return this.http.post<IDataResponse<IInteractivePaymentResponse>>(
      `${this.environment.api}payment/v3/payments/card/interactive`,
      { data }
    );
  }

  public paymentSberbank(data: ISberbankPaymentDto): Observable<IDataResponse<ISberbankPaymentResponse>> {
    return this.http.post<IDataResponse<ISberbankPaymentResponse>>(
      `${this.environment.api}payment/v2/create/sberbank`,
      data
    );
  }

  public paymentYoomoney(data: IYoomoneyPaymentDto): Observable<IDataResponse<IYoomoneyPaymentResponse>> {
    return this.http.post<IDataResponse<IYoomoneyPaymentResponse>>(
      `${this.environment.api}payment/v2/create/yandex`,
      data
    );
  }

  public paymentRussianPost(url: string): Observable<IDataResponse<Blob>> {
    return this.http.get(url, { responseType: 'blob' }).pipe(map((data) => ({ data })));
  }

  public async paymentSbpLink(data: ISbpLinkPaymentDto): Promise<IDataResponse<ISbpLinkPaymentResponse>> {
    const linkResponse = await lastValueFrom(
      this.http.post<IDataResponse<any>>(`${this.environment.api}payment/v2/sbp/link`, { data })
    );
    const linkData: ISbpLinkPaymentResponse = { ...linkResponse.data, ...linkResponse.data.attributes };
    return { data: linkData };
  }

  public paymentVirtualCard(data: IVirtualCardPaymentDto): Observable<IDataResponse<IVirtualCardPaymentResponse>> {
    return this.http.post<IDataResponse<IVirtualCardPaymentResponse>>(
      `${this.environment.api}payment/v2/virtual-card`,
      { data }
    );
  }

  public facts(): Observable<string[]> {
    return this.http.get<string[]>('https://static.webbankir.com/api/tutorial_notes.json');
  }

  public prolongationPdf(link: string) {
    return this.http.get(link, { responseType: 'blob' });
  }

  public prolongationSign(smsCode: string): Observable<void> {
    return this.http.post<void>(`${this.environment.api}supplementary/prolongation/sign`, { smsCode });
  }

  public prolongationDocument(id: string): Observable<Blob> {
    return this.http.get(`${this.environment.api}documents/supplementary/pdf/${id}/last`, {
      responseType: 'blob',
    });
  }
}
