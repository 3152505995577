/**
 * Справочник действий состояния
 */
export enum RegistrationActionList {
  COMPLETE = '[Registration] registration complete',

  INIT_SESSION = '[Registration] init session id',
  INIT_SESSION_FAIL = '[Registration] init session id',
  INIT_SESSION_SUCCESS = '[Registration] init session id',

  FILL = '[Registration] fill form',

  CONSENTS = '[Registration] consents',
  CONSENTS_FAIL = '[Registration] consents fail',
  CONSENTS_SUCCESS = '[Registration] consents success',

  SUBMIT_STEP_ONE = '[Registration] submit step one',
  SUBMIT_STEP_ONE_FAIL = '[Registration] submit step one fail',
  SUBMIT_STEP_ONE_SUCCESS = '[Registration] submit step one success',

  VERIFY = '[Registration] verify',
  VERIFY_FAIL = '[Registration] verify fail',
  VERIFY_SUCCESS = '[Registration] verify success',

  SUGGESTION = '[Registration] suggestion',
  SUGGESTION_FAIL = '[Registration] suggestion fail',
  SUGGESTION_SUCCESS = '[Registration] suggestion success',

  BUILD_FIO = '[Registration] build fio',
  BUILD_FIO_FAIL = '[Registration] build fio fail',
  BUILD_FIO_SUCCESS = '[Registration] build fio success',

  SUBMIT_STEP_TWO = '[Registration] submit step two',
  SUBMIT_STEP_TWO_FAIL = '[Registration] submit step two fail',
  SUBMIT_STEP_TWO_SUCCESS = '[Registration] submit step two success',

  INN = '[Registration] inn',
  INN_FAIL = '[Registration] inn fail',
  INN_SUCCESS = '[Registration] inn success',


  SUBMIT_STEP_THREE = '[Registration] submit step three',
  SUBMIT_STEP_THREE_FAIL = '[Registration] submit step three fail',
  SUBMIT_STEP_THREE_SUCCESS = '[Registration] submit step three success',

  SMS_CODE = '[Registration] sms code',
  SMS_CODE_FAIL = '[Registration] sms code fail',
  SMS_CODE_SUCCESS = '[Registration] sms code success',

  CHANGE_PHONE = '[Registration] change phone',
  CHANGE_PHONE_FAIL = '[Registration] change phone fail',
  CHANGE_PHONE_SUCCESS = '[Registration] change phone success',

  CONFIRM_PHONE = '[Registration] confirm phone',
  CONFIRM_PHONE_FAIL = '[Registration] confirm phone fail',
  CONFIRM_PHONE_SUCCESS = '[Registration] confirm phone success',
  RESET_PHONE_CONFIRMATION = '[Registration] Reset phone confirmation',

  MOBILE_ID = '[Registration] getting mobile ID',
  MOBILE_ID_FAIL = '[Registration] getting mobile ID fail',
  MOBILE_ID_SUCCESS = '[Registration] getting mobile ID success',
  FILL_ADDITIONAL = '[Registration] fill additional',
  FILL_ADDITIONAL_FAIL = '[Registration] fill additional fail',
  FILL_ADDITIONAL_SUCCESS = '[Registration] fill additional success',

  LOAN_CREATE = '[Registration] loan create',
  LOAN_CREATE_FAIL = '[Registration] loan create fail',
  LOAN_CREATE_SUCCESS = '[Registration] loan create success',

  RESET = '[Registration] reset',

  ESIA = '[Registration] esia',
  ESIA_SUCCESS = '[Registration] esia success',
  ESIA_FAIL = '[Registration] esia fail',
  SEND_PHONE = '[Registration] send phone',
  SEND_PHONE_SUCCESS = '[Registration] send phone success',
  SEND_PHONE_FAIL = '[Registration] send phone fail',

  IDENTIFY = '[Registration] identify',
  IDENTIFY_SUCCESS = '[Registration] identify success',
  IDENTIFY_FAIL = '[Registration] identify fail',
}
