/**
 * Справочник действий состояния "История займов"
 */
export enum LoansHistoryActionList {
  LOAD = '[LoansHistory] load',
  LOAD_SUCCESS = '[LoansHistory] load success',
  LOAD_FAIL = '[LoansHistory] load failed',

  LOAD_DOCUMENTS = '[LoansHistory] Load documents',
  LOAD_DOCUMENTS_FAIL = '[LoansHistory] Load documents fail',
  LOAD_DOCUMENTS_SUCCESS = '[LoansHistory] Load documents success',


  OPEN_DOCUMENT = '[LoansHistory] open document',
  OPEN_DOCUMENT_FAIL = '[LoansHistory] open document fail',
  OPEN_DOCUMENT_SUCCESS = '[LoansHistory] open document success',


  LOAD_STATEMENTS = '[LoansHistory] Load statements',
  LOAD_STATEMENTS_FAIL = '[LoansHistory] Load statements fail',
  LOAD_STATEMENTS_SUCCESS = '[LoansHistory] Load statements success',

  APPLY_STATEMENT = '[LoansHistory] Apply statement',
  APPLY_STATEMENT_FAIL = '[LoansHistory] Apply statement fail',
  APPLY_STATEMENT_SUCCESS = '[LoansHistory] Apply statement success',
  RESET = '[LoansHistory] reset',
}
