import { Inject, Injectable } from '@angular/core';
import { AccountType } from '@app/constants/account-type.const';
import { BRAND_WEBBANKIR } from '@app/constants/brand-webbankir.const';
import { ConsentPackage } from '@app/constants/consent-package.const';
import { LoanCreditHolidayStatus } from '@app/constants/loan/loan-credit-holiday.const';
import { LoanInstallmentStatus } from '@app/constants/loan/loan-installment-status.const';
import { ProlongationCalcState } from '@app/constants/loan/prolongation-calc-state.const';
import { ProlongationStatus } from '@app/constants/loan/prolongation-status.const';
import { RequestStatus } from '@app/constants/request-status.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { AnalyticService } from '@app/services/analytic/analytic.service';
import { ConsentService } from '@app/services/consent/consent.service';
import { CookieService } from '@app/services/cookie/cookie.service';
import { DeviceInfoService } from '@app/services/device-info/device-info.service';
import { JuicyscoreService } from '@app/services/juicyscore/juicyscore.service';
import { AuthState } from '@app/states/auth/states/auth.state';
import { Product } from '@app/states/calculator/constants/product.const';
import { MetricType } from '@app/states/events/constants/metric-type.const';
import { EventsActions } from '@app/states/events/states/events.actions';
import { ExperimentsActions } from '@app/states/experiments/states/experiments.actions';
import { ModalActions } from '@app/states/modal/states/modal.actions';
import { CardPaymentErrorCode } from '@app/states/product/constants/card-payment-error-code.const';
import { ICreateLoan } from '@app/states/product/interfaces/create-loan.interface';
import { ProductService } from '@app/states/product/services/product.service';
import { PRODUCT_STATE_DEFAULTS } from '@app/states/product/states/product-state-defaults.const';
import { IProductState } from '@app/states/product/states/product-state.interface';
import { ProductActions } from '@app/states/product/states/product.actions';
import { IProfileState } from '@app/states/profile/states/profile-state.interface';
import { ProfileState } from '@app/states/profile/states/profile.state';
import { ResetForm, UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { BadgeStatus } from '@web-bankir/ui-kit/components/badge';
import { ISelectItem } from '@web-bankir/ui-kit/components/controls/select';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Metrika } from 'ng-yandex-metrika';
import { combineLatest, forkJoin, of, tap, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PAYMENT_METHOD_TITLE_MAP } from '../constants/payment-method-title-map.const';
import { PaymentMethod } from '../constants/payment-method.const';
import { ProductStatus } from '../constants/product-status.const';
import { ProductType } from '../constants/product-type.const';
import { productMap } from '../helpers/product-map.helper';
import { IILSContractDTO } from '../interfaces/ils-contract-dto.interface';
import { IPaymentMethod } from '../interfaces/payment-method.interface';
import { IPDLDTO } from '../interfaces/pdl-dto.interface';

dayjs.extend(utc);

/**
 * Класс NGXS состояния "Заём"
 */
@State<IProductState>({
  name: 'Product',
  defaults: PRODUCT_STATE_DEFAULTS,
})
@Injectable()
export class ProductState extends StateErrorHandler {
  /**
   * Конструктор класса состояния займа
   *
   * @param service [Сервис]{@link ProductService} займа пользователя
   * @param deviceInfoService [Сервис]{@link DeviceInfoService} информации о девайсе
   * @param store NGXS{@link Store} хранилище
   * @param cookie [Сервис]{@link CookieService} для работы с cookies
   * @param window [Интерфейс]{@link Window} для работы с окном браузера и DOM
   * @param yaMetrica [Класс]{@link Metrika} библиотеки Яндекс Метрика
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   * @param consentsService [Сервис]{@link ConsentService} преоброзования данных
   * @param juicyScore [Сервис]{@link JuicyscoreService} JuicyScore (риск аналитика)
   */
  constructor(
    private service: ProductService,
    private deviceInfoService: DeviceInfoService,
    protected store: Store,
    private cookie: CookieService,
    @Inject('Window') private window: Window,
    protected toast: ToastService,
    private consentsService: ConsentService,
    private juicyScore: JuicyscoreService,
    private analyticService: AnalyticService
  ) {
    super(toast, store);
  }

  public static product(id: string) {
    return createSelector(
      [ProductState],
      (state: IProductState) => {
        return state.data?.find((item) => item.id == id);
      },
      {
        containerClass: 'Product',
        selectorName: `product_${id}`,
      }
    );
  }

  @Selector()
  public static form(state: IProductState): ICreateLoan {
    return state?.newLoan?.model;
  }

  /**
   * Селектор, возвращающий информацию является ли статус займа подписанным
   * @param state состояние займа пользователя
   */
  @Selector()
  public static isAccountEditable(state: IProductState): boolean {
    return !!!state?.data?.find((item) => item?.status === ProductStatus.Signed);
  }

  @Selector()
  public static isTwoIssuedLoans(state: IProductState): boolean {
    return state?.data?.filter((item) => item?.status === ProductStatus.Issued)?.length === 2;
  }

  @Selector()
  public static isAbleToDelete(state: IProductState): boolean {
    return state?.isAbleToDelete;
  }

  public static selected(id: string) {
    return createSelector([ProductState], (state: IProductState) => {
      return state.data?.find((i) => i.id === id);
    });
  }

  public static status(id: string) {
    return createSelector(
      [ProductState, ProfileState],
      (state: IProductState, profile: IProfileState) => {
        const product = state.data.find((item) => item.id === id);
        switch (product?.status) {
          case ProductStatus.Waiting:
            return {
              color: BadgeStatus.ACCENTS_SECONDARY_400,
              text: 'Ожидает рассмотрения',
            };
          case ProductStatus.Analyze:
            return {
              color: BadgeStatus.ACCENTS_SECONDARY_400,
              text: 'Рассматривается',
            };
          case ProductStatus.Signing:
            return {
              color: BadgeStatus.ACCENTS_SECONDARY,
              text: 'Подписание',
            };
          case ProductStatus.Signed:
          case ProductStatus.Payment:
            return {
              color: BadgeStatus.SUCCESS,
              text: 'Зачисление денег',
            };
          case ProductStatus.Rejected:
            return {
              color: BadgeStatus.ERROR,
              text: 'Заявка отклонена',
            };
          case ProductStatus.Revoked:
            return {
              color: BadgeStatus.ERROR,
              text: 'Клиент отказался',
            };
          case ProductStatus.Expired:
            return {
              color: BadgeStatus.ERROR,
              text: 'Истек срок подписания',
            };
          case ProductStatus.Closed:
            if (product?.isSold) {
              return {
                color: BadgeStatus.GRAY,
                text: 'Договор продан',
              };
            } else if (profile?.info?.sold) {
              return {
                color: BadgeStatus.GRAY,
                text: 'Договор продан',
              };
            }
            return {
              color: BadgeStatus.GRAY,
              text: 'Закрыт',
            };
          case ProductStatus.AddInfoRequired:
          case ProductStatus.IdRequired:
            return {
              color: BadgeStatus.ACCENTS_SECONDARY,
              text: `Требуется идентификация`,
            };
          default:
            if (profile?.info?.sold) {
              return {
                color: BadgeStatus.GRAY,
                text: 'Договор продан',
              };
            }

            if (profile?.info?.blocked && !product) {
              return {
                color: BadgeStatus.GRAY,
                text: 'Отказано',
              };
            }

            if (product?.supplementary?.prolongation?.status === ProlongationStatus.WaitingPayment) {
              return {
                color: BadgeStatus.ACCENTS_SECONDARY,
                text: `Активация пролонгации`,
              };
            }

            if (product?.supplementary?.installment?.status === LoanInstallmentStatus.Payment) {
              return {
                color: BadgeStatus.ACCENTS_SECONDARY,
                text: `Оформление рассрочки`,
              };
            }

            if (product?.supplementary?.installment?.status === LoanInstallmentStatus.Active) {
              return {
                color: BadgeStatus.SUCCESS,
                text: `Рассрочка активна`,
              };
            }

            if (
              [LoanCreditHolidayStatus.PreActive, LoanCreditHolidayStatus.Active].includes(
                product?.supplementary?.holiday?.status
              )
            ) {
              return {
                color: BadgeStatus.SUCCESS,
                text: `Кредитные каникулы`,
              };
            }

            if (product?.overdue && product?.overdue.delay > 0) {
              return {
                color: BadgeStatus.ERROR,
                text: `Просрочен`,
              };
            }

            return {
              color: BadgeStatus.SUCCESS,
              text: `Активный`,
            };
        }
      },
      {
        containerClass: 'Product',
        selectorName: `product_${id}_status`,
      }
    );
  }

  /**
   * Селектор, возвращающий способ оплаты картой
   * @param state состояние займа пользователя
   */
  @Selector()
  public static creditCardPaymentMethod(state: IProductState): ISelectItem<IPaymentMethod> | undefined {
    return state.paymentMethods.find((f) => f.value.id === PaymentMethod.CreditCard);
  }

  /**
   * Селектор, возвращающий информацию о возможности пролонгации займа
   * @param state состояние займа пользователя
   */
  @Selector()
  public static canProlongate(state: IProductState): boolean {
    return [
      ProlongationCalcState.CanExtend,
      ProlongationCalcState.WaitingSigning,
      ProlongationCalcState.WaitingPayment,
    ].includes(state.prolongation?.state);
  }

  /**
   * Селектор, возвращающий информацию находится ли пользователь на кредитных каникулах
   * @param state состояние займа пользователя
   */
  public static isCreditHoliday(id: string) {
    return createSelector(
      [ProductState],
      (state: IProductState) => {
        return [LoanCreditHolidayStatus.PreActive, LoanCreditHolidayStatus.Active].includes(
          state.data.find((item) => item.id === id)?.supplementary?.holiday?.status
        );
      },
      {
        containerClass: 'Product',
        selectorName: `product_${id}_isCreditHoliday`,
      }
    );
  }

  /**
   * Селектор, возвращающий цвет и текст badge для кредитных каникул
   * @param state состояние займа пользователя
   */
  public static creditHolidayStatus(id: string) {
    return createSelector(
      [ProductState],
      (state: IProductState) => {
        const product = state.data.find((item) => item.id === id);
        switch (product?.supplementary?.holiday?.status) {
          case LoanCreditHolidayStatus.PreActive:
          case LoanCreditHolidayStatus.Active:
            return {
              color: BadgeStatus.SUCCESS,
              text: 'Одобрено',
              info: product?.supplementary?.holiday?.info,
            };
        }
      },
      {
        containerClass: 'Product',
        selectorName: `product_${id}_creditHolidayStatus`,
      }
    );
  }

  /**
   * Действие для загрузки займа
   *
   * Порядок выполнения:
   * - Если в полезной нагрузке действия передан true, очистить данные хранилища, изменить статус запроса данных
   * - Запрос к бэкенду {@link ProductService#load}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#LoadSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#LoadFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Load)
  public load(ctx: StateContext<IProductState>, action: ProductActions.Load) {
    if (!action.payload) {
      ctx.patchState({
        status: RequestStatus.Pending,
        data: null,
      });
    }
    return this.service.load().pipe(
      tap((response) => ctx.dispatch(new ProductActions.LoadSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.LoadFail', err);
        return ctx.dispatch(new ProductActions.LoadFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешной загрузки займа
   *
   * Порядок выполнения:
   * - Очистить данные хранилища, изменить статус запроса данных
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.LoadFail)
  public loadFail(ctx: StateContext<IProductState>, action: ProductActions.LoadFail) {
    ctx.patchState({
      status: RequestStatus.Error,
      data: null,
    });

    this.catchError(action.payload);
  }

  /**
   * Действие для успешной загрузки займа
   *
   * Порядок выполнения:
   * - Получить [модифицированные данные]{@link productMap}
   * - Обновление в состоянии данных о займе, изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<IProductState>, action: ProductActions.LoadSuccess) {
    ctx.patchState({
      status: RequestStatus.Load,
      data: action.payload
        .reduce((data, item) => {
          data.push(productMap(item));
          return data;
        }, [])
        .map((item, index, data) => {
          const refinancedLoan = data.find((i) => i.id === item.refinancedFrom?.id);

          if (refinancedLoan) {
            item.refinancedFrom = {
              ...refinancedLoan,
              refinancedTo: undefined,
              refinancedFrom: undefined,
            };

            refinancedLoan.refinancedTo = {
              ...item,
              refinancedFrom: undefined,
              refinancedTo: undefined,
            };
          }

          return item;
        })
        .filter(
          (item, index, arr) => index === 0 || (item.type === Product.PDL && item.status === ProductStatus.Issued)
        ),
      isAbleToDelete: !action.payload?.length,
    });

    const loan = action.payload?.[0];

    ctx.dispatch(
      new ExperimentsActions.UpdateAttributes({
        activeHoliday: !!loan?.attributes?.supplementary?.holiday,
        activeAmnesty: !!loan?.attributes?.supplementary?.amnesty,
        activeInstallnesty: !!loan?.attributes?.supplementary?.installnesty,
        activeInstallment: !!loan?.attributes?.supplementary?.installment,
        activeFinProtection: !!loan?.attributes?.supplementary?.finProtection,
        two_loans: !!(ctx.getState().data?.length === 2),
        daysDelay:
          loan?.type === ProductType.PDL
            ? (loan?.attributes as IPDLDTO)?.daysDelay
            : (loan?.attributes as IILSContractDTO)?.arrears?.delay,
        loanType: loan ? (loan?.type === ProductType.PDL ? Product.PDL : Product.ILS) : null,
      })
    );
  }

  /**
   * Действие получении информации о количестве займов
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#count}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#LoadCountSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#LoadCountFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.LoadCount)
  public loadCount(ctx: StateContext<IProductState>, action: ProductActions.LoadCount) {
    return this.service.count(action.payload).pipe(
      tap((count) => ctx.dispatch(new ProductActions.LoadCountSuccess(count.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.LoadCountFail', err);
        return ctx.dispatch(new ProductActions.LoadCountFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки количества займов
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о количестве займов
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.LoadCountSuccess)
  public loadCountSuccess(ctx: StateContext<IProductState>, action: ProductActions.LoadCountSuccess) {
    ctx.patchState({
      closedCount: action.payload,
    });

    ctx.dispatch(
      new ExperimentsActions.UpdateAttributes({
        repeatedClient: action.payload.count_loans >= 1,
      })
    );
  }

  /**
   * Действие для загрузки информации о пролонгации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongation}
   * - В случае успешного ответа {@link ProductService#prolongation}, записываем данные prolongation в состояние.
   * - Если значение поля state равно ProlongationCalcState.CanExtend, делаем запрос {@link ProductService#prolongationConsents}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#ProlongationSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Prolongation)
  public prolongation(ctx: StateContext<IProductState>, action: ProductActions.Prolongation) {
    return this.service.prolongation().pipe(
      tap((dataResponse) => ctx.dispatch(new ProductActions.ProlongationSuccess(dataResponse?.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ProlongationFail', err);
        return ctx.dispatch(new ProductActions.ProlongationFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки информации о пролонгации
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о пролонгации
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSuccess)
  public prolongationSuccess(ctx: StateContext<IProductState>, action: ProductActions.ProlongationSuccess) {
    ctx.patchState({ prolongation: action.payload });

    ctx.dispatch(
      new ExperimentsActions.UpdateAttributes({
        prolongationAvailable: action.payload.available,
        refinancingAvailable: !!action.payload.refinancing,
        additionalLoanAvailable: !!action.payload.additionalLoan,
      })
    );
  }

  /**
   * Действие для неуспешной загрузки информации о пролонгации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationFail)
  public prolongationFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationFail) {
    this.catchError(action.payload);
  }

  @Action(ProductActions.ProlongationConsents)
  public prolongationConsents(ctx: StateContext<IProductState>, action: ProductActions.ProlongationConsents) {
    const deviceInfo = this.deviceInfoService.info;
    return this.service.prolongationConsents(ConsentPackage.SigningProlongation, deviceInfo).pipe(
      tap((dataResponse) => ctx.dispatch(new ProductActions.ProlongationConsentsSuccess(dataResponse.data?.[0]))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ProlongationConsentsSuccess', err);
        return ctx.dispatch(new ProductActions.ProlongationConsentsFail(err.error?.errors));
      })
    );
  }

  @Action(ProductActions.ProlongationConsentsSuccess)
  public prolongationConsentsSuccess(
    ctx: StateContext<IProductState>,
    action: ProductActions.ProlongationConsentsSuccess
  ) {
    ctx.patchState({
      prolongationConsent: action.payload,
    });
  }

  @Action(ProductActions.ProlongationConsentsFail)
  public prolongationConsentsFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationConsentsFail) {
    this.catchError(action.payload);
  }

  @Action(ProductActions.RefinancingConsents)
  public refinancingConsents(ctx: StateContext<IProductState>, action: ProductActions.RefinancingConsents) {
    const deviceInfo = this.deviceInfoService.info;
    const isAdditionalLoan = ctx.getState().prolongation?.additionalLoan;
    const sum = ctx.getState().prolongation?.additionalLoan?.leftSum + ctx.getState().additionalLoanSum;
    return combineLatest([
      this.service.prolongationConsents(
        isAdditionalLoan ? ConsentPackage.AdditionalLoan : ConsentPackage.Refinancing,
        deviceInfo,
        null,
        action.payload
      ),
      this.service.prolongationConsents(
        isAdditionalLoan ? ConsentPackage.SigningAdditionalLoan : ConsentPackage.SigningRefinancing,
        deviceInfo,
        sum,
        action.payload
      ),
    ]).pipe(
      tap(([consents, signingConsents]) =>
        ctx.dispatch(
          new ProductActions.RefinancingConsentsSuccess(
            { consents: consents.data, signingConsents: signingConsents.data },
            deviceInfo
          )
        )
      ),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.RefinancingConsentsFail', err);
        return ctx.dispatch(new ProductActions.RefinancingConsentsFail(err.error?.errors));
      })
    );
  }

  @Action(ProductActions.RefinancingConsentsSuccess)
  public refinancingConsentsSuccess(
    ctx: StateContext<IProductState>,
    action: ProductActions.RefinancingConsentsSuccess
  ) {}

  @Action(ProductActions.RefinancingConsentsFail)
  public RefinancingConsentsFail(ctx: StateContext<IProductState>, action: ProductActions.RefinancingConsentsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для пролонгации займа
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongationCreate}
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#ProlongationCreateSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationCreateFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationCreate)
  public prolongationCreate(ctx: StateContext<IProductState>) {
    const { signature } = ctx.getState().prolongationConsent?.attributes;
    const { term } = ctx.getState().payment?.model?.create;
    const deviceInfo = this.deviceInfoService.info;
    return this.service.prolongationCreate(term, deviceInfo, signature).pipe(
      tap(() => ctx.dispatch(new ProductActions.ProlongationCreateSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ProlongationCreateFail', err);
        return ctx.dispatch(new ProductActions.ProlongationCreateFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной пролонгации займа
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link ProductActions#Prolongation} для загрузки данных о пролонгации
   * - Обращение к [действию]{@link ProductActions#Load} для загрузки данных о займе
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationCreateSuccess)
  public prolongationCreateSuccess(
    ctx: StateContext<IProductState>,
    action: ProductActions.ProlongationCreateSuccess
  ) {}

  /**
   * Действие для неуспешной пролонгации займа
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationCreateFail)
  public prolongationCreateFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationCreateFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для подписания договора
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#sign}
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#SignSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#SignFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Sign)
  public sign(ctx: StateContext<IProductState>, action: ProductActions.Sign) {
    return this.service.sign(action.method, action.payload, action.loanType === Product.ILS).pipe(
      tap(() => ctx.dispatch(new ProductActions.SignSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.SignFail', err);
        return ctx.dispatch(new ProductActions.SignFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного подписания договора
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SignFail)
  public signFail(ctx: StateContext<IProductState>, action: ProductActions.SignFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного подписания договора
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SignSuccess)
  public signSuccess(ctx: StateContext<IProductState>, action: ProductActions.SignSuccess) {}

  /**
   * Действие для запроса повторного кода
   *
   * Порядок выполнения:
   *
   * - В случае ILS:
   *   - Запрос к бэкенду {@link ProductService#resendCodeILS}
   *
   * - В случае PDL:
   *   - Запрос к бэкенду {@link ProductService#resendCodePDL}
   *
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#ResendCodeSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ResendCodeFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ResendCode)
  public resendCode(ctx: StateContext<IProductState>, action: ProductActions.ResendCode) {
    if (action.loan.type === Product.ILS) {
      return this.service.resendCodeILS(action.loan.id).pipe(
        tap(() => ctx.dispatch(new ProductActions.ResendCodeSuccess())),
        catchError((err, caught) => {
          this.catchSentryError('ProductActions.ResendCodeFail', err);
          return ctx.dispatch(new ProductActions.ResendCodeFail(err.error?.errors));
        })
      );
    }

    return this.service.resendCodePDL(action.payload).pipe(
      tap(() => ctx.dispatch(new ProductActions.ResendCodeSuccess())),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ResendCodeFail', err);
        return ctx.dispatch(new ProductActions.ResendCodeFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного запроса повторного кода
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ResendCodeFail)
  public resendCodeFail(ctx: StateContext<IProductState>, action: ProductActions.ResendCodeFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного запроса повторного кода
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ResendCodeSuccess)
  public resendCodeSuccess(ctx: StateContext<IProductState>, action: ProductActions.ResendCodeSuccess) {}

  /**
   * Действие для загрузки pdf документов рассрочки/амнистии
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#installmentPdf}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#InstallmentPdfSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#InstallmentPdfFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.InstallmentPdf)
  public installmentPdf(ctx: StateContext<IProductState>, action: ProductActions.InstallmentPdf) {
    return this.service.installmentPdf(action.loanId, action.payload).pipe(
      tap((response) => ctx.dispatch(new ProductActions.InstallmentPdfSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.InstallmentPdfFail', err);
        return ctx.dispatch(new ProductActions.InstallmentPdfFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешной загрузки pdf документов рассрочки/амнистии
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.InstallmentPdfFail)
  public installmentPdfFail(ctx: StateContext<IProductState>, action: ProductActions.InstallmentPdfFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной загрузки pdf документов рассрочки/амнистии
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.InstallmentPdfSuccess)
  public installmentPdfSuccess(ctx: StateContext<IProductState>, action: ProductActions.InstallmentPdfSuccess) {}

  /**
   * Действие для установки суммы оплаты
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных суммы оплаты
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SetPaymentSum)
  public setPaymentSum(ctx: StateContext<IProductState>, action: ProductActions.SetPaymentSum) {
    const form = ctx.getState().payment.model;

    ctx.setState(
      patch({
        payment: patch({
          model: {
            ...form,
            payment: {
              ...form?.payment,
              sumLeft: action.payload,
              sum: action.payload,
            },
          },
        }),
      })
    );
  }

  /**
   * Действие для получения информации о блокировке получения займа
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#disclaim}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#DisclaimSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#DisclaimFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Disclaim)
  public disclaim(ctx: StateContext<IProductState>, action: ProductActions.Disclaim) {
    return this.service.disclaim().pipe(
      tap((response) => ctx.dispatch(new ProductActions.DisclaimSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.DisclaimFail', err);
        return ctx.dispatch(new ProductActions.DisclaimFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного получения информации о блокировке получения займа
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных блокировки получения займа
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.DisclaimSuccess)
  public disclaimSuccess(ctx: StateContext<IProductState>, action: ProductActions.DisclaimSuccess) {
    ctx.patchState({
      disclaim: action.payload,
    });
  }

  /**
   * Действие для неуспешного получения информации о блокировке получения займа
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.DisclaimFail)
  public disclaimFail(ctx: StateContext<IProductState>, action: ProductActions.DisclaimFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для запроса согласования
   *
   * Порядок выполнения:
   *
   * - В случае ILS:
   *   - Запрос к бэкенду {@link ProductService#ilsConsents}
   *
   * - В случае PDL:
   *   - Запросы к бэкенду {@link ProductService#consents}
   *
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#ConsentsSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ConsentsFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Consents)
  public consents(ctx: StateContext<IProductState>, action: ProductActions.Consents) {
    const deviceInfo = this.deviceInfoService.info;

    return forkJoin(action.payload.map((consent) => this.service.consents(consent, deviceInfo, action.product))).pipe(
      tap((response) => {
        const flatResponse = response.map((item) => item.data).flat();
        ctx.dispatch(new ProductActions.ConsentsSuccess(flatResponse, deviceInfo));
      }),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ConsentsFail', err);
        return ctx.dispatch(new ProductActions.ConsentsFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного запроса согласования
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ConsentsFail)
  public consentsFail(ctx: StateContext<IProductState>, action: ProductActions.ConsentsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного запроса согласования
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ConsentsSuccess)
  public consentsSuccess(ctx: StateContext<IProductState>, action: ProductActions.ConsentsSuccess) {}

  /**
   * Действие для создания займа
   *
   * Порядок выполнения:
   *
   * - В случае ILS:
   *   - Запрос к бэкенду {@link ProductService#ilsCreate}
   *
   * - В случае PDL:
   *   - Запрос к бэкенду {@link ProductService#pdlCreate}
   *
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#CreateSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#CreateFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Create)
  public async loanCreate(ctx: StateContext<IProductState>, action: ProductActions.Create) {
    // TODO: Рефакторинг - посмотреть, что можно объединить
    const state = ctx.getState();
    const model = state.newLoan.model;

    const { method, sum, term, paymentDate, account, product, consents, signingConsents, promocode } = model;

    const profile = this.store.selectSnapshot(ProfileState.form);

    const { data: riskAnalysis, hash: userHash } = this.store.selectSnapshot((s) => s.RiskAnalysis || {});

    if (product === Product.ILS) {
      const workAdditionalIncome = this.store.selectSnapshot(ProfileState).info?.workAdditionalIncome;
      return this.service
        .ilsCreate({
          amount: sum,
          profile: this.store.selectSnapshot(AuthState.userId).toString(),
          periods: term,
          issuanceDate: dayjs().format('YYYY-MM-DD'),
          paymentDay: dayjs(paymentDate, 'DD.MM.YYYY').date().toString(),
          purpose: 'other',
          externalAccountId: method?.value === AccountType.Contact ? '0' : `${account?.id}`,
          signatures: [
            consents.application,
            consents.bkiAgreement,
            consents.consents,
            consents.edsAgreement,
            consents.debtLoadNotification,
          ]
            .filter(Boolean)
            .map((sds) => ({
              validFor: sds.type,
              code: sds.signature,
              deviceFingerprint: sds.deviceInfo?.deviceFingerprint,
              content: sds.content ? this.consentsService.fillData(sds.content) : undefined,
            })),
          marketingData: {
            loanAmount: sum,
            loanPeriods: term,
            platform: 'site',
          },
          riskAnalysisData: {
            accelerometerDeviations: undefined,
            coordinates: {
              latitude: undefined,
              longitude: undefined,
              accuracy: undefined,
            },
            juicyscore: JSON.stringify({
              j: this.juicyScore.getSession(),
              s: this.cookie.get('pixel_sess_id'),
              f: this.cookie.get('pixel_user_fp'),
            }),
            pixelDeviceFP: this.cookie.get('pixel_user_fp'),
            pixelSessionId: this.cookie.get('pixel_sess_id'),
            devPlatform: undefined,
            hardDrivePerformance: undefined,
            historySize: this.window.history.length,
            keyboardTypingSpeed:
              Math.round((+new Date() - riskAnalysis?.startAt) / 1000 / riskAnalysis?.keydownCounter) || 0,
            leftPageCount: riskAnalysis?.leftPageCount,
            numberOfCorrections: riskAnalysis?.numberOfCorrections,
            numberSingleClicks: riskAnalysis?.numberSingleClicks,
            osName: this.deviceInfoService.osName,
            pageFillingDuration: Math.round((+new Date() - riskAnalysis?.startAt) / 1000),
            PPI: undefined,
            processorsCount: undefined,
            ramPerformance: undefined,
            ramSize: undefined,
            screenHeight: this.window.screen.height,
            screenWidth: this.window.screen.width,
            touchScreenDeviations: undefined,
            webCanvasFingerprint: this.deviceInfoService.canvasFingerprint,
            webGLRenderer: this.deviceInfoService.videoCardInfo,
            windowQuartersCount: undefined,
            partner: undefined,
          },
          ...(workAdditionalIncome && { workAdditionalIncome }),
        })
        .pipe(
          tap((response) => ctx.dispatch(new ProductActions.CreateSuccess(response.data?.[0]))),
          catchError((err, caught) => {
            this.catchSentryError('ProductActions.CreateFail', err);
            return ctx.dispatch(new ProductActions.CreateFail(err.error?.errors, err.code));
          })
        );
    }

    return this.service
      .pdlCreate({
        days: term,
        sum,
        inn: profile?.snilsOrInn?.length === 11 ? '' : profile?.snilsOrInn,
        snils: profile?.snilsOrInn?.length !== 11 ? '' : profile?.snilsOrInn,
        paySystem: method?.value,
        accountId: method?.value === AccountType.Contact ? 0 : account?.id,
        brand: BRAND_WEBBANKIR,
        signatures: [
          consents.application,
          consents.bkiAgreement,
          consents.consents,
          consents.edsAgreement,
          consents.debtLoadNotification,
          ...(signingConsents?.debtLoadNotification ? [signingConsents.debtLoadNotification] : []),
          ...(signingConsents?.refinancingContract ? [signingConsents.refinancingContract] : []),
          ...(signingConsents?.additionalLoanContract ? [signingConsents.additionalLoanContract] : []),
          ...(signingConsents?.refinancingIlsContract ? [signingConsents.refinancingIlsContract] : []),
        ]
          .filter((sds) => !!sds)
          .map((sds) => ({
            id: sds.signature,
            type: 'SDS',
            attributes: {
              validFor: sds.type,
              deviceFingerprint: sds.deviceInfo?.deviceFingerprint,
              content: sds.content ? this.consentsService.fillData(sds.content) : undefined,
            },
          })),
        browserInfo: '',
        dataForAnalytics: {
          IPAnalyticsId: false, // TODO
          clientIdGoogle: (this.window as any).ga?.create?.()?.model?.data?.ea?.[':clientId'], // TODO
          clientIdYandex: this.analyticService.yaMetricaId, // TODO
          deviceFingerprint: this.deviceInfoService.info.deviceFingerprint, // TODO
          finkartaDeviceFP: (this.window as any).FingerprintID || '', // TODO
          juicyscoreCookie: JSON.stringify({
            j: this.juicyScore.getSession(),
            s: this.cookie.get('pixel_sess_id'),
            f: this.cookie.get('pixel_user_fp'),
          }),
        },
        deviceInfo: '',
        latitude: null,
        longitude: null,
        platform: 1,
        promoCode: promocode?.confirmed ? promocode.code : '',
        riskAnalysisData: {
          bt: userHash,
          coordinates: {
            latitude: undefined, // TODO
            longitude: undefined, // TODO
          },
          historySize: this.window.history.length, // TODO
          juicyscore: JSON.stringify({
            j: this.juicyScore.getSession(),
            s: this.cookie.get('pixel_sess_id'),
            f: this.cookie.get('pixel_user_fp'),
          }),
          pixelDeviceFP: this.cookie.get('pixel_user_fp'),
          pixelSessionId: this.cookie.get('pixel_sess_id'),
          keyboardTypingSpeed:
            Math.round((+new Date() - riskAnalysis?.startAt) / 1000 / riskAnalysis?.keydownCounter) || 0,
          leftPageCount: riskAnalysis?.leftPageCount,
          numberOfCorrections: riskAnalysis?.numberOfCorrections,
          numberSingleClicks: riskAnalysis?.numberSingleClicks,
          osName: this.deviceInfoService.osName,
          pageFillingDuration: Math.round((+new Date() - riskAnalysis?.startAt) / 1000),
          webCanvasFingerprint: this.deviceInfoService.canvasFingerprint,
          webGLRenderer: this.deviceInfoService.videoCardInfo,
        },
        screenX: this.window.screen.width,
        screenY: this.window.screen.height,
        services: {
          insurance: 0, // TODO
          legal: 0, // TODO
        },
        refinancedFrom: action.payload,
        refinancedFromLoanType: action.refinancedFromLoanType,
      })
      .pipe(
        tap((response) => {
          ctx.dispatch(new ProductActions.CreateSuccess(response.body?.data));
          ctx.dispatch(new EventsActions.AddMetric({ status_code: response.status, action: MetricType.CreateLoan }));
        }),
        catchError((err, caught) => {
          this.catchSentryError('ProductActions.CreateFail', err);
          ctx.dispatch(new ProductActions.CreateFail(err.error?.errors, err.status));
          ctx.dispatch(new EventsActions.AddMetric({ status_code: err.status, action: MetricType.CreateLoan }));
          return throwError(() => err);
        })
      );
  }

  /**
   * Действие для неуспешного создания займа
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.CreateFail)
  public loanCreateFail(ctx: StateContext<IProductState>, action: ProductActions.CreateFail) {
    if (action.errorCode === 422 && action.payload?.some((err) => err.code === 'cardCheckError')) {
      return;
    }
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного создания займа
   *
   * Порядок выполнения:
   * - [Обновление]{@link ResetForm} данных в форме пользователя
   * - [Обновление]{@link ResetForm} данных в форме займа
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.CreateSuccess)
  public loanCreateSuccess(ctx: StateContext<IProductState>, action: ProductActions.CreateSuccess) {
    ctx.dispatch(
      new ResetForm({
        path: 'Accounts.form',
      })
    );

    ctx.dispatch(
      new ResetForm({
        path: 'Product.newLoan',
      })
    );
  }

  /**
   * Действие для загрузки фотографии
   *
   * Порядок выполнения:
   *
   * - В случае ILS:
   *   - Запрос к бэкенду {@link ProductService#uploadPhotoILS}
   *
   * - В случае PDL:
   *   - Запрос к бэкенду {@link ProductService#uploadPhoto}
   *
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#UploadPhotoSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#UploadPhotoFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.UploadPhoto)
  public uploadPhoto(ctx: StateContext<IProductState>, action: ProductActions.UploadPhoto) {
    const { id, type } = action.loan;

    if (type === Product.ILS) {
      return this.service.uploadPhotoILS(id, action.payload).pipe(
        tap(() => ctx.dispatch(new ProductActions.UploadPhotoSuccess())),
        catchError((err, caught) => {
          this.catchSentryError('ProductActions.UploadPhotoFail', err);
          return ctx.dispatch(new ProductActions.UploadPhotoFail(err.error?.errors));
        })
      );
    }

    return this.service.uploadPhoto(id, action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new ProductActions.UploadPhotoSuccess());
        ctx.dispatch(
          new EventsActions.AddMetric({
            status_code: response.status,
            action: MetricType.RequestLoanPhoto,
          })
        );
      }),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.UploadPhotoFail', err);
        ctx.dispatch(new ProductActions.UploadPhotoFail(err.error?.errors));
        ctx.dispatch(new EventsActions.AddMetric({ status_code: err.status, action: MetricType.RequestLoanPhoto }));
        return throwError(() => err);
      })
    );
  }

  /**
   * Действие для успешной загрузки фотографии
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.UploadPhotoSuccess)
  public uploadPhotoSuccess(ctx: StateContext<IProductState>, action: ProductActions.UploadPhotoSuccess) {}

  /**
   * Действие для неуспешной загрузки фотографии
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.UploadPhotoFail)
  public uploadPhotoFail(ctx: StateContext<IProductState>, action: ProductActions.UploadPhotoFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для подтверждения без фотографии
   *
   * Порядок выполнения:
   * - Если тип займа ILS:
   *     - Запрос к бэкенду {@link ProductService#revokeIls}
   *    - В случае успешного ответа - [обрабатываем]{@link ProductActions#SubmitWithoutPhotoSuccess}
   *    - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#SubmitWithoutPhotoFail} ошибку
   * - Иначе:
   *    - Запрос к бэкенду {@link ProductService#submitWithoutPhoto}
   *    - В случае успешного ответа - [обрабатываем]{@link ProductActions#SubmitWithoutPhotoSuccess}
   *    - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#SubmitWithoutPhotoFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SubmitWithoutPhoto)
  public submitWithoutPhoto(ctx: StateContext<IProductState>, action: ProductActions.SubmitWithoutPhoto) {
    const { id, type } = action.payload;

    if (type === Product.ILS) {
      return this.service.revokeIls(id).pipe(
        tap(() => ctx.dispatch(new ProductActions.SubmitWithoutPhotoSuccess(type))),
        catchError((err, caught) => {
          this.catchSentryError('ProductActions.SubmitWithoutPhotoFail', err);
          return ctx.dispatch(new ProductActions.SubmitWithoutPhotoFail(err.error?.errors));
        })
      );
    }

    return this.service
      .submitWithoutPhoto(id, {
        id,
        type: 'requests',
        attributes: { status: ProductStatus.IdRefused },
      })
      .pipe(
        tap(() => ctx.dispatch(new ProductActions.SubmitWithoutPhotoSuccess(type))),
        catchError((err, caught) => {
          this.catchSentryError('ProductActions.SubmitWithoutPhotoFail', err);
          return ctx.dispatch(new ProductActions.SubmitWithoutPhotoFail(err.error?.errors));
        })
      );
  }

  /**
   * Действие для успешного подтверждения без фотографии
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SubmitWithoutPhotoSuccess)
  public submitWithoutPhotoSuccess(ctx: StateContext<IProductState>, action: ProductActions.SubmitWithoutPhotoSuccess) {
    if (action.payload === Product.ILS) {
      ctx.dispatch(
        new UpdateFormValue({
          path: 'Product.newLoan',
          value: Product.PDL,
          propertyPath: 'defaultProduct',
        })
      );
    }
  }

  /**
   * Действие для неуспешного подтверждения без фотографии
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.SubmitWithoutPhotoFail)
  public submitWithoutPhotoFail(ctx: StateContext<IProductState>, action: ProductActions.SubmitWithoutPhotoFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для получения Pdf
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#pdf}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#PdfSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#PdfFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Pdf)
  public pdf(ctx: StateContext<IProductState>, action: ProductActions.Pdf) {
    return this.service.pdf(action.payload).pipe(
      tap((response) => ctx.dispatch(new ProductActions.PdfSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.PdfFail', err);
        return ctx.dispatch(new ProductActions.PdfFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного получения Pdf
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PdfFail)
  public pdfFail(ctx: StateContext<IProductState>, action: ProductActions.PdfFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного получения Pdf
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PdfSuccess)
  public pdfSuccess(ctx: StateContext<IProductState>, action: ProductActions.PdfSuccess) {}

  /**
   *  Действие для получения контракта
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#getContract}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#ContractSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ContractFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Contract)
  public contract(ctx: StateContext<IProductState>, action: ProductActions.Contract) {
    return this.service.getContract().pipe(
      tap((response) => ctx.dispatch(new ProductActions.ContractSuccess(response.data?.[0]?.attributes))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ContractFail', err);
        return ctx.dispatch(new ProductActions.ContractFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного получения контракта
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных контракта
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ContractSuccess)
  public contractSuccess(ctx: StateContext<IProductState>, action: ProductActions.ContractSuccess) {
    ctx.patchState({
      contract: action.payload,
    });
  }

  /**
   * Действие для неуспешного получения контракта
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ContractFail)
  public contractFail(ctx: StateContext<IProductState>, action: ProductActions.ContractFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для получения методов оплаты
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#paymentMethods}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#PaymentMethodsSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#PaymentMethodsFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentMethods)
  public paymentMethods(ctx: StateContext<IProductState>, action: ProductActions.PaymentMethods) {
    return this.service.paymentMethods(false, action.payload === Product.ILS ? 'ils_repayment' : 'wb_receive').pipe(
      tap((response) => ctx.dispatch(new ProductActions.PaymentMethodsSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.PaymentMethodsFail', err);
        return ctx.dispatch(new ProductActions.PaymentMethodsFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного получения методов оплаты
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentMethodsFail)
  public paymentMethodsFail(ctx: StateContext<IProductState>, action: ProductActions.PaymentMethodsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного получения методов оплаты
   *
   * Порядок выполнения:
   * - [Преобразование]{@link ProductState#transformPaymentMethods} методов оплаты
   * - Обновление в состоянии данных методов оплаты
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentMethodsSuccess)
  public paymentMethodsSuccess(ctx: StateContext<IProductState>, action: ProductActions.PaymentMethodsSuccess) {
    ctx.patchState({
      paymentMethods: this.transformPaymentMethods(action.payload),
    });
  }

  /**
   * Действие для получения комиссии
   *
   * Порядок выполнения:
   *
   * - В случае ILS:
   *   - Ручной расчет комиссии
   *
   * - В случае PDL:
   *   - Запросы к бэкенду {@link ProductService#paymentCommission}
   *
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#PaymentCommissionSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#PaymentCommissionFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentCommission, { cancelUncompleted: true })
  public paymentCommission(ctx: StateContext<IProductState>, action: ProductActions.PaymentCommission) {
    return this.service.paymentCommission(action.payload, action.service).pipe(
      tap((response) => ctx.dispatch(new ProductActions.PaymentCommissionSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.PaymentCommissionFail', err);
        return ctx.dispatch(new ProductActions.PaymentCommissionFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного получения комиссии
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentCommissionFail)
  public paymentCommissionFail(ctx: StateContext<IProductState>, action: ProductActions.PaymentCommissionFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного получения комиссии
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentCommissionSuccess)
  public paymentCommissionSuccess(ctx: StateContext<IProductState>, action: ProductActions.PaymentCommissionSuccess) {}

  @Action(ProductActions.PaymentV2)
  public paymentV2(ctx: StateContext<IProductState>, action: ProductActions.PaymentV2) {
    const payment = ctx.getState().payment.model.payment;
    const { type, refinancedFrom } = action.loan;
    const clientId = this.store.selectSnapshot((state) => state.Auth?.userId)?.toString();

    const loanId =
      refinancedFrom?.id && [ProductStatus.FailedRefinancing].includes(refinancedFrom?.status)
        ? refinancedFrom.id
        : action.loan.id;

    return timer(action.timeout * 1000).pipe(
      switchMap(() =>
        this.service.paymentInteractive({
          type: 'Payment',
          attributes: {
            amount: payment.sum,
            clientId,
            source: 1,
            card: payment[PaymentMethod.CreditCard].account?.id
              ? {
                  id: payment[PaymentMethod.CreditCard].account.id.toString(),
                  cvc: payment[PaymentMethod.CreditCard].cvc,
                  cvv: payment[PaymentMethod.CreditCard].cvc,
                }
              : {
                  number: payment[PaymentMethod.CreditCard].card,
                  holder: 'UNKNOWN NAME',
                  month: payment[PaymentMethod.CreditCard].expire.slice(0, 2),
                  year: payment[PaymentMethod.CreditCard].expire.slice(2, 4),
                  cvc: payment[PaymentMethod.CreditCard].cvc,
                  cvv: payment[PaymentMethod.CreditCard].cvc,
                },
            useBonuses: payment.useBonuses,
            target: {
              product: type,
              id: loanId,
            },
            clientInfo: {
              challengeWindowSize: '05',
              language: this.window.navigator.language,
              screenHeight: this.window.screen.height,
              screenWidth: this.window.screen.width,
              timezone: String(new Date().getTimezoneOffset()),
              javaEnabled: this.window.navigator?.javaEnabled() ?? false,
              javascriptEnabled: true,
              colorDepth: [1, 4, 8, 15, 16, 24, 32, 48].includes(this.window.screen.colorDepth)
                ? this.window.screen.colorDepth
                : 48,
            },
            paymentId: action.payload,
          },
        })
      ),
      tap((response) => ctx.dispatch(new ProductActions.PaymentV2Success(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.PaymentV2Fail', err);
        return ctx.dispatch(new ProductActions.PaymentV2Fail(err.error?.errors, err.error?.isHandled));
      })
    );
  }

  @Action(ProductActions.PaymentV2Fail)
  public paymentV2Fail(ctx: StateContext<IProductState>, action: ProductActions.PaymentV2Fail) {
    this.catchError(
      action.payload?.map((err) => ({ ...err, timeout: 10000 })),
      !action.isHandled
    );

    if (action.payload?.find((err) => err.code === CardPaymentErrorCode.TechError)) {
      ctx.dispatch(new Navigate(['cabinet', 'main']));
    }
  }

  @Action(ProductActions.PaymentV2Success)
  public paymentV2Success(ctx: StateContext<IProductState>, action: ProductActions.PaymentV2Success) {}

  /**
   * Действие для оплаты
   *
   * Порядок выполнения:
   * - В зависимости от способа оплаты делаем запрос к бэкенду
   *   - [credit_card]{@link ProductService#paymentInteractive}
   *   - [sberbank_online]{@link ProductService#paymentSberbank}
   *   - [yoo_money]{@link ProductService#paymentYoomoney}
   *   - [russian_post]{@link ProductService#paymentRussianPost}
   *   - [SBP]{@link ProductService#paymentSbpLink}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#PaymentSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#PaymentFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Payment)
  public payment(ctx: StateContext<IProductState>, action: ProductActions.Payment) {
    const payment = ctx.getState().payment.model.payment;

    const { type, refinancedFrom } = action.payload;
    const clientId = this.store.selectSnapshot((state) => state.Auth?.userId)?.toString();

    const loanId =
      refinancedFrom?.id && [ProductStatus.FailedRefinancing].includes(refinancedFrom?.status)
        ? refinancedFrom.id
        : action.payload.id;

    return of(payment.method?.value?.id).pipe(
      switchMap((method) => {
        switch (method) {
          case PaymentMethod.CreditCard:
            return this.service.paymentInteractive({
              type: 'Payment',
              attributes: {
                amount: payment.sum,
                clientId,
                source: 1,
                card: payment[PaymentMethod.CreditCard].account?.id
                  ? {
                      id: payment[PaymentMethod.CreditCard].account.id.toString(),
                      cvc: payment[PaymentMethod.CreditCard].cvc,
                      cvv: payment[PaymentMethod.CreditCard].cvc,
                    }
                  : {
                      number: payment[PaymentMethod.CreditCard].card,
                      holder: 'UNKNOWN NAME',
                      month: payment[PaymentMethod.CreditCard].expire.slice(0, 2),
                      year: payment[PaymentMethod.CreditCard].expire.slice(2, 4),
                      cvc: payment[PaymentMethod.CreditCard].cvc,
                      cvv: payment[PaymentMethod.CreditCard].cvc,
                    },
                useBonuses: payment.useBonuses,
                target: {
                  product: type,
                  id: loanId,
                },
                clientInfo: {
                  challengeWindowSize: '05',
                  language: this.window.navigator.language,
                  screenHeight: this.window.screen.height,
                  screenWidth: this.window.screen.width,
                  timezone: String(new Date().getTimezoneOffset()),
                  javaEnabled: this.window.navigator?.javaEnabled() ?? false,
                  javascriptEnabled: true,
                  colorDepth: [1, 4, 8, 15, 16, 24, 32, 48].includes(this.window.screen.colorDepth)
                    ? this.window.screen.colorDepth
                    : 48,
                },
              },
            });
          case PaymentMethod.Sberbank:
            return this.service.paymentSberbank({
              sum: payment.sum,
              paymentData: [{ type: 1, number: loanId }],
            });
          case PaymentMethod.YooMoney:
            return this.service.paymentYoomoney({
              sum: payment.sum,
              paymentData: [{ type: 1, number: loanId }],
            });
          case PaymentMethod.RussianPost:
            return this.service.paymentRussianPost(
              `${payment.method.value.attributes.url}?${Product.PDL ? `loanId=${loanId}` : ''}`
            );

          case PaymentMethod.SBP:
            return this.service.paymentSbpLink({
              type: 'sbpLink',
              attributes: {
                pdlId: loanId,
                amount: payment.sum,
                clientId,
                source: 1,
                useBonuses: !!payment.useBonuses,
                bankSchema: payment.SBP.bank?.value,
                target: {
                  product: type,
                  id: loanId,
                },
              },
            });
          case PaymentMethod.VirtualCard:
            return this.service.paymentVirtualCard({
              type: 'VirtualCardPayment',
              attributes: {
                clientId,
                account: payment[PaymentMethod.VirtualCard].account?.description,
                target: {
                  product: type,
                  id: loanId,
                },
                amount: payment.sum,
                useBonuses: !!payment.useBonuses,
              },
            });
          default:
            return;
        }
      }),
      tap((response) => ctx.dispatch(new ProductActions.PaymentSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.PaymentFail', err);

        const errors =
          payment.method?.value?.id === PaymentMethod.CreditCard
            ? err.error?.errors?.map((error) => ({ ...error, timeout: 10000 }))
            : err.error?.errors;

        return ctx.dispatch(new ProductActions.PaymentFail(errors, err.error?.isHandled));
      })
    );
  }

  /**
   * Действие для неуспешной оплаты
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentFail)
  public paymentFail(ctx: StateContext<IProductState>, action: ProductActions.PaymentFail) {
    this.catchError(action.payload, !action.isHandled);

    if (action.payload?.find((err) => err.code === CardPaymentErrorCode.TechError)) {
      ctx.dispatch(new Navigate(['cabinet', 'main']));
    }
  }

  /**
   * Действие для успешной оплаты
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.PaymentSuccess)
  public paymentSuccess(ctx: StateContext<IProductState>, action: ProductActions.PaymentSuccess) {}

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.Reset)
  public reset(ctx: StateContext<IProductState>, action: ProductActions.Reset) {
    ctx.setState(PRODUCT_STATE_DEFAULTS);
  }

  /**
   * Действие для сброса состояния формы к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ResetForm)
  public resetForm(ctx: StateContext<IProductState>, action: ProductActions.ResetForm) {
    ctx.patchState({
      newLoan: PRODUCT_STATE_DEFAULTS.newLoan,
    });
  }

  /**
   * Метод для преобразования способов оплаты
   *
   * @param methods Способы оплаты
   */
  private transformPaymentMethods(methods: IPaymentMethod[]): ISelectItem[] {
    return methods.map((method) => ({
      value: method,
      label: PAYMENT_METHOD_TITLE_MAP.get(method.id) || method.attributes.name,
      icon: `./assets/images/payment/${method.id}.svg`,
    }));
  }

  /**
   * Действие для загрузки полезных фактов
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#facts}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#FactsSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#FactsFail} ошибку
   */
  @Action(ProductActions.Facts)
  public facts(ctx: StateContext<IProductState>, action: ProductActions.Facts) {
    return this.service.facts().pipe(
      tap((response) => ctx.dispatch(new ProductActions.FactsSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.FactsFail', err);
        return ctx.dispatch(new ProductActions.FactsFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешной загрузки полезных фактов
   *
   * Порядок выполнения:
   * - Обработка ошибки
   */
  @Action(ProductActions.FactsFail)
  public factsFail(ctx: StateContext<IProductState>, action: ProductActions.FactsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной загрузки полезных фактов
   *
   * - Порядок выполнения:
   * - Обновление в состоянии данных полезных фактов
   */
  @Action(ProductActions.FactsSuccess)
  public factsSuccess(ctx: StateContext<IProductState>, action: ProductActions.FactsSuccess) {
    ctx.patchState({
      facts: action.payload.map((item: string) => ({ title: 'Факты о WEBBANKIR', text: item })),
    });
  }

  /**
   * Действие для загрузки pdf дополнительного соглашения пролонгации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongationPdf}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#ProlongationPdfSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationPdfFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationPdf)
  public prolongationPdf(ctx: StateContext<IProductState>, action: ProductActions.ProlongationPdf) {
    return this.service.prolongationPdf(action.payload).pipe(
      tap((response) => ctx.dispatch(new ProductActions.ProlongationPdfSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProductActions.ProlongationPdfFail', err);
        return ctx.dispatch(new ProductActions.ProlongationPdfFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешной загрузки pdf дополнительного соглашения пролонгации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationPdfFail)
  public prolongationPdfFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationPdfFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной загрузки pdf дополнительного соглашения пролонгации
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationPdfSuccess)
  public prolongationPdfSuccess(ctx: StateContext<IProductState>, action: ProductActions.ProlongationPdfSuccess) {}

  /**
   * Действие для загрузки документа пролонгации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongationDocument}
   * - В случае успешного ответа - передаем [данные]{@link ProductActions#ProlongationDocumentSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationDocumentFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationDocument)
  public prolongationDocument(ctx: StateContext<IProductState>, action: ProductActions.ProlongationDocument) {
    return this.service.prolongationDocument(action.payload).pipe(
      tap((response) => ctx.dispatch(new ProductActions.ProlongationDocumentSuccess(response))),
      catchError((err, caught) => ctx.dispatch(new ProductActions.ProlongationDocumentFail(err.error?.errors)))
    );
  }

  /**
   * Действие для успешной загрузки документа пролонгации
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationDocumentSuccess)
  public prolongationDocumentSuccess(
    ctx: StateContext<IProductState>,
    action: ProductActions.ProlongationDocumentSuccess
  ) {}

  /**
   * Действие для неуспешной загрузки документа пролонгации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationDocumentFail)
  public prolongationDocumentFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationDocumentFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для подписания пролонгации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongationSign}
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#ProlongationSignSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationSignFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSign)
  public prolongationSign(ctx: StateContext<IProductState>) {
    const { code } = ctx.getState().payment?.model?.signing;

    return this.service.prolongationSign(code).pipe(
      tap(() => ctx.dispatch(new ProductActions.ProlongationSignSuccess())),
      catchError((err, caught) => ctx.dispatch(new ProductActions.ProlongationSignFail(err.error?.errors)))
    );
  }

  /**
   * Действие для успешного подписания пролонгации
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link ProductActions#Prolongation} для загрузки данных о пролонгации
   * - Обращение к [действию]{@link ProductActions#Load} для загрузки данных о займе
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSignSuccess)
  public prolongationSignSuccess(ctx: StateContext<IProductState>, action: ProductActions.ProlongationSignSuccess) {
    ctx.dispatch(new ProductActions.Prolongation());
    ctx.dispatch(new ProductActions.Load());
  }

  /**
   * Действие для неуспешного подписания пролонгации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSignFail)
  public prolongationSignFail(ctx: StateContext<IProductState>, action: ProductActions.ProlongationSignFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для отправки повторного кода подписания пролонгации
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProductService#prolongationSignResendCode}
   * - В случае успешного ответа - [обрабатываем]{@link ProductActions#ProlongationSignResendCodeSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProductActions#ProlongationSignResendCodeFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSignResendCode)
  public prolongationSignResendCode(ctx: StateContext<IProductState>) {
    return this.service.prolongationSignResendCode().pipe(
      tap(() => ctx.dispatch(new ProductActions.ProlongationSignResendCodeSuccess())),
      catchError((err, caught) => ctx.dispatch(new ProductActions.ProlongationSignResendCodeFail(err.error?.errors)))
    );
  }

  /**
   * Действие для успешной отправки повторного кода подписания пролонгации
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSignResendCodeSuccess)
  public prolongationSignResendCodeSuccess(
    ctx: StateContext<IProductState>,
    action: ProductActions.ProlongationSignResendCodeSuccess
  ) {}

  /**
   * Действие для неуспешной отправки повторного кода подписания пролонгации
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ProlongationSignResendCodeFail)
  public prolongationSignResendCodeFail(
    ctx: StateContext<IProductState>,
    action: ProductActions.ProlongationSignResendCodeFail
  ) {
    this.catchError(action.payload);
  }

  /**
   * Действие Открытие согласия
   *
   * Порядок выполнения:
   *
   * @param ctx
   * @param action
   */
  @Action(ProductActions.ViewConsent)
  public viewConsent(ctx: StateContext<IProductState>, action: ProductActions.ViewConsent) {
    const consent = action.payload;
    const uri = consent.attributes.uri;
    if (uri) {
      this.window.open(action.index === undefined && typeof uri === 'string' ? uri : uri[action.index]);
    } else if (consent.attributes.html) {
      this.store.dispatch(
        new ModalActions.OpenModal({
          route: ['consents'],
          data: {
            header: {
              title: consent.attributes.name,
            },
            body: ['overflow-x-auto hide-scroll px-0 mx-0'],
            content: consent.attributes.html,
          },
        })
      );
    }
  }

  @Action(ProductActions.RefinancingPaid)
  public refinancingPaid(ctx: StateContext<IProductState>, action: ProductActions.RefinancingPaid) {
    ctx.patchState({
      refinancingPaid: true,
    });
  }

  @Action(ProductActions.SetAdditionalLoanSum)
  public setAdditionalLoanSum(ctx: StateContext<IProductState>, action: ProductActions.SetAdditionalLoanSum) {
    ctx.patchState({
      additionalLoanSum: action.payload,
    });
  }
}
