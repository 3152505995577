import { Inject, Injectable } from '@angular/core';
import { IFingerPrint } from '@app/interfaces/finger-print.interface';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  constructor(
    @Inject('Window') private window: Window,
  ) { }

  public get info(): IFingerPrint {
    const canvas = this.window.document.createElement('canvas');
    const gl = canvas.getContext('webgl');
    const debugInfo = gl?.getExtension('WEBGL_debug_renderer_info');
    const unMaskedVendorWebgl = gl?.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || 'no_canvas';
    const unMaskedRendererWebgl = gl?.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'no_canvas';

    const {availWidth, availHeight} = this.window.screen;
    const screen = availWidth > availHeight ? `${availHeight}x${availWidth}` : `${availWidth}x${availHeight}`

    const deviceId = [
      this.window.navigator.userAgent,
      screen,
      unMaskedVendorWebgl,
      unMaskedRendererWebgl,
      this.window.navigator.platform,
      this.window.navigator.vendor,
      this.window.navigator.language,
    ].join(';');

    return {
      deviceFingerprint: Md5.hashStr(deviceId),
      ownerInfo: {
        platform: this.window.navigator.platform,
        screen,
        userAgent: this.window.navigator.userAgent,
        webgl: `${unMaskedVendorWebgl};${unMaskedRendererWebgl}`,
      },
    };
  }

  public get canvasFingerprint(): number {
    const canvas = this.window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const txt = 'i9asdm..$#po((^@KbXrww!~cz';
    ctx.textBaseline = 'top';
    ctx.font = '16px \'Arial\'';
    ctx.textBaseline = 'alphabetic';
    ctx.rotate(0.05);
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = 'rgba(102, 200, 0, 0.7)';
    ctx.fillText(txt, 4, 17);
    ctx.shadowBlur = 10;
    ctx.shadowColor = 'blue';
    ctx.fillRect(-20, 10, 234, 5);
    const str = canvas.toDataURL();

    let hash = 0;
    let char = '';
    if (str?.length === 0) {return;}
    for (let i = 0; i < str.length; i++) {
      char = `${str.charCodeAt(i)}`;
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + (+char);
      // eslint-disable-next-line no-bitwise
      hash &= hash;
    }
    return hash;
  }

  public get videoCardInfo(): any {
    const gl = this.window.document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return {
        error: 'no webgl',
      };
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    return debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : '';
  }

  public get osName(): string | null {
    const { userAgent } = this.window.navigator;
    const { platform } = this.window.navigator;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  public get browserName(): string | null {
    const userAgent = this.window?.navigator?.userAgent;

    if(userAgent.match(/chrome|chromium|crios/i)){
      return 'chrome';
    } else if(userAgent.match(/firefox|fxios/i)){
      return 'firefox';
    } else if(userAgent.match(/safari/i)){
      return 'safari';
    } else if(userAgent.match(/opr\//i)){
      return 'opera';
    } else if(userAgent.match(/edg/i)){
      return 'edge';
    }

    return null;
  }
}
