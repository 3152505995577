/**
 * Справочник действий состояния "Счета"
 */
export enum AccountsActionList {
  LOAD = '[Accounts] loaded',
  LOAD_FAILED = '[Accounts] load failed',
  LOAD_SUCCESS = '[Accounts] load success',

  BANKS = '[Accounts] banks',
  BANKS_FAIL = '[Accounts] banks fail',
  BANKS_SUCCESS = '[Accounts] banks success',

  CARD_BANK = '[Accounts] card bank',
  CARD_BANK_FAIL = '[Accounts] card bank fail',
  CARD_BANK_SUCCESS = '[Accounts] card bank success',

  CHECK_CARD = '[Accounts] check card',
  CHECK_CARD_FAIL = '[Accounts] check card fail',
  CHECK_CARD_SUCCESS = '[Accounts] check card success',

  BIND_CARD = '[Accounts] bind card',
  BIND_CARD_FAIL = '[Accounts] bind card fail',
  BIND_CARD_SUCCESS = '[Accounts] bind card success',

  SEND_CODE = '[Accounts] send code',
  SEND_CODE_FAIL = '[Accounts] send code fail',
  SEND_CODE_SUCCESS = '[Accounts] send code success',

  SUBMIT_CODE = '[Accounts] submit code',
  SUBMIT_CODE_FAIL = '[Accounts] submit code fail',
  SUBMIT_CODE_SUCCESS = '[Accounts] submit code success',

  CREATE = '[Accounts] create account',
  CREATE_FAIL = '[Accounts] create account fail',
  CREATE_SUCCESS = '[Accounts] create account success',

  DELETE = '[Accounts] delete',
  DELETE_FAIL = '[Accounts] delete failed',
  DELETE_SUCCESS = '[Accounts] delete success',

  MAKE_DEFAULT = '[Accounts] make default',
  MAKE_DEFAULT_FAIL = '[Accounts] make default failed',
  MAKE_DEFAULT_SUCCESS = '[Accounts] make default success',

  BIND_OPTIONS = '[Accounts] bind options',
  BIND_OPTIONS_SUCCESS = '[Accounts] bind options success',
  BIND_OPTIONS_FAIL = '[Accounts] bind options fail',

  RESET_FORM = '[Accounts] reset form',
  RESET_CONFIRMATION = '[Accounts] reset confirmation',

  RESET = '[Accounts] reset',
  PAYMENT_3DS_HANDLER = '[Accounts] 3ds',
  WATCH_SMS_CODE = '[Accounts] watch sms code',
}
