import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IJivoChatContacts } from '@app/services/jivo-chat/interfaces/jivo-chat-contacts.interface';
import { ProfileState } from '@app/states/profile/states/profile.state';

@Injectable({
  providedIn: 'root',
})
export class JivoChatService {
  private signedIn = false;

  constructor(
    private store: Store,
  ) {
  }

  public open(): void {
    try {
      jivo_api.open();
    } catch (e) {
    }
  }

  public signin(): void {
    if (this.signedIn) {return;}

    const profile = this.store.selectSnapshot(ProfileState.form);
    const login = this.store.selectSnapshot(ProfileState.info)?.login;

    this.setContacts({
      name: `7${profile.phone}`,
      email: profile.email,
      phone: `7${profile.phone}`,
      description: `${profile.lastName} ${profile.firstName} ${profile.middleName ? profile.middleName : ''} ${login}`,
    });

    this.signedIn = true;
  }

  public signout(): void {
    this.setContacts({
      name: ``,
      email: ``,
      phone: ``,
      description: ``,
    });

    this.signedIn = false;
  }

  private setContacts(data: Partial<IJivoChatContacts>): void {
    try {
      jivo_api.setContactInfo(data);
    } catch (e) {
    }
  }
}
