import { Injectable } from '@angular/core';
import { EditableFieldsExtention } from '@app/areas/authenticated/profile/constants/editable-fields-extention.conts';
import { ProfileFormsPossibleDisabledFields } from '@app/areas/authenticated/profile/constants/profile-forms-fields.conts';
import { WorkType } from '@app/areas/unauthenticated/registration/constants/work-type.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { JivoChatService } from '@app/services/jivo-chat/jivo-chat.service';
import { EventName } from '@app/states/events/constants/event-name.const';
import { MetricType } from '@app/states/events/constants/metric-type.const';
import { EventsActions } from '@app/states/events/states/events.actions';
import { ModalEvent } from '@app/states/modal/constants/modal-event.const';
import { IModalEventFn } from '@app/states/modal/interfaces/modal-event-fn.interface';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ModalClose } from '@web-bankir/ui-kit/components/modal';
import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { forkJoin, switchMap, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestStatus } from '../../../constants/request-status.const';
import { ModalActions } from '../../modal/states/modal.actions';
import { MODAL_INFO_ROUTES_MAP } from '../constants/modal-info-routes-map.const';
import { ModalInfoType } from '../constants/modal-info-type.const';
import { IModalInfo } from '../interfaces/modal-info.interface';
import { IProfileEditableFields } from '../interfaces/profile-editable-fields.interface';
import { IProfileForm } from '../interfaces/profile-form.interface';
import { IProfileInfo } from '../interfaces/profile-info.interface';
import { ProfileService } from '../services/profile.service';
import { PROFILE_STATE_DEFAULTS } from './profile-state-defaults.const';
import { IProfileState } from './profile-state.interface';
import { ProfileActions } from './profile.actions';

/**
 * Класс NGXS состояния "Профиль"
 */
@State<IProfileState>({
  name: 'profile',
  defaults: PROFILE_STATE_DEFAULTS,
})
@Injectable()
export class ProfileState extends StateErrorHandler {
  /**
   * Конструктор класса состояния профиля пользователя
   *
   * @param store NGXS{@link Store} хранилище
   * @param service [Сервис]{@link ProfileService} профиля пользователя
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   */
  constructor(
    protected store: Store,
    private service: ProfileService,
    protected toast: ToastService,
    private jivoChat: JivoChatService
  ) {
    super(toast, store);
  }

  /**
   * Селектор, возвращающий форму профиля
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static form(state: IProfileState): IProfileForm {
    return state?.form?.model;
  }

  /**
   * Селектор, возвращающий информацию о профиле
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static info(state: IProfileState): IProfileInfo {
    return state.info;
  }

  /**
   * Селектор, возвращающий профиль удален
   */
  @Selector()
  public static deletedAt(state: IProfileState): string {
    return state.deletedAt;
  }

  /**
   * Селектор, возвращающий информацию является ли профиль пользователя заблокированным
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static isBlocked(state: IProfileState): boolean {
    return !!state.info?.blocked;
  }

  /**
   * Селектор, возвращающий информацию является ли пользователь мошенником
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static isFraud(state: IProfileState): boolean {
    return !!state.info?.fraudster;
  }

  /**
   * Селектор, возвращающий информацию является ли профиль пользователя проданным
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static isSold(state: IProfileState): boolean {
    return !!state.info?.sold;
  }

  /**
   * Селектор, возвращающий информацию о неактивном статусе профиля пользователя
   * Профиль считается неактивным, если есть один из признаков:
   * заблокирован, продан, мошенник, находится в черном списке, удален
   *
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static isInactive(state: IProfileState): boolean {
    return (
      state.info?.blocked || state.info?.sold || state.info?.fraudster || state.info?.blacklisted || state.info?.deleted
    );
  }

  /**
   * Селектор, возвращающий возможные для редактирования поля
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static editableFields(state: IProfileState): IProfileEditableFields {
    return state.info?.editableFields;
  }

  /**
   * Селектор, возвращающий блокированные поля для конкретного раздела
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static hasEnabledFields(state: IProfileState): { [key: string]: boolean } {
    if (!state.info?.editableFields) {
      return;
    }
    const enabledFields = {};
    Object.keys(ProfileFormsPossibleDisabledFields).forEach((key) => {
      const hasEnabledField = ProfileFormsPossibleDisabledFields[key]?.find((field: string) =>
        state.info?.editableFields?.attributes.includes(field)
      );
      enabledFields[key] = hasEnabledField;
    });
    return enabledFields;
  }

  /**
   * Селектор возвращает номер телефона пользователя в формате +7 (xxx) xxx-xx-xx
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static phone(state: IProfileState): string {
    if (!state.form?.model?.phone) {
      return '';
    }

    return ('7' + state.form?.model?.phone?.toString())?.replace(
      /(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/,
      '+$1 ($2) $3-$4-$5'
    );
  }

  /**
   * Селектор возвращает информацию о наличии не прошедших проверку фотографий
   * @param profile данные профиля пользователя
   */
  @Selector()
  public static problemsWithPhoto(state: IProfileState): string[] {
    return state.info?.problems
      ?.filter((item) => ['clientPhoto', 'passportPhoto'].includes(item.attribute))
      .map((item) => item.attribute);
  }

  @Selector()
  public static problemsWithEnrollment(state: IProfileState): string[] {
    return state.info?.problems?.filter((item) => item.code === 'EnrollmentMoneyError').map((item) => item.code);
  }

  /**
   * Селектор возвращает данные для модального окна изменения данных профиля
   * @param state состояние профиля пользователя
   */
  @Selector()
  public static modalInfo(state: IProfileState): IModalInfo {
    return state.modalInfo;
  }

  /**
   * Действие для загрузки профиля пользователя
   *
   * Порядок выполнения:
   * - Очистить данные хранилища, изменить статус запроса данных
   * - Запросы к бэкенду {@link ProfileService#getProfile} и {@link ProfileService#getClientInfo}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link ProfileActions#LoadSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#LoadFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.Load)
  public load(ctx: StateContext<IProfileState>, action: ProfileActions.Load) {
    const userId = this.store.selectSnapshot((state) => {
      if (new Date(state.Auth?.tokenExpired * 1000) > new Date()) {
        return state.Auth.userId;
      }

      return null;
    });

    ctx.patchState({
      status: RequestStatus.Pending,
    });

    return forkJoin([this.service.getProfile(action.withModals), this.service.getClientInfo(userId)]).pipe(
      tap(([profile, clientInfo]) => {
        ctx.dispatch(
          new ProfileActions.LoadSuccess({
            ...profile.data,
            problems: clientInfo.data?.attributes?.problems,
            sold: clientInfo.data?.attributes?.sold,
            blacklisted: clientInfo.data?.attributes?.blacklisted,
            isPassportInvalid: clientInfo.data?.attributes?.isPassportInvalid,
          }, clientInfo.data?.workAdditionalIncome)
        );
        ctx.dispatch(new ProfileActions.LoadEditableFields());
        ctx.dispatch(new EventsActions.SetWebbankirCrossId(clientInfo.data?.attributes?.webbankirCrossId));    
      }),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.LoadFail', err);
        return ctx.dispatch(new ProfileActions.LoadFail(err.error?.errors || []));
      })
    );
  }

  /**
   * Действие для успешной загрузки профиля пользователя
   *
   * Порядок выполнения:
   * - Обновить состояние данных о профиле, изменить статус запроса данных
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.LoadSuccess) {
    ctx.dispatch(
      new UpdateFormValue({
        path: 'profile.form',
        value: {
          ...ctx.getState()?.form?.model,
          fio: [action.payload.lastName, action.payload.firstName, action.payload.middleName].join(' '),
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          middleName: action.payload.middleName,
          bDay: action.payload.bDay,
          phone: action.payload.mobilePhone ? `${action.payload.mobilePhone}`.substring(1) : null,
          email: action.payload.email,
          bPlace: action.payload.bPlace,
          gender: action.payload.gender,
          passport: action.payload.passport,
          passportDivisionCode: action.payload.passportDivisionCode,
          passportDateOfIssue: action.payload.passportDateOfIssue,
          passportIssuedBy: action.payload.passportIssuedBy,
          passportEsiaStatus: action.payload.passportEsiaStatus,
          address: action.payload.address
            ? {
                value: {
                  postal_code: action.payload.address.postalCode,
                  region_kladr_id: action.payload.address.region,
                  city: action.payload.address.city,
                  settlement: action.payload.address.settlement,
                  street: action.payload.address.street,
                  house: action.payload.address.house,
                  geo_lat: action.payload.address.geoLat,
                  geo_lon: action.payload.address.geoLon,
                  block: [action.payload.address.housing, action.payload.address.building]
                    .filter((e) => !!e)
                    .join(' стр '),
                  block_type: action.payload.address.housing ? 'к' : action.payload.address.building ? 'стр' : null,
                  flat: action.payload.address.flat,
                  region_fias_id: action.payload.addressFiases.region_fias_id,
                  city_fias_id: action.payload.addressFiases.city_fias_id,
                  settlement_fias_id: action.payload.addressFiases.settlement_fias_id,
                  street_fias_id: action.payload.addressFiases.street_fias_id,
                  house_fias_id: action.payload.addressFiases.house_fias_id,
                },
                label: action.payload.addressFiases.full_value,
              }
            : null,
          addressRegDate: action.payload.addressRegDate,
          addressFiases: action.payload.addressFiases,
          doNotHaveStreet: action.payload.addressFiases ? !action.payload.addressFiases.street_fias_id : null,
          doNotHaveFlat: action.payload.address ? !action.payload.address.flat : null,
          snilsOrInn: action.payload.snilsOrInn,
          inn: action.payload.inn,
          snils: action.payload.snils,
          typeOfEmployment: action.payload.typeOfEmployment ? { value: action.payload.typeOfEmployment } : null,
          workSalary: action.payload.workSalary,
          workAdditionalIncome: action.payload.workAdditionalIncome,
          workType: action.payload.workType,
          workOgrnip: action.payload.workOgrnip,
          workRegDate: action.payload.workRegDate,
          workScope: action.payload.workScope ? { value: action.payload.workScope } : null,
          workNumberOfEmployees: action.payload.workNumberOfEmployees
            ? { value: action.payload.workNumberOfEmployees }
            : null,
          workPeriod: action.payload.workPeriod ? { value: action.payload.workPeriod } : null,
          workName:
            action.payload.typeOfEmployment === WorkType.Businessperson
              ? action.payload.workFullName
              : action.payload.workName,
          workAddress:
            action.payload.workAddress && action.payload.workAddressFiases?.value
              ? {
                  value: {
                    postal_code: action.payload.workAddress.postalCode,
                    region_kladr_id: action.payload.workAddress.region,
                    city: action.payload.workAddress.city,
                    settlement: action.payload.workAddress.settlement,
                    street: action.payload.workAddress.street,
                    house: action.payload.workAddress.house,
                    geo_lat: action.payload.workAddress.geoLat,
                    geo_lon: action.payload.workAddress.geoLon,
                    block: [action.payload.workAddress.housing, action.payload.workAddress.building]
                      .filter((e) => !!e)
                      .join(' стр '),
                    block_type: action.payload.workAddress.housing
                      ? 'к'
                      : action.payload.workAddress.building
                      ? 'стр'
                      : null,
                    flat: action.payload.workAddress.flat,
                    region_fias_id: action.payload.workAddressFiases.region_fias_id,
                    city_fias_id: action.payload.workAddressFiases.city_fias_id,
                    settlement_fias_id: action.payload.workAddressFiases.settlement_fias_id,
                    street_fias_id: action.payload.workAddressFiases.street_fias_id,
                  },
                  label: action.payload.workAddressFiases.value,
                }
              : null,
          maritalStatus: action.payload.maritalStatus ? { value: action.payload.maritalStatus } : null,
          loansPurpose: action.payload.loansPurpose ? { value: action.payload.loansPurpose } : null,
          mandatoryPaymentsAmount: action.payload.mandatoryPaymentsAmount ?? null,
          hasChildren: !!action.payload.numberOfChildren || false,
          numberOfChildren: action.payload.numberOfChildren || null,
          educationType: action.payload.educationType ? { value: action.payload.educationType } : null,
          workFullName: action.payload.workFullName,
          workPhone: action.payload.workPhone,
          workInn: action.payload.workInn,
          workPosition: action.payload.workPosition,
          officialState: action.payload.publicity?.officialState
            ? action.payload.publicity?.officialOwnerIsMe
              ? 1
              : 2
            : 0,
          officialName: action.payload.publicity?.officialName,
          internationalOrganizationState: action.payload.publicity?.internationalOrganizationState
            ? action.payload.publicity?.internationalOrganizationOwnerIsMe
              ? 1
              : 2
            : 0,
          internationalOrganizationName: action.payload.publicity?.internationalOrganizationName,
          officialRussiaState: action.payload.publicity?.officialRussiaState
            ? action.payload.publicity?.officialRussiaOwnerIsMe
              ? 1
              : 2
            : 0,
          officialRussiaName: action.payload.publicity?.officialRussiaName,
          benefitsOfAnotherPerson: action.payload.publicity?.benefitsOfAnotherPerson,
          beneficial: {
            state: action.payload.beneficial?.state ? 1 : 0,
            fio: [
              action.payload.beneficial?.lastName,
              action.payload.beneficial?.firstName,
              action.payload.beneficial?.middleName,
            ]
              .filter((i) => !!i)
              .join(' '),
            passport: action.payload.beneficial?.passport,
            passportDateOfIssue: action.payload.beneficial?.passportDateOfIssue,
            passportDivisionCode: action.payload.beneficial?.passportDivisionCode,
          },
          additionalPhone: action.payload.additionalPhone,
          additionalPhoneOwner: action.payload.additionalPhone,
        },
      })
    );

    if (action.payload.modalInfo) {
      ctx.setState(
        patch({
          modalInfo: action.payload.modalInfo,
        }),
      );
    }

    ctx.setState(
      patch({
        info: {
          id: action.payload.id,
          problems: action.payload.problems,
          sold: action.payload.sold,
          blacklisted: action.payload.blacklisted,
          editableFields: action.payload.editableFields,
          brands: action.payload.brands,
          percent: action.payload.percent,
          login: action.payload.login,
          emailConfirmed: action.payload.emailConfirmed,
          deleted: action.payload.deleted,
          blocked: action.payload.blocked,
          fraudster: action.payload.fraudster,
          hasPreviousConviction: action.payload.hasPreviousConviction,
          sourceOfInformation: action.payload.sourceOfInformation,
          storageKey: action.payload.storageKey,
          isPassportInvalid: action.payload.isPassportInvalid,
          workAdditionalIncome: action.workAdditionalIncome,
        } as IProfileInfo,
        status: RequestStatus.Load,
        deletedAt: action.payload.deletedAt ,
      })
    );

    this.jivoChat.signin();
  }

  /**
   * Действие для неуспешной загрузки профиля пользователя
   *
   * Порядок выполнения:
   * - Очистить данные профиля, изменить статус запроса данных
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadFail)
  public loadFail(ctx: StateContext<IProfileState>, action: ProfileActions.LoadFail) {
    ctx.patchState({
      status: RequestStatus.Error,
      form: null,
      info: null,
    });

    this.catchError(action.payload);
  }

  /**
   * Действие для загрузки информации о наличии социальных сетей пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#getSocialNetworks}
   * - В случае успешного ответа - передаем [данные]{@link ProfileActions#LoadSocialNetworkSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#LoadSocialNetworkFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadSocialNetwork)
  public loadSocialNetwork(ctx: StateContext<IProfileState>, action: ProfileActions.LoadSocialNetwork) {
    return this.service.getSocialNetworks().pipe(
      tap((response) => ctx.dispatch(new ProfileActions.LoadSocialNetworkSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.LoadSocialNetworkFail', err);
        return ctx.dispatch(new ProfileActions.LoadSocialNetworkFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки информации о наличии социальных сетей пользователя
   *
   * Порядок выполнения:
   * - Обновление в состоянии данных о социальных сетях пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadSocialNetworkSuccess)
  public loadSocialNetworkSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.LoadSocialNetworkSuccess) {
    ctx.patchState({
      networks: action.payload,
    });
  }

  /**
   * Действие для неуспешной загрузки информации о наличии социальных сетей пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadSocialNetworkFail)
  public loadSocialNetworkFail(ctx: StateContext<IProfileState>, action: ProfileActions.LoadSocialNetworkFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для загрузки фотографий паспорта и пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#verify}
   * - В случае успешного ответа - обращение к [действию]{@link ProfileActions#VerifySuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#VerifyFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.Verify)
  public verify(ctx: StateContext<IProfileState>, action: ProfileActions.Verify) {
    const userId = this.store.selectSnapshot((state) => state.Auth?.userId);

    return this.service.verify(userId, action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new EventsActions.AddMetric({ status_code: response.status, action: MetricType.UserVerify }));
        ctx.dispatch(new ProfileActions.VerifySuccess());
      }),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.VerifyFail', err);
        ctx.dispatch(new EventsActions.AddMetric({ status_code: err.status, action: MetricType.UserVerify }));
        ctx.dispatch(new ProfileActions.VerifyFail(err.error?.errors));
        return throwError(() => err);
      })
    );
  }

  /**
   * Действие для неуспешной загрузки фотографий паспорта и пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.VerifyFail)
  public verifyFail(ctx: StateContext<IProfileState>, action: ProfileActions.VerifyFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешной загрузки фотографий паспорта и пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.VerifySuccess)
  public verifySuccess(ctx: StateContext<IProfileState>, action: ProfileActions.VerifySuccess) {
  }

  /**
   * Действие для предлагаемых вариантов заполнения раздела профиля пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#suggestion}
   * - В случае успешного ответа - передаем [данные]{@link ProfileActions#SuggestionSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#/Fail} ошибку
   *
   * @param ctx
   * @param action
   */

  @Action(ProfileActions.Suggestion)
  public suggestion(ctx: StateContext<IProfileState>, action: ProfileActions.Suggestion) {
    return this.service.suggestion(action.payload.type, action.payload.value).pipe(
      tap((response) =>
        ctx.dispatch(
          new ProfileActions.SuggestionSuccess(
            response.result?.map((item) => ({
              value: item.data,
              label: item.value,
            })),
            action.payload.type
          )
        )
      ),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.SuggestionFail', err);
        return ctx.dispatch(new ProfileActions.SuggestionFail(err.error?.errors || []));
      })
    );
  }

  /**
   * Действие для изменения данных профиля пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#editUserProfile}
   * - Обращение к [действию]{@link ProfileActions#Load} для загрузки данных о профиле
   * - В случае успешного ответа - обращение к [действию]{@link ProfileActions#ChangeProfileSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#ChangeProfileFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeProfile)
  public changeProfile(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeProfile) {
    return this.service.editUserProfile(action.payload).pipe(
      switchMap(() => ctx.dispatch(new ProfileActions.Load())),
      tap((response) => ctx.dispatch(new ProfileActions.ChangeProfileSuccess(action.successNotification))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.ChangeProfileFail', err);
        return ctx.dispatch(new ProfileActions.ChangeProfileFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного изменения данных профиля пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешном изменении профиля
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeProfileSuccess)
  public changeProfileSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeProfileSuccess) {
    if (action.payload) {
      this.toast.notification({
        ...action.payload,
        type: ToastType.Success,
      });
    }
  }

  /**
   * Действие для неуспешного изменения данных профиля пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeProfileFail)
  public changeProfileFail(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeProfileFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#changeUserPassword}
   * - В случае успешного ответа - обращение к [действию]{@link ProfileActions#ChangePasswordSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#ChangeProfileFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePassword)
  public changePassword(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePassword) {
    return this.service.changeUserPassword(action.payload).pipe(
      tap((response) => ctx.dispatch(new ProfileActions.ChangePasswordSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.ChangePasswordFail', err);
        return ctx.dispatch(new ProfileActions.ChangePasswordFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешном изменении пароля
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePasswordSuccess)
  public changePasswordSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePasswordSuccess) {
    this.toast.notification({
      title: 'Успех',
      text: 'Пароль успешно изменен',
      type: ToastType.Success,
    });
  }

  /**
   * Действие для неуспешного изменения пароля пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePasswordFail)
  public changePasswordFail(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePasswordFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для проверки нового номера телефона пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#checkNewPhone}
   * - В случае успешного ответа - передаем [данные]{@link ProfileActions#CheckPhoneSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#CheckPhoneFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.CheckPhone)
  public checkPhone(ctx: StateContext<IProfileState>, action: ProfileActions.CheckPhone) {
    return this.service.checkNewPhone(action.payload).pipe(
      tap((response) =>
        ctx.dispatch(new ProfileActions.CheckPhoneSuccess({ response, phone: action.payload.mobilePhone }))
      ),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.CheckPhoneFail', err);
        return ctx.dispatch(new ProfileActions.CheckPhoneFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной проверки нового номера телефона пользователя
   *
   * Порядок выполнения:
   * - Изменяем состояние данных номера телефона пользователя
   * - Обращение к [действию]{@link Navigate} для перехода в профиль пользователя на страницу ввода кода подтверждения
   * - Обращение к [действию]{@link ProfileActions#SendCodeConfirmation} для отправки кода подтверждения
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.CheckPhoneSuccess)
  public checkPhoneSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.CheckPhoneSuccess) {
    ctx.patchState({
      phone: action.payload.phone,
    });
    ctx.dispatch(new Navigate(['cabinet', 'profile', 'code-confirmation']));
    ctx.dispatch(new ProfileActions.SendCodeConfirmation());
  }

  /**
   * Действие для неуспешной проверки нового номера телефона пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.CheckPhoneFail)
  public checkPhoneFail(ctx: StateContext<IProfileState>, action: ProfileActions.CheckPhoneFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для запроса кода подтверждения
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#changePhone}
   * - В случае успешного ответа - обращение к [действию]{@link ProfileActions#SendCodeConfirmationSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#SendCodeConfirmationFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.SendCodeConfirmation)
  public sendCodeConfirmation(ctx: StateContext<IProfileState>, action: ProfileActions.SendCodeConfirmation) {
    const state = ctx.getState();
    return this.service.changePhone({ mobilePhone: state.phone }).pipe(
      tap((response) => ctx.dispatch(new ProfileActions.SendCodeConfirmationSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.SendCodeConfirmationFail', err);
        return ctx.dispatch(new ProfileActions.SendCodeConfirmationFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного запроса кода подтверждения
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link Navigate} для перехода в профиль пользователя на страницу ввода кода подтверждения
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.SendCodeConfirmationSuccess)
  public sendCodeConfirmationSuccess(
    ctx: StateContext<IProfileState>,
    action: ProfileActions.SendCodeConfirmationSuccess
  ) {
    ctx.dispatch(new Navigate(['cabinet', 'profile', 'code-confirmation']));
  }

  /**
   * Действие для неуспешного запроса кода подтверждения
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.SendCodeConfirmationFail)
  public sendCodeConfirmationFail(ctx: StateContext<IProfileState>, action: ProfileActions.SendCodeConfirmationFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для изменения номера телефона пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#changePhone}
   * - В случае успешного ответа - передаем [данные]{@link ProfileActions#ChangePhoneSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#ChangePhoneFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePhone)
  public changePhone(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePhone) {
    const state = ctx.getState();
    return this.service.changePhone({ mobilePhone: state.phone, smsCode: action.payload.smsCode }).pipe(
      tap((response) => ctx.dispatch(new ProfileActions.ChangePhoneSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.ChangePhoneFail', err);
        return ctx.dispatch(new ProfileActions.ChangePhoneFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного изменения номера телефона пользователя
   *
   * Порядок выполнения:
   * - изменение состояние поля mobilePhone в профиле
   * - Вывод информационного сообщения об успешном изменении
   * - Обращение к [действию]{@link Navigate} для перехода в профиль пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePhoneSuccess)
  public changePhoneSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePhoneSuccess) {
    const state = ctx.getState();
    ctx.dispatch(
      new UpdateFormValue({
        path: 'profile.form',
        value: {
          ...state.form?.model,
          phone: `${state.phone}`.substring(1),
        },
      })
    );

    this.toast.notification({
      title: 'Успех',
      text: action.payload.data.message,
      type: ToastType.Success,
    });
    ctx.dispatch(new Navigate(['cabinet', 'profile']));
  }

  /**
   * Действие для неуспешного изменения номера телефон
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangePhoneFail)
  public changePhoneFail(ctx: StateContext<IProfileState>, action: ProfileActions.ChangePhoneFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для изменения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#editUserEmail}
   * - В случае успешного ответа - обращение к [действию]{@link ProfileActions#ChangeEmailSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#ChangeEmailFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeEmail)
  public changeEmail(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeEmail) {
    return this.service.editUserEmail(action.payload).pipe(
      tap((response) => ctx.dispatch(new ProfileActions.ChangeEmailSuccess(response))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.ChangeEmailFail', err);
        return ctx.dispatch(new ProfileActions.ChangeEmailFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного изменения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Вывод информационного сообщения об успешном изменении
   * - Обращение к [действию]{@link ProfileActions#Load} для загрузки данных профиля пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeEmailSuccess)
  public changeEmailSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeEmailSuccess) {
    let message = action.payload.data.message;
    if (action.payload.data.code === 'emailWithRestoreLinkSuccessfullySend') {
      message = 'Email успешно изменен';
    }

    this.toast.notification({
      title: 'Успех',
      text: message,
      type: ToastType.Success,
      timeout: 5000,
    });

    ctx.dispatch(new ProfileActions.Load());
  }

  /**
   * Действие для неуспешного изменения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ChangeEmailFail)
  public changeEmailFail(ctx: StateContext<IProfileState>, action: ProfileActions.ChangeEmailFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для подвтверждения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#editUserEmail}
   * - В случае успешного ответа - изменяем состояние данных профиля, обращение к [действию]{@link ProfileActions#ConfirmEmailSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#ConfirmEmailFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ConfirmEmail)
  public confirmEmail(ctx: StateContext<IProfileState>, action: ProfileActions.ConfirmEmail) {
    return this.service.editUserEmail(action.payload).pipe(
      tap((response) => {
        const state = ctx.getState();
        ctx.dispatch(
          new UpdateFormValue({
            path: 'profile.form',
            value: {
              ...state.form?.model,
              ...action.payload,
            },
          })
        );
        ctx.dispatch(new ProfileActions.ConfirmEmailSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.ConfirmEmailFail', err);
        return ctx.dispatch(new ProfileActions.ConfirmEmailFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешного подтверждения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Обращение к [действию]{@link ModalActions#OpenModal} для открытия модалки с информацией об отправленном письме на почту
   * и возможностью запросить письмо еще раз
   * - Вывод информационного сообщения об успешном изменении
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ConfirmEmailSuccess)
  public confirmEmailSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.ConfirmEmailSuccess) {
    ctx.dispatch(
      new ModalActions.OpenModal({
        route: ['confirm-email', 'send-email'],
      })
    );
    this.toast.notification({
      title: 'Успех',
      text: action.payload.data.message,
      type: ToastType.Success,
      timeout: 5000,
    });
  }

  /**
   * Действие для неуспешного подтверждения электронной почты пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ConfirmEmailFail)
  public confirmEmailFail(ctx: StateContext<IProfileState>, action: ProfileActions.ConfirmEmailFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для загрузки возможных для редактирования полей в профиле пользователя
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link ProfileService#getEditableFields}
   * - В случае успешного ответа - передаем [данные]{@link ProfileActions#LoadEditableFieldsSuccess}
   * - В случае неуспешного ответа - [обрабатываем]{@link ProfileActions#LoadEditableFieldsFail} ошибку
   *
   * @param ctx
   * @param action
   */

  @Action(ProfileActions.LoadEditableFields, { cancelUncompleted: true })
  public loadEditableFields(ctx: StateContext<IProfileState>, action: ProfileActions.LoadEditableFields) {
    ctx.setState(
      patch({
        info: patch({
          editableFields: null,
        }),
      })
    );

    const userId = this.store.selectSnapshot((state) => state.Auth?.userId);

    return this.service.getEditableFields(userId).pipe(
      tap((response) => ctx.dispatch(new ProfileActions.LoadEditableFieldsSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('ProfileActions.LoadEditableFieldsFail', err);
        return ctx.dispatch(new ProfileActions.LoadEditableFieldsFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки возможных для редактирования полей в профиле пользователя
   *
   * Порядок выполнения:
   * - Обновляем данные возможных для редактирования полей в профиле пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadEditableFieldsSuccess)
  public loadEditableFieldsSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.LoadEditableFieldsSuccess) {
    const attributes = [
      ...action.payload?.attributes.reduce((prev, cur) => {
        EditableFieldsExtention[cur]?.forEach((field) => prev.add(field)) || prev.add(cur);
        return prev;
      }, new Set<string>()),
    ];
    ctx.setState(
      patch({
        info: patch({
          editableFields: {
            ...action.payload,
            attributes,
          },
        }),
      })
    );
  }

  /**
   * Действие для неуспешной загрузки возможных для редактирования полей в профиле пользователя
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.LoadEditableFieldsFail)
  public loadEditableFieldsFail(ctx: StateContext<IProfileState>, action: ProfileActions.LoadEditableFieldsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   */
  @Action(ProfileActions.Reset)
  public reset(ctx: StateContext<IProfileState>) {
    ctx.setState(PROFILE_STATE_DEFAULTS);
  }

  /**
   * Действие для сброса данных модального окна редактирования профиля к значению по умолчанию
   *
   * @param ctx
   * @param action
   */
  @Action(ProfileActions.ResetModalInfo)
  public resetModalInfo(ctx: StateContext<IProfileState>, action: ProfileActions.ResetModalInfo) {
    ctx.patchState({ modalInfo: null });
  }

  /**
   * Действие показа модального окна с данными modalInfo
   *
   * @param ctx
   */
  @Action(ProfileActions.ShowChangeModal)
  public openChangeModal(ctx: StateContext<IProfileState>, action: ProfileActions.ShowChangeModal) {
    const { modalInfo } = ctx.getState();

    if (modalInfo) {
      ctx.dispatch(
        new ModalActions.OpenModal({
          route: MODAL_INFO_ROUTES_MAP.get(modalInfo.type),
          events: this.getModalInfoEvents(modalInfo.type),
          data: {
            loanId: action.payload
          }
        }),
      );
    }
  }

  /**
   * Действие загрузка причин удаления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.LoadReasons)
  public LoadReasons(ctx: StateContext<IProfileState>, action: ProfileActions.LoadReasons) {
    return this.service.LoadReasons().pipe(
      tap((response) => ctx.dispatch(new ProfileActions.LoadReasonsSuccess(response.data))),
      catchError((err, caught) => {
        return ctx.dispatch(new ProfileActions.LoadReasonsFail(err.error?.errors));
      }),
    );
  }

  /**
   * Действие удачная загрузка причин удаления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.LoadReasonsSuccess)
  public loadReasonsSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.LoadReasonsSuccess) {
  }

  /**
   * Действие неудачная загрузка причин удаления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.LoadReasonsFail)
  public loadReasonsFail(ctx: StateContext<IProfileState>, action: ProfileActions.LoadReasonsFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие восстановления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.RecoverProfile)
  public recoverProfile(ctx: StateContext<IProfileState>) {
    const userId = this.store.selectSnapshot((state) => state.Auth?.userId);
    
    return this.service.recoverProfile(userId).pipe(
      tap((response) => {
        ctx.dispatch(new ProfileActions.RecoverProfileSuccess());
      }),
      catchError((err) => {
        return ctx.dispatch(new ProfileActions.RecoverProfileFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие успешного восстановления профиля
   * 
   * @param ctx
   */
  @Action(ProfileActions.RecoverProfileSuccess) 
  public recoverProfileSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.RecoverProfileSuccess) {
  }

  /**
   * Действие неуспешного восстановления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.RecoverProfileFail)
  public recoverProfileFail(ctx: StateContext<IProfileState>, action: ProfileActions.RecoverProfileFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие удаления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.DeleteProfile)
  public deleteProfile(ctx: StateContext<IProfileState>, action: ProfileActions.DeleteProfile) {
    const userId = this.store.selectSnapshot((state) => state.Auth?.userId);
    
    return this.service.deleteProfile(userId, action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new ProfileActions.DeleteProfileSuccess(response.data?.attributes?.deletedAt));
      }),
      catchError((err) => {
        return ctx.dispatch(new ProfileActions.DeleteProfileFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие успешного удаления профиля
   * 
   * @param ctx
   */
  @Action(ProfileActions.DeleteProfileSuccess) 
  public deleteProfileSuccess(ctx: StateContext<IProfileState>, action: ProfileActions.DeleteProfileSuccess) {
    ctx.setState(
      patch({
        deletedAt: action.payload,
      }),
    );
  }

  /**
   * Действие неуспешного удаления профиля
   *
   * @param ctx
   */
  @Action(ProfileActions.DeleteProfileFail)
  public deleteProfileFail(ctx: StateContext<IProfileState>, action: ProfileActions.DeleteProfileFail) {
    this.catchError(action.payload);
  }

  /** Метод обработки событий модального окна в зависимости от типа
   *
   * @param modalType - тип модального окна
   */
  private getModalInfoEvents(modalType: ModalInfoType): { [key in ModalEvent]?: IModalEventFn } {
    switch (modalType) {
      case ModalInfoType.WorkSalaryChanged:
        return {
          [ModalEvent.onClose]: (payload, closedBy: ModalClose) => {
            const type = {
              [ModalClose.X_CLICK]: 'via_cross_web',
              [ModalClose.CUSTOM]: 'confirmed_without_changes_web',
              [ModalClose.OUTSIDE_CLICK]: 'via_outside_click_web',
            };
            if (type[closedBy]) {
              this.store.dispatch(
                new EventsActions.AddMetric(
                  {
                    type: type[closedBy],
                  },
                  EventName.IncomeModalWindowClosed
                )
              );
            }
            this.store.dispatch(new ProfileActions.ResetModalInfo())
          },
        };
      default: 
        return {
          [ModalEvent.onClose]: (payload, closedBy: ModalClose) => {
            this.store.dispatch(new ProfileActions.ResetModalInfo())
          }
        }
    }
  }
}
