import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAutoLoginResponse, ILoginResponse } from '../../../interfaces/login-response.interface';
import { ILoginPayload } from '../interfaces/login-payload.interface';
import { IPasswordRecoveryCheckPayload } from '../interfaces/password-recovery-check-payload.interface';
import { IPasswordRecoveryPayload } from '../interfaces/password-recovery-payload.interface';
import { IPasswordRecoverySuccessResponse } from '../interfaces/password-recovery-success-response.interface';
import { IPasswordRecoveryConfirmResponse } from '../interfaces/password-recovery-confirm-response.interface';
import { IResetPasswordPayload } from '../interfaces/reset-password-payload.interface';
import { IResetPasswordResponse } from '../interfaces/reset-password-response.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IPasswordRecoveryMethod } from '../interfaces/password-recovery-method.interface';
import { IRegistrationApplicationDto } from '@app/states/registration/interfaces/registration-application-dto.interface';
import { CookieService } from '@app/services/cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly loginPath: string;
  private readonly passwordRecoveryCheckPath: string;
  private readonly passwordRecoveryPath: string;
  private readonly resetPasswordPath: string;
  private readonly autologinPath: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    @Inject('Window') protected window: Window,
  ) {
    this.loginPath = `${this.environment.api}user/login`;
    this.autologinPath = `${this.environment.api}autologin/get`;
    this.passwordRecoveryCheckPath = `${this.environment.api}user/v2/password-recovery/check`;
    this.passwordRecoveryPath = `${this.environment.api}user/v2/password-recovery`;
    this.resetPasswordPath = `${this.environment.api}user/password/reset`;
  }

  public loginRequest(payload: ILoginPayload): Observable<IDataResponse<ILoginResponse>> {
    return this.http.post<IDataResponse<ILoginResponse>>(this.loginPath, payload);
  }

  public autologinRequest(hash: string): Observable<IDataResponse<IAutoLoginResponse>> {
    return this.http.post<IDataResponse<IAutoLoginResponse>>(this.autologinPath, { hash });
  }

  public emailConfirm(emailHash: string): Observable<any> {
    return this.http.post(`${this.environment.api}user/edit/email/confirm`, { emailHash });
  }

  public passwordRecoveryCheck(
    payload: IPasswordRecoveryCheckPayload,
  ): Observable<IDataResponse<IPasswordRecoveryMethod[]>> {
    return this.http.post<IDataResponse<IPasswordRecoveryMethod[]>>(this.passwordRecoveryCheckPath, payload);
  }

  public passwordRecovery(
    payload: IPasswordRecoveryPayload,
  ): Observable<HttpResponse<IPasswordRecoverySuccessResponse | IPasswordRecoveryConfirmResponse>> {
    return this.http.post(this.passwordRecoveryPath, payload, { observe: 'response' }) as Observable<
      HttpResponse<IPasswordRecoverySuccessResponse | IPasswordRecoveryConfirmResponse>
    >; // TODO is needed HttpResponse as generic ?
  }

  public resetPasswordHash(data: { password: string; emailHash: string}): Observable<any> {
    return this.http.post(`${this.environment.api}user/password/change/outside`, data);
  }

  public resetPassword(payload: IResetPasswordPayload): Observable<IResetPasswordResponse> {
    return this.http.post<IResetPasswordResponse>(this.resetPasswordPath, payload);
  }

  public esiaData(key: string): Observable<IDataResponse<ILoginResponse> | IRegistrationApplicationDto> {
    return this.http.post<IDataResponse<ILoginResponse> | IRegistrationApplicationDto>(
      `${this.environment.api}user/esia/get`,
      { key, referer: CookieService.referrer },
    );
  }
}
