import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { ModalEvent } from '../constants/modal-event.const';
import { IModalConfig } from '../interfaces/modal-config.interface';
import { IModalState } from './modal-state.interface';
import { MODAL_STATE_DEFAULTS } from './modal-states-defaults.const';
import { ModalActions } from './modal.actions';

@State<IModalState>({
  name: 'Modal',
  defaults: MODAL_STATE_DEFAULTS,
})
@Injectable()
export class ModalState {
  private modalsQueue: IModalConfig[] = [];

  constructor() {
  }

  @Action(ModalActions.OpenModal)
  public openModal(ctx: StateContext<IModalState>,
                   action: ModalActions.OpenModal) {
    if (this.modalsQueue.push(action.payload) === 1) {
      ctx.patchState({
        data: action.payload.data,
      });
      ctx.dispatch(new Navigate(
        [{ outlets: { modal: ['modal', ...action.payload.route] } }],
        action.payload.params,
        { skipLocationChange: true },
      ));
      ctx.dispatch(new ModalActions.BindEvent(ModalEvent.onClose, action.payload.events?.onClose));
      this.modalsQueue.shift();
    }
  }

  @Action(ModalActions.BindEvent)
  public bindEvent(ctx: StateContext<IModalState>, action: ModalActions.BindEvent) {
    ctx.patchState({
      [action.event]: action.payload,
    });
  }

  @Action(ModalActions.CloseModal)
  public closeModal(ctx: StateContext<IModalState>, action: ModalActions.CloseModal) {
    const { onClose } = ctx.getState();
    ctx.dispatch(new Navigate([{ outlets: { modal: null } }]))
      .subscribe(() => {
        if (!!this.modalsQueue[0]) {
          const nextModal = this.modalsQueue[0];
          ctx.patchState({
            data: nextModal.data,
          });
          ctx.dispatch(new Navigate(
            [{ outlets: { modal: ['modal', ...nextModal.route] } }],
            nextModal.params,
            { skipLocationChange: true },
          ));
          ctx.dispatch(new ModalActions.BindEvent(ModalEvent.onClose, nextModal.events?.onClose));
        } else {
          ctx.patchState({
            data: MODAL_STATE_DEFAULTS.data,
          });
        }

        onClose?.(action.payload, action.closedBy);
      });
  }

  @Action(ModalActions.Reset)
  public reset(ctx: StateContext<IModalState>, action: ModalActions.Reset) {
    ctx.setState({
      ...MODAL_STATE_DEFAULTS,
    });
  }

}
