export enum VirtualCardActionList {
  LOAD_STATE = '[VirtualCard] load state',
  LOAD_STATE_SUCCESS = '[VirtualCard] load state success',
  LOAD_STATE_FAIL = '[VirtualCard] load state fail',

  CONTEXT = '[VirtualCard] context',
  CONTEXT_SUCCESS = '[VirtualCard] context success',
  CONTEXT_FAIL = '[VirtualCard] context fail',

  CREATE = '[VirtualCard] create',
  CREATE_SUCCESS = '[VirtualCard] create success',
  CREATE_FAIL = '[VirtualCard] create fail',

  HIDE_NEW_CARD = '[VirtualCard] hide new card',

  TOKEN = '[VirtualCard] token',
  TOKEN_SUCCESS = '[VirtualCard] token success',
  TOKEN_FAIL = '[VirtualCard] token fail',

  AUTH_CODE = '[VirtualCard] auth code',
  AUTH_CODE_SUCCESS = '[VirtualCard] auth code success',
  AUTH_CODE_FAIL = '[VirtualCard] auth code fail',

  BLOCK = '[VirtualCard] block',
  BLOCK_SUCCESS = '[VirtualCard] block success',
  BLOCK_FAIL = '[VirtualCard] block fail',

  REISSUE = '[VirtualCard] reissue',
  REISSUE_SUCCESS = '[VirtualCard] reissue success',
  REISSUE_FAIL = '[VirtualCard] reissue fail',

  REISSUE_STATUS = '[VirtualCard] reissue status',
  REISSUE_STATUS_SUCCESS = '[VirtualCard] reissue status success',
  REISSUE_STATUS_FAIL = '[VirtualCard] reissue status fail',

  OPERATIONS = '[VirtualCard] operations',
  OPERATIONS_SUCCESS = '[VirtualCard] operations success',
  OPERATIONS_FAIL = '[VirtualCard] operations fail',

  CARD_DATA = '[VirtualCard] card data',
  CARD_DATA_SUCCESS = '[VirtualCard] card data success',
  CARD_DATA_FAIL = '[VirtualCard] card data fail',

  CHANGE_PIN = '[VirtualCard] change pin',
  CHANGE_PIN_SUCCESS = '[VirtualCard] change pin success',
  CHANGE_PIN_FAIL = '[VirtualCard] change pin fail',

  TOP_UP = '[VirtualCard] top up',
  TOP_UP_SUCCESS = '[VirtualCard] top up success',
  TOP_UP_FAIL = '[VirtualCard] top up fail',

  TRANSFER = '[VirtualCard] transfer',
  TRANSFER_SUCCESS = '[VirtualCard] transfer success',
  TRANSFER_FAIL = '[VirtualCard] transfer fail',

  INIT_WIDGET = '[VirtualCard] init widget',

  RESET = '[VirtualCard] reset',
}
