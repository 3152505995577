export enum ILSApplicationState {
  Draft = 'draft', // Черновик
  Scoring = 'scoring', // Автоматическое рассмотрение
  Underwriting = 'underwriting', // Ручное рассмотрение
  Info = 'info', // Запрос информации
  Approved = 'approved', // Одобрена
  Rejected = 'rejected', // Отклонена
  Revoked = 'revoked', // Клиент отказался
  Expired = 'expired', // Истёк срок подписания
  Completed = 'completed', // Выполнена
}
