import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAppealsResponse } from '../interfaces/appeals-response.interface';

/**
 * Класс сервиса запросов к бэкэнду обращений пользователя
 */
@Injectable({
  providedIn: 'root',
})
export class AppealsService {
  /**
   * URL для запросов к бэкэнду
   */
  private readonly path: string;

  /**
   * Конструктор сервиса запросов к бэкэнду обращений пользователя
   *
   * @param http [Сервис]{@link HttpClient} для обработки HTTP запросов
   * @param environment Данные окружения приложения
   */
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.path = `${this.environment.api}user/v2/appeal_responses`;
  }

  /**
   * Метод по получению данных от бэкэнда по обращениям пользователя
   * @param pageNumber номер страницы
   * @param attributes количество элементов на странице
   */
  public getAppeals(pageNumber = 1 , pageSize = 20): Observable<IAppealsResponse> {
    return this.http.get<IAppealsResponse>(`${this.path}?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  /**
   * Метод отправки обращения пользователю на почту
   * @param id ID обращения
   */
  public sendToEmail(id: string): Observable<any> {
    return this.http.post<any>(`${this.path}/${id}/send`, { transport: 'email' });
  }

  /**
   * Метод для загрузки обращения для скачивания
   * @param id ID обращения
   */
  public getFile(id: string): Observable<Blob> {
    return this.http.get(`${this.path}/${id}/file`, { responseType: 'blob' });
  }
}
