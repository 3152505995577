import { IBonusState } from './bonus.state.interface';

/**
 * Дефолтное состояние хранилища "Бонусы" при его инициализации
 */
export const BONUS_STATE_DEFAULTS: IBonusState = {
  balanceData: null,
  expire: {
    days: null,
    date: null,
    amount: null,
  },
  transactionHistory: {
    page: null,
    data: null,
  },
};
