import { IError } from '@app/interfaces/error.interface';
import { IAttributes } from '../interfaces/attributes.interface';
import { IGrowthbookDTO } from '../interfaces/growthbook-dto.interface';
import { ExperimentsActionList } from './experiments-action-list.const';

/**
 * Namespace для действий состояния "Эксперименты"
 */
export namespace ExperimentsActions {
  /**
   * Действие для загрузки экспериментов
   */
  export class Load {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ExperimentsActionList = ExperimentsActionList.LOAD;
  }

  /**
   * Действие для успешной загрузки экспериментов
   */
  export class LoadSuccess {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ExperimentsActionList = ExperimentsActionList.LOAD_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Данные экспериментов
     */
    constructor(public payload: Pick<IGrowthbookDTO, 'experiments' | 'features'>) {}
  }

  /**
   * Действие для неуспешной загрузки экспериментов
   */
  export class LoadFail {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ExperimentsActionList = ExperimentsActionList.LOAD_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /**
   * Действие обновления атрибутов эксперимента
   */
  export class UpdateAttributes {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ExperimentsActionList = ExperimentsActionList.UPDATE_ATTRIBUTES;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IAttributes) {}
  }

  /**
   * Сброс
   */
  export class Reset {
    /**
     * Уникальное имя действия
     */
    public static readonly type: ExperimentsActionList = ExperimentsActionList.RESET;
  }
}
