import { Injectable } from '@angular/core';
import { ErrorCode } from '@app/constants/error-code.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { Product } from '@app/states/calculator/constants/product.const';
import { PercentUnit } from '@app/states/product/constants/percent-unit.const';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { RequestStatus } from 'projects/web-bankir-app/src/app/constants/request-status.const';
import { forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CalculatorService } from '../services/calculator.service';
import { CALCULATOR_STATE_DEFAULTS } from './calculator-state-defaults.const';
import { CalculatorActions } from './calculator.actions';
import { ICalculatorState } from './calculator.state.interface';

@State<ICalculatorState>({
  name: 'Calculator',
  defaults: CALCULATOR_STATE_DEFAULTS,
})
/**
 * Класс NGXS состояния калькулятора займа
 */
@Injectable()
export class CalculatorState extends StateErrorHandler {
  /**
   * Конструктор класса состояния калькулятора займа
   *
   * @param store NGXS хранилище
   * @param service [Сервис] {@link CalculatorService} калькулятора займа
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   */
  constructor(protected store: Store, private service: CalculatorService, protected toast: ToastService) {
    super(toast, store);
  }

  @Selector()
  public static isILSEnabled(state: ICalculatorState): boolean {
    return !!state.ils?.data;
  }

  public static periodMax(product: Product) {
    return createSelector(
      [CalculatorState],
      (state: ICalculatorState) => {
        switch (product) {
          case Product.ILS:
            return state.ils?.data?.monthsMax || 6;
          case Product.PDL:
            return state.pdl?.data?.period_max || 31;
        }
      },
      {
        containerClass: 'Calculator',
        selectorName: `periodMax_${product}`,
      }
    );
  }

  public static limit(product: Product) {
    return createSelector([CalculatorState], (state: ICalculatorState) => {
      switch (product) {
        case Product.ILS:
          return {
            min: state.ils?.data?.amountMin,
            max: 49900,
            current: state.ils?.data?.amountMax,
            percent: {
              value: state.ils?.data?.yearRate,
              unit: PercentUnit.Year,
            },
            promo: 0,
          };
        case Product.PDL:
          return {
            min: state.pdl?.data?.sum_min,
            max: state.pdl?.data?.sum_max_website,
            current: state.pdl?.data?.sum_max,
            percent: {
              value: state.pdl?.data?.percent,
              unit: PercentUnit.Day,
            },
            promo: state.pdl?.data?.promo_id,
          };
      }
    });
  }

  /**
   * Действие для загрузки данных калькулятора займа
   *
   * Порядок выполнения:
   * - Получение данных из NGXS хранилища, активен ли продукт ИЛС
   * - Обновление в NGXS состоянии статуса запроса на займ как Pending
   * - Заполнение данных по ПДЛ и ИЛС: запрос к бэкенду {@link CalculatorService#calculatorRequest} для ПДЛ,
   * если продукт ИЛС активен и действие имеет полезную нагрузку запрашивать ли данные калькулятора по ИЛС как true, то
   * вызывается запрос к бэкенду {@link CalculatorService#ils} для ИЛС, иначе значение ИЛС устанавливается как null
   * - В случае успешного ответа - передаем [данные]{@link CalculatorState#LoadSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link CalculatorState#LoadFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.Load)
  public load(ctx: StateContext<ICalculatorState>, action: CalculatorActions.Load) {
    ctx.setState(
      patch<ICalculatorState>({
        status: RequestStatus.Pending,
        pdl: patch({
          data: null,
        }),
        ils: patch({
          data: null,
        }),
      })
    );

    return forkJoin({
      pdl: this.service.calculatorRequest(),
      ils: action.payload ? this.service.ils().pipe(catchError(() => of(null))) : of(null),
    }).pipe(
      map(({ pdl, ils }) =>
        ctx.dispatch(new CalculatorActions.LoadSuccess({ pdl: pdl.data, ils: ils?.data?.attributes }))
      ),
      catchError((err, caught) => {
        this.catchSentryError('CalculatorActions.LoadFail', err);
        return ctx.dispatch(new CalculatorActions.LoadFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки данных калькулятора займа
   *
   * Порядок выполнения:
   * - Обновление данных в состоянии о продуктах ИЛС и ПДЛ на основании полученной полезной нагрузки
   * - Обновление данных в состоянии о статусе запроса в значение Load
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<ICalculatorState>, action: CalculatorActions.LoadSuccess) {
    ctx.setState(
      patch({
        pdl: patch({
          data: action.payload.pdl,
        }),
        ils: patch({
          data: action.payload.ils,
        }),
        status: RequestStatus.Load,
      })
    );
  }

  /**
   * Действие для неуспешной загрузки данных калькулятора займа
   *
   * Порядок выполнения:
   * - Обновление данных в состоянии о статусе запроса в значение Error
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.LoadFail)
  public loadFailed(ctx: StateContext<ICalculatorState>, action: CalculatorActions.LoadFail) {
    ctx.setState(
      patch({
        status: RequestStatus.Error,
      })
    );

    const errors = action.payload?.map((err) => {
      if (err.code === ErrorCode.NotAuthorized) {
        return {
          ...err,
          message: 'Не удалось проверить ключ доступа.',
        };
      } else {
        return err;
      }
    });
    this.catchError(errors);
  }

  /**
   * Действие для заполнения расписания калькулятора ИЛС
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link CalculatorService#ilsSchedule}
   * - В случае успешного ответа - передаем [данные]{@link CalculatorState#ScheduleSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link CalculatorState#ScheduleFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.Schedule)
  public schedule(ctx: StateContext<ICalculatorState>, action: CalculatorActions.Schedule) {
    ctx.setState(
      patch({
        ils: patch({
          schedule: null,
        }),
      })
    );

    return this.service.ilsSchedule(action.payload).pipe(
      tap((response) => ctx.dispatch(new CalculatorActions.ScheduleSuccess(response.data.attributes))),
      catchError((err, caught) => {
        this.catchSentryError('CalculatorActions.ScheduleFail', err);
        return ctx.dispatch(new CalculatorActions.ScheduleFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для неуспешного заполнения расписания калькулятора ИЛС
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.ScheduleFail)
  public scheduleFail(ctx: StateContext<ICalculatorState>, action: CalculatorActions.ScheduleFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для успешного заполнения расписания калькулятора ИЛС
   *
   * Порядок выполнения:
   * - Запись в состояние полученной полезной нагрузки
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.ScheduleSuccess)
  public scheduleSuccess(ctx: StateContext<ICalculatorState>, action: CalculatorActions.ScheduleSuccess) {
    ctx.setState(
      patch({
        ils: patch({
          schedule: action.payload,
        }),
      })
    );
  }

  /**
   * Действие для загрузки PDL калькулятора
   *
   * Порядок выполнения:
   * - Запрос к бэкенду {@link CalculatorService#calculatorRequest}
   * - В случае успешного ответа - передаем [данные]{@link CalculatorActions#LoadPdlSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link CalculatorActions#LoadPdlFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.LoadPdl)
  public loadPdl(ctx: StateContext<ICalculatorState>, action: CalculatorActions.LoadPdl) {
    return this.service.calculatorRequest(action.payload).pipe(
      tap((response) => ctx.dispatch(new CalculatorActions.LoadPdlSuccess(response.data))),
      catchError((err, caught) => {
        this.catchSentryError('CalculatorActions.LoadPdlFail', err);
        return ctx.dispatch(new CalculatorActions.LoadPdlFail(err.error?.errors));
      })
    );
  }

  /**
   * Действие для успешной загрузки PDL калькулятора
   *
   * Порядок выполнения:
   * - Запись в состояние данных калькулятора
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.LoadPdlSuccess)
  public loadPdlSuccess(ctx: StateContext<ICalculatorState>, action: CalculatorActions.LoadPdlSuccess) {
    ctx.setState(
      patch({
        pdl: patch({
          data: action.payload,
        }),
      })
    );
  }

  /**
   * Действие для неуспешной загрузки PDL калькулятора
   *
   * Порядок выполнения:
   * - Обработка ошибки
   *
   * @param ctx
   * @param action
   */
  @Action(CalculatorActions.LoadPdlFail)
  public loadPdlFail(ctx: StateContext<ICalculatorState>, action: CalculatorActions.LoadPdlFail) {
    this.catchError(action.payload);
  }

  /**
   * Действие для сброса данных калькулятора при логауте пользователя
   *
   * Порядок выполнения:
   * - Обновление состояния дефолтными данными
   *
   * @param ctx
   */
  @Action(CalculatorActions.Reset)
  public reset(ctx: StateContext<ICalculatorState>) {
    ctx.setState(CALCULATOR_STATE_DEFAULTS);
  }
}
