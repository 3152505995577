import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGrowthbookDTO } from '../interfaces/growthbook-dto.interface';
import { IGrowthbookRequestDTO } from '../interfaces/growthbook-request-dto.interface';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  public load(data: IGrowthbookRequestDTO): Observable<IGrowthbookDTO> {
    return this.http.post<IGrowthbookDTO>(
      `${this.environment.growthBook.apiHost}/api/eval/${this.environment.growthBook.clientKey}`,
      data
    );
  }
}
