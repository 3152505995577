export enum WorkType {
  HiredEmployee = 1, // 'Наемный сотрудник',
  IndividualEntrepreneur, // 'Индивидуальный предприниматель',
  Businessperson,  // 'Учредитель/участник ООО',
  Pensioner, // 'Пенсионер',
  Student,  // 'Студент',
  Unemployed,  // 'Безработный',
  SelfEmployed, // 'Самозанятый',
  Serviceman, // 'Военнослужащий'
}
