import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

// Sentry
import * as Sentry from '@sentry/angular-ivy';

// dayjs
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { bootstrapApplication } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { AppComponent } from '@app/app.component';
import { APP_CONFIG } from '@app/app.config';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

registerLocaleData(ru, 'ru');

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://084f946843e956474ccd29a65cadf572@sentry-gis.webbankir.team/2',
  environment: environment.name,
  release: environment.release,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['api.test.webbankir.com', 'ng-api.webbankir.com', 'lk.gate.webbankir.com', /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.2,
});

bootstrapApplication(AppComponent, APP_CONFIG).catch((err) => console.error(err));
