import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { catchError, tap } from 'rxjs';
import { IBalanceResponse } from '../interfaces/balance-response.interface';
import { ITransactionHistoryResponse } from '../interfaces/transaction-history-response.interface';
import { BonusAccountsService } from '../services/bonus-accounts/bonus-accounts.service';
import { BONUS_STATE_DEFAULTS } from './bonus-state-defaults.const';
import { BonusActions } from './bonus.actions';
import { IBonusState } from './bonus.state.interface';
import { ITransaction } from '@app/states/bonus/interfaces/transaction.interface';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { ToastService } from '@web-bankir/ui-kit/components/toast';

/**
 * Количество элементов на одной странице
 */
const HISTORY_PAGE_SIZE = 20;

/**
 * Класс NGXS состояния "Бонусы"
 */
@State<IBonusState>({
  name: 'Bonus',
  defaults: BONUS_STATE_DEFAULTS,
})
@Injectable()
export class BonusState extends StateErrorHandler {
  /**
   * Конструктор класса состояния "Бонусы" пользователя
   *
   * @param service [Сервис]{@link BonusAccountsService} бонусов пользователя
   */
  constructor(
    private service: BonusAccountsService,
    protected toast: ToastService,
    protected store: Store,
  ) {
    super(toast, store);
  }

  /**
   * Действие для загрузки бонуного баланса пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link BonusAccountsService#getBalance}
   * - В случае успешного ответа - вызываем [действие]{@link BonusActions#GetUpcomingExpiration}
   * и передаем [модифицированные данные]{@link BonusActions#GetBalanceSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link BonusActions#GetBalanceFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetBalance, { cancelUncompleted: true })
  public getBalance(ctx: StateContext<IBonusState>, action: BonusActions.GetBalance) {
    return this.service.getBalance(action.payload.clientId).pipe(
      tap((res: IBalanceResponse) => {
        if(res.result?.balance) {
          ctx.dispatch(new BonusActions.GetUpcomingExpiration({ clientId: action.payload.clientId }));
        }

        ctx.dispatch(new BonusActions.GetBalanceSuccess(res.result));
      },
      ),
      catchError((err, caught) => {
        this.catchSentryError('BonusActions.GetBalanceFail', err);
        return ctx.dispatch(
          new BonusActions.GetBalanceFail()
        )
      })
    );
  }

  /**
   * Действие для неуспешной загрузки бонуного баланса пользователя
   *
   * Порядок выполнения:
   * - Обнулить данные бонусного баланса
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetBalanceFail)
  public getBalanceFail(ctx: StateContext<IBonusState>,
                        action: BonusActions.GetBalanceFail) {
    ctx.setState(patch({
      balanceData: {
        balance: 0,
      },
    }));
  }

  /**
   * Действие для успешной загрузки бонуного баланса пользователя
   *
   * Порядок выполнения:
   * - Обновить данные бонусного баланса
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetBalanceSuccess)
  public getBalanceSuccess(ctx: StateContext<IBonusState>,
                           action: BonusActions.GetBalanceSuccess) {
    ctx.setState(patch({
      balanceData: action.payload,
    }));
  }

  /**
   * Действие для загрузки информации о сгорании бонусов пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link BonusAccountsService#getUpcomingExpiration}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link BonusActions#GetUpcomingExpirationSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link BonusActions#GetUpcomingExpirationFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetUpcomingExpiration)
  public getUpcomingExpiration(ctx: StateContext<IBonusState>,
                               action: BonusActions.GetUpcomingExpiration) {
    return this.service.getUpcomingExpiration(action.payload.clientId).pipe(
      tap(response => ctx.dispatch(new BonusActions.GetUpcomingExpirationSuccess(response.result))),
      catchError((err, caught) => {
        this.catchSentryError('BonusActions.GetUpcomingExpirationFail', err);
        return ctx.dispatch(
          new BonusActions.GetUpcomingExpirationFail()
        )
      })
    );
  }

  /**
   * Действие для неуспешной загрузки информации о сгорании бонусов пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetUpcomingExpirationFail)
  public getUpcomingExpirationFail(ctx: StateContext<IBonusState>,
                                   action: BonusActions.GetUpcomingExpirationFail) {
  }

  /**
   * Действие для успешной загрузки информации о сгорании бонусов пользователя
   *
   * Порядок выполнения:
   * - Обновить данные о сгорании бонусов пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetUpcomingExpirationSuccess)
  public getUpcomingExpirationSuccess(ctx: StateContext<IBonusState>,
                                      action: BonusActions.GetUpcomingExpirationSuccess) {
    ctx.patchState({
      expire: {
        ...action.payload,
        days: Math.ceil((+new Date(action.payload.date) - (+new Date())) / (1000 * 60 * 60 * 24)),
      },
    });
  }

  /**
   * Действие для загрузки первой страницы истории изменений бонусного баланса пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link BonusAccountsService#getTransactionHistory}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link BonusActions#GetTransactionHistoryFirstPageSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link BonusActions#GetTransactionHistoryFirstPageFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryFirstPage)
  public getTransactionHistoryFirstPage(
    ctx: StateContext<IBonusState>,
    action: BonusActions.GetTransactionHistoryFirstPage,
  ) {
    return this.service.getTransactionHistory(action.payload.clientId, HISTORY_PAGE_SIZE, 0).pipe(
      tap((res: ITransactionHistoryResponse) =>
        ctx.dispatch(new BonusActions.GetTransactionHistoryFirstPageSuccess(res.result)),
      ),
      catchError((err, caught) => {
        this.catchSentryError('BonusActions.GetTransactionHistoryFirstPageFail', err);
        return ctx.dispatch(
          new BonusActions.GetTransactionHistoryFirstPageFail()
        )
      })
    );
  }

  /**
   * Действие для неуспешной загрузки первой страницы истории изменений бонусного баланса пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryFirstPageFail)
  public getTransactionHistoryFirstPageFail(ctx: StateContext<IBonusState>,
                                            action: BonusActions.GetTransactionHistoryFirstPageFail) {
  }

  /**
   * Действие для успешной загрузки первой страницы истории изменений бонусного баланса пользователя
   *
   * Порядок выполнения:
   * - Если данных в хранилище нет или их количество отличается от входящего, обновить данные истории
   * изменений бонусного баланса пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryFirstPageSuccess)
  public getTransactionHistoryFirstPageSuccess(ctx: StateContext<IBonusState>,
                                               action: BonusActions.GetTransactionHistoryFirstPageSuccess) {
    const state = ctx.getState();

    if (!state.transactionHistory.data || state.transactionHistory.data.totalItems !== action.payload.totalItems) {
      ctx.setState(
        patch({
          transactionHistory: {
            page: 0,
            data: action.payload,
          },
        }),
      );
    }
  }

  /**
   * Действие для загрузки n-ой страницы истории изменений бонусного баланса пользователя
   *
   * Порядок выполнения:
   * - Запросы к бэкенду {@link BonusAccountsService#getTransactionHistory}
   * - В случае успешного ответа - передаем [модифицированные данные]{@link BonusActions#GetTransactionHistoryNextPageSuccess} в состояние
   * - В случае неуспешного ответа - [обрабатываем]{@link BonusActions#GetTransactionHistoryNextPageFail} ошибку
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryNextPage)
  public getTransactionHistoryNextPage(
    ctx: StateContext<IBonusState>,
    action: BonusActions.GetTransactionHistoryNextPage,
  ) {
    const state = ctx.getState();
    const page = state.transactionHistory.page + 1;

    return this.service
      .getTransactionHistory(action.payload.clientId, HISTORY_PAGE_SIZE, page * HISTORY_PAGE_SIZE)
      .pipe(
        tap((res: ITransactionHistoryResponse) =>
          ctx.dispatch(new BonusActions.GetTransactionHistoryNextPageSuccess(res.result)),
        ),
        catchError((err, caught) => {
          this.catchSentryError('BonusActions.GetTransactionHistoryNextPageFail', err);
          return ctx.dispatch(
            new BonusActions.GetTransactionHistoryNextPageFail()
          )
        })
      );
  }

  /**
   * Действие для неуспешной загрузки n-ой страницы истории изменений бонусного баланса пользователя
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryNextPageFail)
  public getTransactionHistoryNextPageFail(ctx: StateContext<IBonusState>,
                                           action: BonusActions.GetTransactionHistoryNextPageFail) {
  }

  /**
   * Действие для успешной загрузки n-ой страницы истории изменений бонусного баланса пользователя
   *
   * Порядок выполнения:
   * - Добавляем данные истории изменений бонусного баланса в хранилище
   * - Обновляем номер текущей страницы в хранилище
   *
   * @param ctx
   * @param action
   */
  @Action(BonusActions.GetTransactionHistoryNextPageSuccess)
  public getTransactionHistoryNextPageSuccess(ctx: StateContext<IBonusState>,
                                              action: BonusActions.GetTransactionHistoryNextPageSuccess) {
    const page = ctx.getState().transactionHistory.page + 1;

    ctx.setState(
      patch({
        transactionHistory: patch({
          page,
          data: patch({
            transactions: append<ITransaction>(action.payload.transactions),
          }),
        }),
      }),
    );
  }

  /**
   * Действие для сброса состояния к значению по умолчанию
   *
   * @param ctx
   */
  @Action(BonusActions.Reset)
  public reset(ctx: StateContext<IBonusState>) {
    ctx.setState(BONUS_STATE_DEFAULTS);
  }
}
