import { IProfileState } from './profile-state.interface';
import { RequestStatus } from '../../../constants/request-status.const';

/**
 * Дефолтное состояние хранилища "Профиль" при его инициализации
 */
export const PROFILE_STATE_DEFAULTS: IProfileState = {
  version: 1,
  status: RequestStatus.NotInvoked,
  networks: null,
  phone: null,
  email: null,
  form: null,
  info: null,
  modalInfo: null,
};
