import { IStateMigration } from '@app/interfaces/state-migration.interface';
import { RequestStatus } from '@app/constants/request-status.const';

/**
 * Класс миграций состояния "Заём"
 */
export class AuthStateMigrations {
  /**
   * Метод получения перечня миграций хранилища
   */
  public static migrate(): IStateMigration[] {
    return [
      {
        version: undefined,
        key: 'Auth',
        versionKey: 'version',
        migrate: (state) => ({
          version: 1,
          token: null,
          tokenExpired: null,
          userId: null,
          webview: false,
          passwordRecoveryMethods: null,
          loginRequestStatus: RequestStatus.NotInvoked,
          recoveryCheckStatus: RequestStatus.NotInvoked,
          recoveryIdentity: null,
          passwordRecoveryStatus: RequestStatus.NotInvoked,
          recoveryConfirmIdentity: null,
          resetPasswordStatus: RequestStatus.NotInvoked,
          esiaDataResponse: null,
          autologin: null,
        }),
      },
      {
        version: 1,
        key: 'Auth',
        versionKey: 'version',
        migrate: (state) => ({
          version: 2,
          token: null,
          tokenExpired: null,
          userId: null,
          webview: false,
          passwordRecoveryMethods: null,
          loginRequestStatus: RequestStatus.NotInvoked,
          recoveryCheckStatus: RequestStatus.NotInvoked,
          recoveryIdentity: null,
          passwordRecoveryStatus: RequestStatus.NotInvoked,
          recoveryConfirmIdentity: null,
          resetPasswordStatus: RequestStatus.NotInvoked,
          esiaDataResponse: null,
          autologin: null,
          registration: false,
        }),
      },
    ];
  }
}
