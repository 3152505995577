import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDefaultProfileSettings } from '@app/states/settings/interfaces/default-settings.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';


@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly path: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.path = `${this.environment.api}`;
  }

  public profileSettings(): Observable<IDataResponse<{fields: IDefaultProfileSettings}>> {
    return this.http.get<IDataResponse<{fields: IDefaultProfileSettings}>>(`${this.path}user/v2/registration-settings`);
  }
}
