export const ProfileFormsPossibleDisabledFields: { [key: string]: string[] } = {
  contacts: ['mobilePhone', 'email'],
  personal: ['first_name', 'last_name', 'middle_name', 'bDay', 'bPlace', 'gender'],
  passport: ['passport', 'passportDateOfIssue', 'passportDivisionCode', 'passportIssuedBy'],
  inn: ['snilsOrInn', 'snils', 'inn'],
  address: ['address', 'legalAddress'],
  work: [
    'typeOfEmployment',
    'workType',
    'workSalary',
    'workScope',
    'workNumberOfEmployees',
    'workPeriod',
    'workName',
  ],
  family: [
    'maritalStatus',
    'hasChildren',
    'numberOfChildren',
  ],
  education: [
    'education',
    'educationType',
  ],
  publicity: [
    'officialState',
    'officialName',
    'internationalOrganizationState',
    'internationalOrganizationName',
    'officialRussiaState',
    'officialRussiaName',
    'benefitsOfAnotherPerson',
  ],
  beneficial: ['beneficial'],
};