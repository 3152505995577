import { IRiskAnalysisState } from '@app/states/risk-analysis/states/risk-analysis-state.interface';

export const RISK_ANALYSIS_STATE_DEFAULTS: IRiskAnalysisState = {
  hash: undefined,
  data: {
    keyboardTypingSpeed: 0,
    leftPageCount: 0,
    numberOfCorrections: 0,
    numberSingleClicks: 0,
    pageFillingDuration: 0,
    startAt: 0,
    keydownCounter: 0,
  },
};
