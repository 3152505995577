import { LoanInstallmentStatus } from '@app/constants/loan/loan-installment-status.const';
import { LoanStatus } from '@app/constants/loan/loan-status.const';
import { Product } from '@app/states/calculator/constants/product.const';
import { PercentUnit } from '@app/states/product/constants/percent-unit.const';
import { TermUnit } from '@app/states/product/constants/term-unit.const';
import { IPDLDTO } from '@app/states/product/interfaces/pdl-dto.interface';
import { IProduct } from '@app/states/product/interfaces/product.interface';
import { IInstallment } from '@app/states/product/interfaces/supplementary/installment.interface';
import dayjs from 'dayjs';
import { ilsContractStatusMap } from './product-ils-contract-status.helper';
import { pdlStatusMap } from './product-pdl-status.helper';
import { amnestyMap, holidayMap, prolongationMap } from './product-supplementary.helper';

export function productPdlMap(id: string, product: IPDLDTO): IProduct {
  const installment = installmentMap(product);
  const activeInstallment = [
    LoanInstallmentStatus.Active,
    LoanInstallmentStatus.Prolongation,
    LoanInstallmentStatus.Overdue,
  ].includes(installment?.status);

  return {
    id,
    type: Product.PDL,
    status: pdlStatusMap(product.status),
    documentUrl: `documents/list/${id}`,
    statementUrl: `documents/statement/${id}`,
    sum: {
      debt: debtMap(product),
      debtPaid: 0,
      debtOld: debtOldMap(product),
      debtPrincipal: product.sumLeft,
      debtPercent: product.sumPercent,
      debtPenalty: product.sumPenalty,
      initial: product.sum,
      debtEnd: product.sumToRefund,
    },
    bonuses: {
      available: product.sumPayableByBonuses,
      received: product.sumBonusMax,
    },
    date: {
      start: dayjs(product.dateFrom)?.toISOString(),
      end: activeInstallment
        ? dayjs(installment.payments[installment.payments.length - 1].date)?.toISOString()
        : product.supplementary?.loanNewDateEnd
        ? dayjs(product.supplementary?.loanNewDateEnd).toISOString()
        : dayjs(product.dateTo).toISOString(),
      oldEnd: dayjs(product.dateTo)?.toISOString(),
      request: dayjs(product.dateRequest)?.toISOString(),
    },
    percent: {
      value: product.percent,
      unit: PercentUnit.Day, // TODO ENUM
    },
    currentPercent: product.currentPercent,
    term: {
      value: product.days,
      unit: TermUnit.Day, // TODO ENUM
    },
    overdue:
      (product.overdue && !installment) || installment?.status === LoanInstallmentStatus.Overdue
        ? {
            value: product.overdue,
            type: product.portfolio,
            delay: product.daysDelay,
          }
        : null,
    payments: {
      current: activeInstallment ? installment.payments.find((p) => !p.paid) : null,
      all: activeInstallment ? installment.payments : null,
    },
    supplementary: {
      installment,
      amnesty: amnestyMap(product),
      prolongation: prolongationMap(product),
      holiday: holidayMap(product),
    },
    isSold: product.isSold,
    sumPayForRefinance: product.sumPayForRefinance,
    payDateEnd: product.payDateEnd,
    refinancedFrom: product.refinancedFromInfo
      ? {
          id: product.refinancedFromInfo.loanId,
          status:
            product.refinancedFromInfo.loanType === Product.PDL
              ? pdlStatusMap(product.refinancedFromInfo.pdlState)
              : ilsContractStatusMap(product.refinancedFromInfo.ilsState),
          type: product.refinancedFromInfo.loanType,
        }
      : null,
    notifications: product.notifications,
    loanType: product.loanType,
    sumAdditionalLoan: product.sumAdditionalLoan,
    orderNumber: product.orderNumber,
    canRefinancing: product.canRefinancing,
    refinanceAmnestyAmount: product.refinanceAmnestyAmount,
  };
}

export function installmentMap(product: IPDLDTO): IInstallment {
  if (product.supplementary?.installnesty) {
    return {
      id: product.supplementary.installnesty.id,
      initialAmount: product.supplementary.installnesty.depositAmount,
      payDate: product.supplementary.installnesty.payDateEnd,
      signDate: product.supplementary.installnesty.signDateEnd,
      status: product.supplementary.installnesty.status,
      discount: product.supplementary.installnesty.writeOff,
      payments: product.supplementary.installnesty.paymentsPlan.map((p) => ({
        date: p.payDateEnd,
        sumPaid: p.sumPaidPerPeriod - p.sumLeftPerPeriod,
        sumOverpayment: 0,
        sumLeft: p.sumLeftPerPeriod,
        sumTotal: p.sumPaidPerPeriod,
        debtTotal: p.totalSumLeftToPay,
        paid: p.paid,
        discount: p.writeOff,
      })),
    };
  }

  if (product.supplementary?.installment) {
    return {
      id: product.supplementary.installment.id,
      initialAmount: product.supplementary.installment.depositAmount,
      payDate: product.supplementary.installment.payDateEnd,
      signDate: product.supplementary.installment.signDateEnd,
      status: product.supplementary.installment.status,
      discount: 0,
      payments: product.supplementary.installment.paymentsPlan.map((p) => ({
        date: p.payDateEnd,
        sumOverpayment: 0,
        sumPaid: p.sumLeftToPayPerMonth,
        sumLeft: p.totalSumToPayPerMonth - p.sumLeftToPayPerMonth,
        sumTotal: p.totalSumToPayPerMonth,
        debtTotal: p.totalSumLeftToPay,
        paid: p.payed,
        discount: 0,
      })),
    };
  }

  return undefined;
}

export function debtMap(product: IPDLDTO): number {
  if (product.supplementary?.amnesty && product.supplementary.amnesty.status !== LoanInstallmentStatus.Signing) {
    return product.supplementary.amnesty.depositAmount;
  }

  if (product.status === LoanStatus.Approved) {
    return product.sumTotalMax;
  }

  return product.sumTotal;
}

export function debtOldMap(product: IPDLDTO): number {
  if (product.status === LoanStatus.Approved) {
    return product.sumTotalMax;
  }

  return product.sumTotal;
}
