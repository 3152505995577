import { RequestStatus } from '@app/constants/request-status.const';
import { IProductState } from '@app/states/product/states/product-state.interface';

/**
 * Дефолтное состояние хранилища "Заём" при его инициализации
 */
export const PRODUCT_STATE_DEFAULTS: IProductState = {
  version: 4,
  data: null,
  status: RequestStatus.NotInvoked,
  newLoan: {
    model: {
      sum: null,
      term: null,
      product: null,
      account: null,
      terms: null,
      consents: null,
      signingConsents: null,
      method: null,
      card: null,
      expire: null,
      bank: null,
      promocode: {
        code: null,
        confirmed: null,
      },
      defaultProduct: null,
    },
    status: null,
    errors: null,
    dirty: null,
  },
  payment: {
    model: null,
    status: null,
    errors: null,
    dirty: null,
  },
  prolongation: null,
  prolongationConsent: null,
  disclaim: null,
  contract: null,
  closedCount: {
    count_loans_pdl: 0,
    count_loans: 0,
    count_loans_pos: 0,
  },
  paymentMethods: null,
  facts: [],
  refinancingPaid: false,
  additionalLoanSum: null,
  isAbleToDelete: false,
};
