import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { IRiskAnalysisState } from '@app/states/risk-analysis/states/risk-analysis-state.interface';
import { RISK_ANALYSIS_STATE_DEFAULTS } from '@app/states/risk-analysis/states/risk-analysis-state-defaults.const';
import { Injectable } from '@angular/core';
import { RiskAnalysisActions } from '@app/states/risk-analysis/states/risk-analysis.actions';
import { patch } from '@ngxs/store/operators';

@State<IRiskAnalysisState>({
  name: 'RiskAnalysis',
  defaults: RISK_ANALYSIS_STATE_DEFAULTS,
})
@Injectable()
export class RiskAnalysisState implements NgxsOnInit {
  public ngxsOnInit(ctx: StateContext<IRiskAnalysisState>) {
    if (!ctx.getState().hash) {
      ctx.setState(
        patch({
          hash: Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7),
        }),
      );
    }
  }

  @Action(RiskAnalysisActions.Set)
  public set(ctx: StateContext<IRiskAnalysisState>,
             action: RiskAnalysisActions.Set) {
    ctx.setState(
      patch({
        data: patch(action.payload),
      }),
    );
  }

  @Action(RiskAnalysisActions.Reset)
  public reset(ctx: StateContext<IRiskAnalysisState>,
               action: RiskAnalysisActions.Reset) {
    const { hash } = ctx.getState();

    ctx.setState({
      ...RISK_ANALYSIS_STATE_DEFAULTS,
      hash,
    });
  }
}
