/**
 * Справочник действий состояния "Авторизация"
 */
export enum AuthActionList {
  LOGIN = '[Auth] login request',
  LOGIN_FAIL = '[Auth] login failed',
  LOGIN_SUCCESS = '[Auth] login success',

  AUTH_BY_TOKEN = '[Auth] auth by token',

  EMAIL_CONFIRM = '[Auth] email confirm',
  EMAIL_CONFIRM_FAIL = '[Auth] email confirm fail',
  EMAIL_CONFIRM_SUCCESS = '[Auth] email confirm success',

  ESIA = '[Auth] login ESIA',
  ESIA_FAIL = '[Auth] login ESIA fail',
  ESIA_SUCCESS = '[Auth] login ESIA success',

  LOGOUT = '[Auth] logout',

  RECOVERY_CHECK = '[Auth] recovery check',
  RECOVERY_CHECK_SUCCESS = '[Auth] recovery check success',
  RECOVERY_CHECK_FAIL = '[Auth] recovery check fail',

  RECOVERY = '[Auth] recovery via sms',
  RECOVERY_SUCCESS = '[Auth] recovery success',
  RECOVERY_FAIL = '[Auth] recovery fail',
  RECOVERY_VIA_SMS_CONFIRMATION = '[Auth] recovery via sms confirmation',

  RESET_PASSWORD = '[Auth] reset password request',
  RESET_PASSWORD_SUCCESS = '[Auth] reset password success',
  RESET_PASSWORD_FAIL = '[Auth] reset password fail',

  RESET = '[Auth] reset',

  AUTOLOGIN = '[Auth] autologin request',
  RESET_AUTOLOGIN = '[Auth] reset autologin',
}
