import { Overlay } from '@angular/cdk/overlay';
import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NoPreloading,
  Router,
  provideRouter,
  withDebugTracing,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { APP_ROUTES } from '@app/app.routes';
import { CustomStorage } from '@app/helpers/storage/custom-storage.class';
import { AuthInterceptor } from '@app/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from '@app/interceptors/error-interceptor/error.interceptor';
import { AnalyticService } from '@app/services/analytic/analytic.service';
import { CookieService } from '@app/services/cookie/cookie.service';
import { AuthStateMigrations } from '@app/states/auth/states/auth-state.migrations';
import { AuthState } from '@app/states/auth/states/auth.state';
import { CalculatorStateMigrations } from '@app/states/calculator/states/calculator-state.migrations';
import { CalculatorState } from '@app/states/calculator/states/calculator.state';
import { EventsStateMigrations } from '@app/states/events/states/events-state.migrations';
import { EventsActions } from '@app/states/events/states/events.actions';
import { EventsState } from '@app/states/events/states/events.state';
import { LoansHistoryStateMigrations } from '@app/states/loans-history/states/loan-history/loans-history-state.migrations';
import { LoggerState } from '@app/states/logger/states/logger.state';
import { ModalState } from '@app/states/modal/states/modal.state';
import { ProductStateMigrations } from '@app/states/product/states/product-state.migrations';
import { ProductState } from '@app/states/product/states/product.state';
import { ProfileStateMigrations } from '@app/states/profile/states/profile-state.migrations';
import { ProfileState } from '@app/states/profile/states/profile.state';
import { RegistrationStateMigrations } from '@app/states/registration/states/registration-state.migrations';
import { RiskAnalysisState } from '@app/states/risk-analysis/states/risk-analysis.state';
import { SettingsState } from '@app/states/settings/states/settings.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { DecoratorService } from '@web-bankir/ui-kit/helpers/decorators';
import { DeviceService } from '@web-bankir/ui-kit/services/device';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { MetrikaModule } from 'ng-yandex-metrika';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';
import { PWAService } from './services/pwa/pwa.service';
import { RouterScrollService } from './services/scroll-service/scroll-service.service';
import { ExperimentsState } from './states/experiments/states/experiments.state';
import { VirtualCardState } from './states/virtual-card/states/virtual-card.state';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideRouter(
      APP_ROUTES,
      withPreloading(NoPreloading),
      withInMemoryScrolling({ anchorScrolling: 'disabled' }),
      withRouterConfig({ onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' }),
      withDebugTracing()
    ),
    importProvidersFrom(
      NgxsModule.forRoot([
        RiskAnalysisState,
        ModalState,
        AuthState,
        CalculatorState,
        LoggerState,
        EventsState,
        ProductState,
        ProfileState,
        SettingsState,
        ExperimentsState,
      ]),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxsStoragePluginModule.forRoot({
        key: [
          AuthState,
          'RiskAnalysis',
          'Accounts',
          CalculatorState,
          'profile',
          'registration',
          'Feedback',
          EventsState,
          'Product',
          'LoansHistory',
          SettingsState,
          VirtualCardState,
          'Experiments',
        ],
        migrations: [
          ...AuthStateMigrations.migrate(),
          ...RegistrationStateMigrations.migrate(),
          ...ProductStateMigrations.migrate(),
          ...CalculatorStateMigrations.migrate(),
          ...LoansHistoryStateMigrations.migrate(),
          ...ProfileStateMigrations.migrate(),
          ...EventsStateMigrations.migrate(),
        ],
      }),
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      NgxsFormPluginModule.forRoot(),
      NgxsRouterPluginModule.forRoot(),
      NgxMaskDirective,
      NgxMaskPipe,
      NgxGoogleAnalyticsModule.forRoot('UA-45944839-1'),
      MetrikaModule.forRoot([
        {
          id: environment.yandexMetrikaId,
          webvisor: true,
        },
      ]),
      GoogleTagManagerModule.forRoot({
        id: environment.gtmCode,
      }),
      ReactiveFormsModule
    ),
    Overlay,
    ToastService,
    DeviceService,
    AnalyticService,
    provideNgxMask(),
    { provide: LOCALE_ID, useValue: 'ru' },
    CurrencyPipe,
    { provide: 'Window', useFactory: () => window },
    { provide: 'Document', useValue: document },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          store: Store,
          deviceService: DeviceService,
          gtm: GoogleTagManagerService,
          routerScrollService: RouterScrollService,
          pwaService: PWAService
        ) =>
        () => {
          gtm.addGtmToDom();

          store.dispatch(new EventsActions.NewUser());
          store.dispatch(new EventsActions.NewSession());

          deviceService.init();
          routerScrollService.initScroll();
          pwaService.init();
        },
      deps: [
        Store,
        DeviceService,
        GoogleTagManagerService,
        RouterScrollService,
        PWAService,
        DecoratorService,
        Sentry.TraceService,
        CookieService,
      ],
      multi: true,
    },
    {
      provide: 'Window',
      useValue: window,
    },
    {
      provide: 'URL',
      useValue: URL,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '₽',
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: STORAGE_ENGINE,
      useClass: CustomStorage,
    },
  ],
};
