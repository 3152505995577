import { IError } from "@app/interfaces/error.interface";
import { VirtualCardActionList } from "@app/states/virtual-card/states/virtual-card-action-list.const";
import { IVirtualCardStateDto } from "../interfaces/virtual-card-state-dto.interface";
import { IVirtualCardContextDto } from "../interfaces/virtual-card-context-dto.interface";
import { IVirtualCardAccountDto } from "../interfaces/virtual-card-account-dto.interface";
import { IVirtualCardWidgetToken } from "../interfaces/virtual-card-widget-token.interface";
import { IVirtualCardReissueStatusDto } from "../interfaces/virtual-card-reissue-status.interface";
import { IVirtualCardOperation } from "../interfaces/virtual-card-operation.interface";
import { IYoomoneyWidgetAuthCode } from "../interfaces/yoomoney-widget/yoomoney-widget-auth-code.interface";
import { IYoomoneyWidgetError } from "../interfaces/yoomoney-widget/yoomoney-widget-error.interface";
import { YoomoneyWidgetCardDataErrorCode } from "../constants/yoomoney-widget/yoomoney-widget-card-data-error-code.const";
import { IYoomoneyWidgetChangePin } from "../interfaces/yoomoney-widget/yoomoney-widget-change-pin.interface";
import { IYoomoneyWidgetTopUp } from "../interfaces/yoomoney-widget/yoomoney-widget-top-up.interface";
import { IYoomoneyWidgetTransfer } from "../interfaces/yoomoney-widget/yoomoney-widget-transfer.interface";

export namespace VirtualCardActions {
  /** Действие для загрузки карты */
  export class LoadState {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.LOAD_STATE;
  }

  /** Действие для неуспешной загрузки карты */
  export class LoadStateFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.LOAD_STATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для успешной загрузки карты */
  export class LoadStateSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.LOAD_STATE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные карты
     */
    constructor(public payload: IVirtualCardStateDto) {}
  }


  /** Действие для получения контекста для запуска виджета */
  export class Context {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CONTEXT;
  }

  /** Действие для неуспешного получения контекста для запуска виджета */
  export class ContextFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CONTEXT_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для успешного получения контекста для запуска виджета */
  export class ContextSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CONTEXT_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные контекста
     */
    constructor(public payload: IVirtualCardContextDto) {}
  }

  /** Действие для cоздания счета виртуальной карты */
  export class Create {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CREATE;

     /**
     * Тело действия
     *
     * @param payload Временный токен
     */
     constructor(public payload: string) {}
  }

  /** Действие для неуспешного cоздания счета виртуальной карты */
  export class CreateFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CREATE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для успешного cоздания счета виртуальной карты */
  export class CreateSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CREATE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные контекста
     */
    constructor(public payload: IVirtualCardAccountDto) {}
  }

  /** Действие для установки флага hideNewCard в true */
  export class HideNewCard {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.HIDE_NEW_CARD;
  }

  /** Действие для получение токена виджета Юмани */
  export class Token {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOKEN;
  }

  /** Действие для успешного получение токена виджета Юмани */
  export class TokenSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOKEN_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Предлагаемые данные токена
     */
    constructor(public payload: IVirtualCardWidgetToken) {}
  }

  /** Действие для неуспешного получение токена виджета Юмани */
  export class TokenFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOKEN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для получения кода подтверждения виджета Юмани */
  export class AuthCode {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.AUTH_CODE;
  
    /**
     * Тело действия
     *
     * @param payload URL страницы регистрации или входа
     */
    constructor(public payload: string) {}
  }

  /** Действие для успешного получения кода подтверждения виджета Юмани */
  export class AuthCodeSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.AUTH_CODE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload 
     */
    constructor(public payload: IYoomoneyWidgetAuthCode) {}
  }

  /** Действие для неуспешного получения кода подтверждения виджета Юмани */
  export class AuthCodeFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.AUTH_CODE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: any) {}
  }
  
  /** Действие для блокировка карты ЮМани */
  export class Block {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.BLOCK;
  }

  /** Действие для успешной блокировка карты ЮМани */
  export class BlockSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.BLOCK_SUCCESS;
  }

  /** Действие для неуспешной блокировка карты ЮМани */
  export class BlockFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.BLOCK_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для перевыпуска карты ЮМани */
  export class Reissue {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE;
  }

  /** Действие для успешного перевыпуска карты ЮМани */
  export class ReissueSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Состояние перевыпуска
     */
    constructor(public payload: IVirtualCardReissueStatusDto) {}
  }

  /** Действие для неуспешного перевыпуска карты ЮМани */
  export class ReissueFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для получения статуса перевыпуска карты ЮМани */
  export class ReissueStatus {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE_STATUS;

    /**
     * Тело действия
     *
     * @param payload Идентификатор записи процесса выпуска карты
     */
    constructor(public payload: string) {}
  }

  /** Действие для успешного получения статуса перевыпуска карты ЮМани */
  export class ReissueStatusSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE_STATUS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload Состояние перевыпуска
     */
    constructor(public payload: IVirtualCardReissueStatusDto) {}
  }

  /** Действие для неуспешного получения статуса перевыпуска карты ЮМани */
  export class ReissueStatusFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.REISSUE_STATUS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для получения транкзакций по карте за период */
  export class Operations {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.OPERATIONS;
    /**
     * Тело действия
     *
     * @param from
     * @param to 
     */
    constructor(public from: string, public to: string) {}
  }
  
  /** Действие для успешного получения транкзакций по карте за период */
  export class OperationsSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.OPERATIONS_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload данные транкзакций
     */
    constructor(public payload: IVirtualCardOperation[]) {}
  }
  
  /** Действие для неуспешного получения транкзакций по карте за период */
  export class OperationsFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.OPERATIONS_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: IError[]) {}
  }

  /** Действие для просмотра данных карты ЮМани */
  export class CardData {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CARD_DATA;
  
    /**
     * Тело действия
     *
     * @param widgetToken Временный токен
     * @param cardId Идентификатор банковской карты
     */
    constructor(public widgetToken: string, public cardId: string) {}
  }

  /** Действие для успешного просмотра данных карты ЮМани */
  export class CardDataSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CARD_DATA_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload
     */
    constructor(public payload: IYoomoneyWidgetError<YoomoneyWidgetCardDataErrorCode>) {}
  }

  /** Действие для неуспешного просмотра данных карты ЮМани */
  export class CardDataFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CARD_DATA_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: any) {}
  }

  /** Действие для изменения пин-кода */
  export class ChangePin {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CHANGE_PIN;
  
    /**
     * Тело действия
     *
     * @param widgetToken Временный токен
     * @param cardId Идентификатор банковской карты
     */
    constructor(public widgetToken: string, public cardId: string) {}
  }

  /** Действие для успешного изменения пин-кода */
  export class ChangePinSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CHANGE_PIN_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload
     */
    constructor(public payload: IYoomoneyWidgetChangePin) {}
  }

  /** Действие для неуспешного изменения пин-кода */
  export class ChangePinFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.CHANGE_PIN_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: any) {}
  }

  /** Действие для пополнения баланса */
  export class TopUp {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOP_UP;
  
    /**
     * Тело действия
     *
     * @param payload Временный токен
     */
    constructor(public payload: string) {}
  }

  /** Действие для успешного пополнения баланса */
  export class TopUpSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOP_UP_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload
     */
    constructor(public payload: IYoomoneyWidgetTopUp) {}
  }

  /** Действие для неуспешного пополнения баланса */
  export class TopUpFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TOP_UP_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: any) {}
  }

  /** Действие для перевода */
  export class Transfer {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TRANSFER;
  
    /**
     * Тело действия
     *
     * @param payload Временный токен
     */
    constructor(public payload: string) {}
  }

  /** Действие для успешного перевода */
  export class TransferSuccess {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TRANSFER_SUCCESS;

    /**
     * Тело действия
     *
     * @param payload
     */
    constructor(public payload: IYoomoneyWidgetTransfer) {}
  }

  /** Действие для неуспешного перевода */
  export class TransferFail {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.TRANSFER_FAIL;

    /**
     * Тело действия
     *
     * @param payload Данные об ошибке
     */
    constructor(public payload: any) {}
  }

  /** Действие для подключения виджета */
  export class InitWidget {
    public static readonly type: VirtualCardActionList = VirtualCardActionList.INIT_WIDGET;
  }

  /** Действие для сброса состояния к значению по умолчанию */
  export class Reset {
    /** Уникальное имя действия */
    public static readonly type: VirtualCardActionList = VirtualCardActionList.RESET;
  }
}
