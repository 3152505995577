import { RequestStatus } from '@app/constants/request-status.const';
import { IVirtualCardState } from './virtual-card.state.interface';


export const VIRTUAL_CARD_STATE_DEFAULTS: IVirtualCardState = {
  data: null,
  status: RequestStatus.NotInvoked,
  hideNewCard: false,
  operations: null,
};
