import { Injectable } from '@angular/core';
import { RequestStatus } from '@app/constants/request-status.const';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { Action, NgxsOnInit, State, StateContext, Store, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { catchError, tap } from 'rxjs';
import { FeatureKey } from '../constants/feature-key.const';
import { IAttributes } from '../interfaces/attributes.interface';
import { ExperimentsService } from '../services/experiments.service';
import { EXPERIMENTS_STATE_DEFAULTS } from './experiments-state-defaults.const';
import { IExperimentsState } from './experiments-state.interface';
import { ExperimentsActions } from './experiments.actions';

/**
 * Класс NGXS состояния экспериментов
 */
@State<IExperimentsState>({
  name: 'Experiments',
  defaults: EXPERIMENTS_STATE_DEFAULTS,
})
@Injectable()
export class ExperimentsState extends StateErrorHandler implements NgxsOnInit {
  /**
   * Конструктор класса состояния экспериментов
   *
   * @param store NGXS{@link Store} хранилище
   * @param service [Сервис]{@link ExperimentsService} обращения к growthbook
   * @param toast [Сервис]{@link ToastService} библиотеки ui-kit всплывающих информационных окон
   */
  constructor(protected store: Store, private service: ExperimentsService, protected toast: ToastService) {
    super(toast, store);
  }

  /**
   * Селектор для значения фичи
   *
   * @param key кодовое имя фичи
   * @param defaultValue дефолтное значение (опционально)
   *
   * @returns значение фичи
   */
  public static featureValue(key: FeatureKey, defaultValue: any = null) {
    return createSelector(
      [ExperimentsState],
      (state: IExperimentsState) => {
        if ([RequestStatus.Load, RequestStatus.Error].includes(state.status)) {
          return state.features[key] !== undefined ? state.features[key].defaultValue : defaultValue;
        }

        return defaultValue;
      },
      {
        containerClass: 'Experiments',
        selectorName: `featureValue_${key}_default_${defaultValue}`,
      }
    );
  }

  /**
   * Lifecycle-hook загрузки состояния:
   *    - [загрузка]{@link ExperimentsState.load} экспериментов
   * @param ctx
   */
  public ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new ExperimentsActions.Load());
  }

  /**
   * Загрузка актуальных экспериментов
   *
   * @param ctx
   * @param action
   */
  @Action(ExperimentsActions.Load, { cancelUncompleted: true })
  public load(ctx: StateContext<IExperimentsState>, action: ExperimentsActions.Load) {
    ctx.patchState({
      status: RequestStatus.Pending,
      features: EXPERIMENTS_STATE_DEFAULTS.features,
      experiments: EXPERIMENTS_STATE_DEFAULTS.experiments,
    });
    return this.service
      .load({
        attributes: ctx.getState().attributes,
        forcedFeatures: [],
        forcedVariations: {},
        url: '',
      })
      .pipe(
        tap((res) =>
          ctx.dispatch(new ExperimentsActions.LoadSuccess({ features: res.features, experiments: res.experiments }))
        ),
        catchError((err, caught) => ctx.dispatch(new ExperimentsActions.LoadFail(err.error)))
      );
  }

  /**
   * Загрузка актуальных экспериментов не успешно
   *
   * @param ctx
   * @param action
   */
  @Action(ExperimentsActions.LoadFail)
  public loadFail(ctx: StateContext<IExperimentsState>, action: ExperimentsActions.LoadFail) {
    ctx.patchState({
      status: RequestStatus.Error,
    });
  }

  /**
   * Загрузка актуальных экспериментов успешно
   *
   * @param ctx
   * @param action
   */
  @Action(ExperimentsActions.LoadSuccess)
  public loadSuccess(ctx: StateContext<IExperimentsState>, action: ExperimentsActions.LoadSuccess) {
    ctx.patchState({
      status: RequestStatus.Load,
      experiments: action.payload.experiments,
      features: action.payload.features,
    });
  }

  /**
   * Обновление атрибутов эксперимента
   *
   * @param ctx
   * @param action
   */
  @Action(ExperimentsActions.UpdateAttributes)
  public updateAttributes(ctx: StateContext<IExperimentsState>, action: ExperimentsActions.UpdateAttributes) {
    if (this.isAttributesEqual(ctx.getState().attributes, { ...ctx.getState().attributes, ...action.payload })) return;

    ctx.setState(
      patch({
        attributes: action.payload ? patch(action.payload) : {},
      })
    );

    ctx.dispatch(new ExperimentsActions.Load());
  }

  /**
   * Сброс
   *
   * @param ctx
   * @param action
   */
  @Action(ExperimentsActions.Reset)
  public reset(ctx: StateContext<IExperimentsState>, action: ExperimentsActions.Reset) {
    ctx.setState(EXPERIMENTS_STATE_DEFAULTS);
  }

  private isAttributesEqual(prevObj: IAttributes, newObj: IAttributes): boolean {
    return prevObj && newObj && typeof prevObj === 'object' && typeof newObj === 'object'
      ? Object.keys(prevObj).length === Object.keys(newObj).length &&
          Object.keys(prevObj).reduce((isEqual, key) => {
            return isEqual && this.isAttributesEqual(prevObj[key], newObj[key]);
          }, true)
      : prevObj === newObj;
  }
}
